import { themes } from './index';
export interface ITheme {
  [key: string]: string;
}

export interface IThemes {
  [key: string]: ITheme;
}

export interface IMappedTheme {
  [key: string]: string | null;
}

export const mapTheme = (variables: ITheme): IMappedTheme => {
  return {
    '--color-brand-900': variables['brand-900'],
    '--color-brand-800': variables['brand-800'],
    '--color-brand-700': variables['brand-700'],
    '--color-brand-600': variables['brand-600'],
    '--color-brand-500': variables['brand-500'],
    '--color-brand-400': variables['brand-400'],
    '--color-brand-300': variables['brand-300'],
    '--color-brand-200': variables['brand-200'],
    '--color-brand-100': variables['brand-100'],
    '--color-brand-50': variables['brand-50'],
  };
};
export const applyTheme = (theme: string): void => {
  const themeObject: IMappedTheme = mapTheme(themes[theme]);
  if (!themeObject) return;

  const root = document.documentElement;

  Object.keys(themeObject).forEach((property) => {
    root.style.setProperty(property, themeObject[property]);
  });
};
export const extend = (extending: ITheme, newTheme: ITheme): ITheme => {
  return { ...extending, ...newTheme };
};
