const inventory = {
  category: {
    categoryBtn: 'कैटगरी',
    singleCategory: 'सिंगल कैटगरी',
    multipleCategory: 'मल्टीपल कैटगरी',
    deleteBtn: 'डिलीट करें',
  },

  addCategoryBulkForm: {
    categoryExistErrorMsg: 'कैटगरी पहले से मौजूद है',
    duplicateCategoryErrorMsg: 'डूप्लीकेट कैटगरी मौजूद है',
    title: 'थोक में कैटगरी जोड़ें',
    allowedExtensionsText:
      'सिर्फ .xls .xlxs .csv एक्सटेंशन वाली फाइलों की अनुमति है',
    downloadTemplateBtn: 'टेम्प्लेट डाउनलोड करें',
    cancelBulkProcessBtn: 'कैंसल करें',
    uploadBulkProcessBtn: 'अपलोड करें',
    dragAndDropText: 'ड्रैग और ड्रॉप करें',
    uploadFromComputerText: 'कम्प्यूटर से अपलोड करें ',
    invalidTemplateError:
      'इस फाइल को अपलोड नहीं कर सकते। टेम्प्लेट फाइल को चेक करें और सभी आवश्यक फील्ड भरें।',
    partialFileReviewText:
      '{{totalLength}} में से {{errorLength}} आइटम्स में गलतियां हैं। अपलोड पूरा करने के लिए गलतियां हटाएं!',
    completeFileReviewText: 'कृपया इसे रीव्यू करें',
    reviewedNameLbl: 'नाम',
    reviewedNamePlaceholder: 'नाम',
    reviewedNameRequiredError: 'आवश्यक',
    reviewedNameCommaError: 'कृपया कॉमा(,) हटाएं ',
    uploadFileAgain: 'दोबारा अपलोड करें',
    maxLengthError: 'प्रोसेस 100  से बड़ा नहीं हो सकता',
  },

  addPartyForm: {
    title: 'पार्टी जोड़ें',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    partyTypeLbl: 'पार्टी का प्रकार',
    partyTypePlaceholder: 'कस्टमर/वेंडर',
    mobileLbl: 'मोबाइल नंबर',
    mobilePlaceholder: 'मोबाइल नंबर',
    personOfContactLbl: 'संपर्क का व्यक्ति (वैकल्पिक)',
    personOfContactPlaceholder: 'उदाहरणः रमेश शर्मा',
    emailLbl: 'ईमेल (वैकल्पिक)',
    emailPlaceholder: 'उदाहरणः abcxyz123@email.com',
    GSTINLbl: 'जीएसटीआईएन (वैकल्पिक)',
    GSTINPlaceholder: 'उदाहरणः 22 AAAAA0000A 1 Z 5',
    addressLbl: 'पता (वैकल्पिक)',
    addressPlaceholder: 'उदाहरणः 72, चिंतामणि रोड',
    addBtn: 'जोड़ें',
    requiredError: 'आवश्यक',
    validMobileError: 'कृपया सही नंबर डालें',
    validEmailError: 'कृपया सही ईमेल डालें',
    validGSTINError: 'कृपया सही जीएसटीआईएन डालें',
    customerLbl: 'कस्टमर',
    customerVal: 'कस्टमर',
    vendorLbl: 'वेंडर',
    vendorVal: 'वेंडर',
    vendCustLbl: 'वेंडर और कस्टमर',
    vendCustVal: 'वेंडर_कस्टमर',
  },
  editPartyForm: {
    title: 'पार्टी एडिट करें',
  },
  editEntry: {
    title: 'एंट्री एडिट करें',
    subTitle: 'पर बनाया गया',
    reasonLabel: 'कारण',
    quantityLabel: 'मात्रा',
    amountLbl: 'राशि',
    button: 'सेव करें',
    reasonPlaceHolder: 'उदाहरणः रिटर्न',
    requiredError: 'आवश्यक',
    priceLbl: 'प्रति यूनिट कीमत',
    partyLbl: 'पार्टी चुनें',
    reelNameLbl: 'रील का नाम',
    quantityLbl: 'मात्रा',
    avlQty: 'उपलब्ध मात्राः {{avlQty}}',
    addReelBtn: 'एक और रील जोड़ें',
    anotherReelBtn: 'एक और रील चुनें',
    totalQuantity: 'कुल मात्रा :',
    partyPlaceholder: 'पार्टी सर्च करें',
  },

  viewHistory: {
    title: 'हिस्ट्री एडिट करें',
    qtyLabel: 'वास्तविक मात्रा',
    priceLabel: 'वास्तविक राशि',
    timestamp: 'तारीख और समय',
    reason: 'कारण',
    quantity: 'मात्रा',
    price: 'राशि',
    updatedBy: 'द्वारा अपडेट किया गया',
    originalParty: 'वास्तविक पार्टी',
    originalNotes: 'वास्तविक नोट्स',
    viewNote: 'नोट देखें',
    party: 'पार्टी',
    note: 'नोट',
  },
  list: {
    dataNotAvailable: 'लागू नहीं',
    codeAndName: 'कोड और नाम',
    brand: 'ब्रांड',
    avlQty: 'उपलब्ध मात्रा',
    minQty: 'न्यूनतम मात्रा',
    ppu: 'प्रति यूनिट कीमत',
    hsn: 'एचएसएन कोड',
    reel: 'रील',
    reels: 'रील्स',
  },
  partySms: {
    title: 'पार्टी को एसएमएस भेजें',
    question:
      'क्या आप हमेशा पार्टी को एक एसएमएस भेजना चाहते हैं, जब भी उनके खिलाफ कोई कार्रवाई की जाती है?',
  },
  details: {
    viewHistory: 'एडिट हिस्ट्री देखें',
    editEntry: 'एंट्री एडिट करें',
    remainingQty: 'बचा हुआ: {{value}} {{unit}}',
    consumedByAt: '{{name}} पर',
    edited: '(एडिट किया गया)',
    actionTypeInOrder: 'में #{{orderCode}}',
    duplicateMaterialBtn: 'डूप्लीकेट',
    backBtn: 'वापस जाएं',
    editMaterialBtn: 'एडिट करें',
    deleteMaterialBtn: 'डिलीट करें',
    dataNotAvailable: 'लागू नहीं',
    avlQty: 'उपलब्ध मात्रा',
    minQty: 'न्यूनतम मात्रा',
    categories: 'कैटगरीज़',
    usageHistory: 'इस्तेमाल की विवरण',
    ppu: 'प्रति यूनिट कीमत',
    brand: 'ब्रांड',
    avlReelsLbl: 'उपलब्ध रील्स',
    reelQty: '{{reel}} रील्स',
    hsnCodeLbl: 'एचएसएन कोड',
  },

  addEditInventoryForm: {
    createMaterialTitle: 'मटीरियल बनाएं',
    editMaterialTitle: 'मटीरियल एडिट करें',
    editProductTitle: 'प्रोडक्ट एडिट करें',
    codeLbl: 'कोड',
    codePlaceholder: 'मटीरियल कोड',
    codeSuggestion: 'सुझाव',
    nameLbl: 'नाम',
    namePlaceholder: 'उदाहरणः क्राफ्ट पेपर 32x45x28',
    unitLbl: 'यूनिट',
    unitPlaceholder: 'उदाहरणः किलो, मीटर',
    brandLbl: 'ब्रांड',
    priceLbl: 'कीमत',
    brandPlaceholder: 'उदाहरणः निपॉन',
    openingStockLbl: 'शुरुआती स्टॉक',
    openingStockPlaceholder: 'उदाहरणः 2500',
    minQtyLbl: 'न्यूनतम मात्रा',
    minQtyPlaceholder: 'उदाहरणः 500',
    categoryLbl: 'कैटगरी',
    categoryPlaceholder: 'उदारहणः शेल्फ-23',
    cancelMaterialBtn: 'कैंसल करें',
    createMaterialBtn: 'बनाएं',
    saveMaterialBtn: 'सेव करें',
    requiredError: 'आवश्यक',
    qtyNegativeError: 'मात्रा नेगेटिव नहीं हो सकती',
    commaNotAllowedError: 'कॉमा(,) की अनुमति नहीं है',
    numberError: 'नंबर डालें',
    copyLbl: '(कॉपी करें)',
    materialInfo: 'मटीरियल इन्फो',
    useReelsLbl: 'रील्स का इस्तेमाल करें',
    reelNameLbl: 'रील का नाम',
    quantityLbl: 'मात्रा',
    addReelBtn: 'एक और रील जोड़ें',
    hsnLbl: 'एचएसएन कोड',
    hsnPlaceholder: 'उदाहरणः 12345678',
    secondaryBtn: 'अतिरिक्त यूनिट इस्तेमाल करें',
    secondaryUnitLbl: 'अतिरिक्त यूनिट',
    conversionRateLbl: 'कन्वर्जन रेट',
    conversionRatePlaceholder: 'उदाहरणः 100',
    stockInfoLbl: 'स्टॉक इन्फो',
    totalQuantity: 'कुल मात्रा :',
    createMaterialTitleBar: 'मटीरियल बनाएं | फैक्ट्रीप्लस',
    editMaterialTitleBar: 'मटीरियल एडिट करें | फैक्ट्रीप्लस',
    productInfo: 'Product Info',
    avlQtyLbl: 'Available Quantity',
    reelsPlaceholder: 'ex: Reel',
  },
  actionLoader: {
    materialInfo: 'मटीरियल इन्फो',
    stockInfoLbl: 'स्टॉक इन्फो',
  },
  inventoryItem: {
    reelImgAlt: 'रील',
  },
  dropdownOption: {
    avlQtyLabel: 'उपलब्ध मात्रा :',
    statusLow: 'कम',
  },
  actionSection: {
    excelBtn: 'बेहतरीन',
    singleMaterial: 'सिंगल मटीरियल',
    multipleMaterials: 'मल्टीपल मटीरियल्स',
    singleProduct: 'सिंगल प्रोडक्ट',
    multipleProducts: 'मल्टीपल प्रोडक्ट्स',
    material: 'मटीरियल',
    product: 'प्रोडक्ट',
  },
};

export default inventory;
