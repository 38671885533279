const reminder = {
  reminders: 'रिमाइंडर्स',
  addReminderBtn: 'रिमाइंडर लगाएं',
  removeReminderTooltip: 'रिमाइंडर हटाएं',
  noReminderMessage1: 'आपके पास कोई भी सेट किया हुआ रिमाइंडर नहीं है',
  noReminderMessage2: 'ऐसा लगता है आपने कोई भी सेट नहीं किया है',
  noReminderMessage3: 'आपकी फैक्ट्री के लिए रिमाइंडर्स',
  deleteReminderModal: {
    title: 'रिमाइंडर डिलीट करें',
    bodyText: 'क्या आपको यकीन है कि आप {{time}} डिलीट करना चाहते हैं?',
    cancelBtn: 'कैंसल करें',
    deleteBtn: 'डिलीट करें',
  },
  updateReminderForm: {
    title: 'रिमाइंडर की डिटेल्स',
    timeLbl: 'समय चुनें',
    timePlaceholder: 'समय',
    every: 'प्रत्येक',
    sunday: 'रविवार',
    monday: 'सोमवार',
    tuesday: 'मंगलवार',
    wednesday: 'बुधवार',
    thursday: 'गुरूवार',
    friday: 'शुक्रवार',
    saturday: 'शनिवार',
    cancelBtn: 'कैंसल करें',
    createBtn: 'बनाएं',
    errorMsg: 'रिमाइंडर पहले से मौजूद है',
    updateBtn: 'अपडेट करें',
  },
};
export default reminder;
