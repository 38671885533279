const products = {
  title: 'प्रोडक्ट्स',
  pageTitle: {
    productsTab: 'प्रोडक्ट्स | फैक्ट्री प्लस',
    categoryTab: 'कैटगरी | फैक्ट्री प्लस',
  },
  excelDownloadFailed: 'एक्सेल फाइल डाउनलोड नहीं हुआ, कृपया दोबारा कोशिश करें',
  excelDownloadSuccess: 'प्रोडक्ट अच्छे तरीके से डाउनलोड हो गया!',
  tabLabelProduct: 'प्रोडक्ट्स',
  tabLabelCategory: 'कैटगरी',
  productsList: {
    title: 'लिस्ट',
    productSearchPlaceholderSuffix: 'प्रोडक्ट कोड, नाम...',
    addProductBtn: 'प्रोडक्ट जोड़ें',
    addProductBulkBtn: 'थोक में अपलोड',
    codeAndName: 'कोड और नाम',
    avlQty: 'उपलब्ध मात्रा',
    ppu: 'प्रति यूनिट कीमत',
    avlQtyUnit: '{{avlQtyPcs}} पीस',
    dataNotAvailable: 'लागू नहीं',
    dispatchBtn: 'भेजें',
    restockBtn: 'रीस्टॉक करें',
    loadMoreBtn: 'और ज्यादा लोड करें',
  },
  productsCategory: {
    title: 'कैटगरी',
    categorySearchPlaceholderSuffix: 'कैटगरी का नाम',
    addCategoryBtn: 'कैटगरी जोड़ें',
    loadMoreBtn: 'और ज्यादा लोड करें',
    totalItems: '{{noOfItems}} आइटम्स',
    editBtn: 'एडिट करें',
    deleteBtn: 'डिलीट करें',
  },
  updateCategoryProductForm: {
    title: 'प्रोडक्ट्स जोड़ें/हटाएं',
    searchProductsPlaceholder: 'प्रोडक्ट्स सर्च करें',
    cancelBtn: 'कैंसल करें',
    saveBtn: 'सेव करें',
  },
  addEditCategoryForm: {
    title: '{{formType}} कैटगरी',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    cancelBtn: 'कैंसल करें',
    createBtn: 'बनाएं',
    editBtn: 'एडिट करें',
    requiredError: 'आवश्यक',
    commaNotAllowedError: 'कॉमा(,) की अनुमति नहीं है',
  },
  deleteCategoryModal: {
    title: 'कैटगरी डिलीट करें',
    bodyText: 'क्या आपको यकीन है कि आप {{categoryName}} डिलीट करना चाहते हैं ?',
    cancelBtn: 'कैंसल करें',
    deleteBtn: 'डिलीट करें',
  },
  productsCategoryDetail: {
    editBtn: 'एडिट करें',
    deleteBtn: 'डिलीट करें',
    productSearchPlaceholderSuffix: 'प्रोडक्ट कोड और नाम...',
    createProductBtn: 'प्रोडक्ट बनाएं',
    addRemoveProductBtn: 'प्रोडक्ट जोड़ें/हटाएं',
  },
  createOrDuplicateProductForm: {
    material: 'Material',
    conversionRatePlaceholder: 'उदाहरणः 100',
    unitPlaceholder: 'उदाहरणः किलो, मीटर',
    secondaryUnitLbl: 'अतिरिक्त यूनिट',
    conversionRateLbl: 'कन्वर्जन रेट',
    productInfo: 'प्रोडक्ट इन्फो',
    stockInfo: 'स्टॉक इन्फो',
    step1: 'प्रोडक्ट डिटेल्स',
    noProcessError: 'कोई भी प्रोसेस चुना नहीं गया',
    noMaterialError: 'कोई भी मटीरियल चुना नहीं गया',
    secondaryBtn: 'अतिरिक्त यूनिट इस्तेमाल करें',
    step2: 'प्रोसेस डिटेल्स',
    step3: 'प्रोडक्ट्स जोड़ें/हटाएं',
    step4: 'मटीरियल अनुपात',
    title: '{{formType}} प्रोडक्ट',
    codeLbl: 'कोड',
    codePlaceholder: 'प्रोडक्ट कोड',
    hsnLbl: 'एचएसएन कोड',
    hsnPlaceholder: 'उदाहरणः 100',
    codeSuggestion: 'सुझाव',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    openingStockLbl: 'शुरुआती स्टॉक',
    unitLbl: 'यूनिट',
    unitPlaceHolder: 'उदाहरणः किलो, मीटर',
    priceLbl: 'प्रति यूनिट कीमत',

    openingStockPlaceholder: 'उदाहरणः 2500',
    openingStockUnit: 'पीस',
    categoryLbl: 'कैटगरी',
    categoryPlaceholder: 'उदाहरणः शेल्फ-23',
    searchProcessPlaceholder: 'प्रोसेस सर्च करें',
    searchMaterialPlaceholder: 'मटीरियल सर्च करें',
    forProductLbl: 'के लिए',
    zeroPlaceholder: '0',
    addMaterialWarningLine1: 'कृपया',
    addMaterialWarningLine2: 'मटीरियल्स जोड़ें',
    addMaterialWarningLine3: 'इस फीचर को इस्तेमाल करने के लिए',
    saveForLater:
      'आप बाद में इस फीचर का इस्तेमाल करने के लिए सेव पर क्लिक करके आगे बढ़ सकते हैं',
    learnMore: 'और ज्यादा जानिए',
    learnMoreTooltip1: 'प्रोडक्शन के लिए आवश्यक मात्राएं डालें',
    learnMoreTooltip2:
      'इस अनुपात का इस्तेमाल भविष्य में हिसाब लगाने के लिए किया जाएगा',
    learnMoreTooltip3: 'इस प्रोडक्ट के प्रोडक्शन के लिए मटीरियल्स की मात्रा',
    prevBtn: 'पिछला',
    nextBtn: 'अगला',
    addBtn: 'जोड़ें',
    skipAndAddBtn: 'इसे छोड़कर आगे बढ़ें और जोड़ें',
    requiredError: 'आवश्यक',
    valueNegativeError: 'वैल्यू नेगेटिव नहीं हो सकती',
    valueDecimalError: 'वैल्यू डेसिमल में नहीं हो सकती',
    commaNotAllowedError: 'कॉमा(,) की अनुमति नहीं है',
    minQtyLbl: 'न्यूनतम मात्रा',
    minQtyPlaceHolder: 'उदाहरणः 500',
  },
  addProductsBulkForm: {
    minQtyLbl: 'न्यूनतम मात्रा',
    ppuLbl: 'प्रति यूनिट कीमत',
    codeExists: 'कोड पहले से मौजूद है',
    minQtyPlaceholder: '0',
    ppuPlaceholder: '999999',
    unitPlaceholder: 'उदाहरणः किलो, मीटर',
    unitLbl: 'यूनिट',
    unitRequiredError: 'यूनिट आवश्यक है',
    title: 'थोक में प्रोडक्ट्स जोड़ें',
    allowedExtensionsText:
      'सिर्फ .xls .xlxs .csv एक्सटेंशन वाली फाइलों की अनुमति है',
    partialFileReviewTextPart1: '{{errorLength}} में से {{totalLength}} ',
    partialFileReviewTextPart2:
      'आइटम्स में गलतियां हैं। अपलोड पूरा करने के लिए गलतियां हटाएं!',
    completeFileReviewText: 'कृपया इसे रीव्यू करें',
    downloadTemplateBtn: 'टेम्प्लेट डाउनलोड करें',
    codeLbl: 'कोड',
    codePlaceholder: 'कोड',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    openingStockLbl: 'शुरुआती स्टॉक',
    openingStockPlaceholder: 'उपलब्ध मात्रा',
    materialsLbl: 'मटीरियल्स',
    materialsPlaceholder: 'मटीरियल्स चुनें',
    processesLbl: 'प्रोसेसेस',
    processesPlaceholder: 'प्रोसेसेस चुनें',
    categoryLbl: 'कैटगरी',
    categoryPlaceholder: 'कैटगरी',
    dragAndDropText: 'ड्रैग और ड्रॉप करें',
    uploadFromComputerText: 'कम्प्यूटर से अपलोड करें ',
    invalidTemplateError:
      'इस फाइल को अपलोड नहीं कर सकते। टेम्प्लेट फाइल को चेक करें और सभी आवश्यक फील्ड भरें। टेस्ट',
    uploadFileAgain: 'दोबारा अपलोड करें',
    cancelBulkProductBtn: 'कैंसल करें',
    uploadBulkProductBtn: 'अपलोड करें',
    requiredError: 'आवश्यक',
    nameRequiredError: 'नाम आवश्यक है',
    qtyNegativeError: 'मात्रा नेगेटिव नहीं हो सकती',
    valueNegativeError: 'वैल्यू नेगेटिव नहीं हो सकती',
    valueDecimalError: 'वैल्यू डेसिमल में नहीं हो सकती',
    commaNotAllowedError: 'कॉमा(,) की अनुमति नहीं है',
    duplicateCodeError: 'डूप्लीकेट कोड',
  },
  productDetail: {
    backBtn: 'वापस जाएं',
    duplicateProductBtn: 'डूप्लीकेट',
    editProductBtn: 'एडिट करें',
    deleteProductBtn: 'डिलीट करें',
    avlQtyLbl: 'उपलब्ध मात्रा',
    avlQty: '{{quantity}} पीस',
    produceBtn: 'उत्पाद',
    restockBtn: 'रीस्टॉक करें',
    dispatchBtn: 'भेजें',
    categories: 'कैटगरीज़',
    processDetails: 'प्रोसेस डिटेल्स',
    materialDetails: 'मटीरियल डिटेल्स',
    usageHistory: 'इस्तेमाल की हिस्ट्री',
    produced: 'उत्पादित',
    dispatched: 'भेजे गए',
    producedOrDispatchedInOrderCode: 'में #{{orderCode}}',
    userAt: '{{name}} पर',
    qtyChange: '{{quantityChange}} पीस',
    remainingQty: 'बचा हुआ:  {{value}} {{unit}}',
    loadMoreBtn: 'और ज्यादा लोड करें',
    ppu: 'प्रति यूनिट कीमत',
    viewHistory: 'एडिट की हिस्ट्री देखें',
    editEntry: 'एंट्री एडिट करें',
  },

  editProductForm: {
    editProductTitleBar: 'प्रोडक्ट एडिट करें | फैक्ट्रीप्लस',
    title: 'प्रोडक्ट एडिट करें',
    codeLbl: 'कोड',
    codePlaceholder: 'प्रोडक्ट कोड',
    codeSuggestion: 'सुझाव',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    categoryLbl: 'कैटगरी',
    categoryPlaceholder: 'उदाहरणः शेल्फ-23',
    updateBtn: 'अपडेट करें',
    requiredError: 'आवश्यक',
    commaNotAllowedError: 'कॉमा(,) की अनुमति नहीं है',
    unitLbl: 'यूनिट',
    unitPlaceHolder: 'उदाहरणः किलो, मीटर',
    priceLbl: 'प्रति यूनिट कीमत',
  },

  restockOrDispatchProductForm: {
    dispatchTitle: 'भेजें',
    restockTitle: 'मात्रा रीस्टॉक करें',
    availableQty: 'उपलब्ध मात्रा',
    qtyLbl: 'मात्रा',
    qtyPlaceholder: 'मात्रा',
    partyLbl: 'पार्टी चुनें',
    partyPlaceholder: 'पार्टी सर्च करें',
    customerLbl: 'कस्टमर चुनें',
    customerPlaceholder: 'कस्टमर सर्च करें',
    notesLbl: 'नोट्स (वैकल्पिक)',
    notesPlaceholder: 'नोट्स',
    dispatchBtn: 'भेजें',
    restockBtn: 'रीस्टॉक करें',
    requiredError: 'आवश्यक',
    qtyNegativeError: 'प्रोडक्ट की मात्रा नेगेटिव नहीं हो सकती',
    qtyZeroError: 'प्रोडक्ट की मात्रा 0  नहीं हो सकती',
    qtyDecimalError: 'प्रोडक्ट की मात्रा डेसिमल में नहीं हो सकती',
    enterQty: 'मात्रा डालें',
    price: 'राशि',
    pricePerUnitLbl: 'प्रति यूनिट कीमत',
    amountLbl: 'राशि',
    savePricePerUnitCheckbox: 'सभी आइटम्स के लिए प्रति यूनिट कीमत सेव करें',
    cancelBtn: 'कैंसल करें',
  },
  deleteProductModel: {
    title: 'प्रोडक्ट डिलीट करें',
    bodyText: 'क्या आपको यकीन है कि आप {{productName}} डिलीट करना चाहते हैं?',
    unableToDeleteMsg: ' माफ़ कीजिए, अभी {{productName}} डिलीट नहीं कर सकते',
    cancelBtn: 'कैंसल करें',
    deleteBtn: 'डिलीट करें',
  },
  processUpdateForm: {
    title: '{{formType}} प्रोसेस',
    processPlaceholder: 'प्रोसेस सर्च करें',
    updateBtn: 'अपडेट करें',
  },
  productInfoUpdateForm: {
    title: 'मटीरियल डिटेल्स',
    forProductLbl: 'के लिए',
    productUnit: 'पीस',
    productPlaceholder: '0',
    addMaterialsText: 'इस फीचर को इस्तेमाल करने के लिए कृपया मटीरियल्स जोड़ें',
    addMaterialsBtn: 'मटीरियल्स जोड़ें',
    addRemoveMaterials: 'प्रोडक्ट्स जोड़ें/हटाएं',
    cancelBtn: 'कैंसल करें',
    saveBtn: 'सेव करें',
    valueZeroError: 'वैल्यू 0 नहीं हो सकती',
    valueNegativeError: 'वैल्यू नेगेटिव नहीं हो सकती',
    productDecimalError: 'प्रोडक्ट डेसिमल में नहीं हो सकता',
    qtyRequiredError: 'मात्रा आवश्यक है',
    requiredError: 'आवश्यक',
  },
  productMaterialUpdateForm: {
    material: 'मटीरियल',
    title: '{{formType}} मटीरियल',
    materialSearchPlaceholder: 'मटीरियल सर्च करें',
    prevBtn: 'पिछला',
    updateBtn: 'अपडेट करें',
  },
};

export default products;
