const materials = {
  title: 'मटीरियल्स',
  pageTitle: {
    materialsTab: 'मटीरियल्स | फैक्ट्री प्लस',
    categoryTab: 'कैटगरी | फैक्ट्री प्लस',
  },
  tabLabelMaterial: 'मटीरियल्स',
  tabLabelCategory: 'कैटगरी',
  excelDownloadFailed: 'एक्सेल फाइल डाउनलोड नहीं हुआ, कृपया दोबारा कोशिश करें',
  excelDownloadSuccess: 'मटीरियल अच्छे तरीके से डाउनलोड हो गया!',
  materialsList: {
    title: 'लिस्ट',
    materialSearchPlaceholderSuffix: 'मटीरियल कोड, नाम...',
    addMaterialBtn: 'मटीरियल जोड़ें',
    addMaterialBulkBtn: 'थोक में अपलोड',
    codeAndName: 'कोड और नाम',
    brand: 'ब्रांड',
    avlQty: 'उपलब्ध मात्रा',
    minQty: 'न्यूनतम मात्रा',
    ppu: 'प्रति यूनिट कीमत',
    dataNotAvailable: 'लागू नहीं',
    refillBtn: 'रीफिल',
    consumeBtn: 'इस्तेमाल करें',
    loadMoreBtn: 'और ज्यादा लोड करें',
    zero: '0',
  },
  materialsCategory: {
    title: 'कैटगरी',
    categorySearchPlaceholderSuffix: 'कैटगरी का नाम',
    addCategoryBtn: 'कैटगरी जोड़ें',
    loadMoreBtn: 'और ज्यादा लोड करें',
    totalItems: '{{noOfItems}} आइटम्स ',
    editBtn: 'एडिट करें',
    deleteBtn: 'डिलीट करें',
  },
  updateCategoryMaterialForm: {
    title: 'मटीरियल्स जोड़ें/हटाएं',
    searchMaterialPlaceholder: 'मटीरियल सर्च करें',
    cancelBtn: 'कैंसल करें',
    saveBtn: 'सेव करें',
  },
  addEditCategoryForm: {
    title: '{{formType}} कैटगरी',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    cancelBtn: 'कैंसल करें',
    createBtn: 'बनाएं',
    editBtn: 'एडिट करें',
    requiredError: 'आवश्यक',
    commaNotAllowedError: 'कॉमा(,) की अनुमति नहीं है',
  },
  deleteCategoryModal: {
    title: 'कैटगरी डिलीट करें',
    bodyText: 'क्या आपको यकीन है कि आप {{categoryName}} डिलीट करना चाहते हैं ?',
    cancelBtn: 'कैंसल करें',
    deleteBtn: 'डिलीट करें',
  },
  materialsCategoryDetail: {
    editBtn: 'एडिट करें',
    deleteBtn: 'डिलीट करें',
    materialSearchPlaceholderSuffix: 'मटीरियल कोड और नाम...',
    createMaterialBtn: 'मटीरियल बनाएं',
    addRemoveMaterialsBtn: 'मटीरियल्स जोड़ें/हटाएं',
  },
  addEditMaterialForm: {
    createTitle: 'मटीरियल बनाएं',
    editTitle: 'मटीरियल एडिट करें',
    codeLbl: 'कोड',
    codePlaceholder: 'मटीरियल कोड',
    codeSuggestion: 'सुझाव',
    nameLbl: 'नाम',
    namePlaceholder: 'उदाहरणः क्राफ्ट पेपर 32x45x28',
    unitLbl: 'यूनिट',
    unitPlaceholder: 'उदाहरणः किलो, मीटर',
    brandLbl: 'ब्रांड',
    priceLbl: 'कीमत',
    brandPlaceholder: 'उदाहरणः निपॉन',
    openingStockLbl: 'शुरुआती स्टॉक',
    openingStockPlaceholder: 'उदाहरणः 2500',
    minQtyLbl: 'न्यूनतम मात्रा',
    minQtyPlaceholder: 'उदाहरणः 500',
    categoryLbl: 'कैटगरी',
    categoryPlaceholder: 'उदाहरणः शेल्फ-23',
    cancelMaterialBtn: 'कैंसल करें',
    createMaterialBtn: 'बनाएं',
    saveMaterialBtn: 'सेव करें',
    requiredError: 'आवश्यक',
    qtyNegativeError: 'मात्रा नेगेटिव नहीं हो सकती',
    commaNotAllowedError: 'कॉमा(,) की अनुमति नहीं है',
    numberError: 'नंबर डालें',
    copyLbl: '(कॉपी करें)',
    materialInfo: 'मटीरियल इन्फो',
    useReelsLbl: 'रील्स इस्तेमाल करें',
    reelNameLbl: 'रील का नाम',
    quantityLbl: 'मात्रा',
    addReelBtn: 'एक और रील जोड़ें',
    hsnLbl: 'एचएसएन कोड',
    hsnPlaceholder: 'उदाहरणः 12345678',
    secondaryBtn: 'अतिरिक्त यूनिट इस्तेमाल करें',
    secondaryUnitLbl: 'अतिरिक्त यूनिट',
    conversionRateLbl: 'कन्वर्जन रेट',
    conversionRatePlaceholder: 'उदाहरणः 100',
    stockInfoLbl: 'स्टॉक इन्फो',
    totalQuantity: 'कुल मात्राः ',
    createMaterialTitleBar: 'मटीरियल बनाएं | फैक्ट्रीप्लस',
    editMaterialTitleBar: 'मटीरियल एडिट करें | फैक्ट्रीप्लस',
  },
  addMaterialsBulkForm: {
    ppuLbl: 'प्रति यूनिट कीमत',
    ppuPlaceholder: '9999999',
    codeExists: 'कोड पहले से मौजूद है',
    title: 'थोक में मटीरियल जोड़ें',
    allowedExtensionsText:
      'सिर्फ .xls .xlxs .csv एक्सटेंशन वाली फाइलों की अनुमति है',
    downloadTemplateBtn: 'टेम्प्लेट डाउनलोड करें',
    partialFileReviewTextPart1: '{{errorLength}} में से {{totalLength}} ',
    partialFileReviewTextPart2:
      'आइटम्स में गलतियां हैं। अपलोड पूरा करने के लिए गलतियां हटाएं!',
    completeFileReviewText: 'कृपया इसे रीव्यू करें',
    codeLbl: 'कोड',
    codePlaceholder: 'मटीरियल कोड',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    brandLbl: 'ब्रांड',
    brandPlaceholder: 'ब्रांड',
    unitLbl: 'यूनिट',
    unitPlaceholder: 'यूनिट',
    avlQtyLbl: 'उपलब्ध मात्रा',
    avlQtyPlaceholder: 'उपलब्ध मात्रा',
    minQtyLbl: 'न्यूनतम मात्रा',
    minQtyPlaceholder: 'न्यूनतम मात्रा',
    categoryLbl: 'कैटगरी',
    categoryPlaceholder: 'कैटगरी',
    dragAndDropText: 'ड्रैग और ड्रॉप करें',
    uploadFromComputerText: 'कम्प्यूटर से अपलोड करें ',
    invalidTemplateError:
      'इस फाइल को अपलोड नहीं कर सकते। टेम्प्लेट फाइल को चेक करें और सभी आवश्यक फील्ड भरें। टेस्ट',
    uploadFileAgain: 'दोबारा अपलोड करें',
    cancelBulkMaterialBtn: 'कैंसल करें',
    uploadBulkMaterialBtn: 'अपलोड करें',
    requiredError: 'आवश्यक',
    nameRequiredError: 'नाम आवश्यक है',
    qtyNegativeError: 'मात्रा नेगेटिव नहीं हो सकती',
    unitRequiredError: 'यूनिट आवश्यक है',
    commaNotAllowedError: 'कॉमा(,) की अनुमति नहीं है',
    duplicateCodeError: 'डूप्लीकेट कोड',
  },
  materialDetail: {
    brand: 'ब्रांड',
    refillBtn: 'रीफिल',
    consumeBtn: 'इस्तेमाल करें',
    consumed: 'इस्तेमाल किया गया',
    refilled: 'रीफिल किया गया',
  },
  refillConsumeMaterialForm: {
    refillFormTitle: 'मटीरियल रीफिल करें',
    consumeFormTitle: 'मटीरियल इस्तेमाल करें',
    availableQty: 'उपलब्ध मात्रा',
    qtyLbl: 'मात्रा',
    qtyPlaceholder: 'उदाहरणः 4000',
    vendorLbl: 'वेंडर चुनें (वैकल्पिक)',
    vendorPlaceholder: 'वेंडर सर्च करें',
    customerLbl: 'कस्टमर चुनें (वैकल्पिक)',
    customerPlaceholder: 'कस्टमर चुनें',
    notesLbl: 'नोट्स (वैकल्पिक)',
    notesPlaceholder: 'नोट्स',
    priceLbl: 'राशि',
    pricePerUnitLbl: 'प्रति यूनिट कीमत',
    amountLbl: 'राशि',
    savePricePerUnitCheckbox: 'सभी आइटम्स के लिए प्रति यूनिट कीमत सेव करें',
    cancelBtn: 'कैंसल करें',
    refillBtn: 'रीफिल',
    consumeBtn: 'इस्तेमाल करें',
    requiredError: 'आवश्यक',
    qtyNegativeError: 'मात्रा नेगेटिव नहीं हो सकती',
    qtyZeroError: 'मात्रा 0 नहीं हो सकती',
    stockInfoLbl: 'स्टॉक इन्फो',
    reelNameLbl: 'रील का नाम',
    namePlaceHolder: 'उदाहरणः रील 1-27/04',
    addReelBtn: 'एक और रील जोड़ें',
    totalQuantity: 'कुल मात्राः ',
    materialInfo: 'मटीरियल इन्फो',
    nameLbl: 'नाम',
    noteBtn: 'नोट जोड़ें',
    materialLbl: 'मटीरियल',
    atLeastOneError: 'कम से कम एक रील के लिए मात्रा डालें',
    avlQtyLbl: 'उपलब्ध मात्रा:',
    reelInfoLbl: 'इस मटीरियल में रील है',
    reelsLbl: 'रील्स',
    reelsPlaceholder: 'उदाहरणः रील',
    noReels: 'कोई रील्स नहीं',
    noReeltitleMessage: 'कोई भी रील नहीं मिला',
    noReelBodyMessage:
      'ऐसा लगता है कि आपने अपने सभी रील्स इस्तेमाल कर लिए हैं। जारी रखने के लिए रील्स को रीफिल करें',
  },
  deleteMaterialModel: {
    title: 'मटीरियल डिलीट करें',
    bodyText: 'क्या आपको यकीन है कि आप {{materialName}} डिलीट करना चाहते हैं?',
    unableToDeleteMsg: 'माफ़ कीजिए, अभी {{materialName}} डिलीट नहीं कर सकते',
    cancelBtn: 'कैंसल करें',
    deleteBtn: 'डिलीट करें',
  },
};

export default materials;
