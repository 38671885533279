const signUp = {
  signUpForm: {
    ownerNameLbl: 'मालिक का नाम',
    namePlaceholder: 'उदाहरणः रमेश शर्मा',

    factoryNameLbl: 'फैक्ट्री का नाम',
    factoryNamePlaceholder: 'उदाहरणः रमेश पैकर्स',

    addressLbl: 'पता ',
    addressLblPlaceholder: 'उदाहरण: नोएडा, यूपी',

    continueBtn: 'जारी रखना',
    completeBtn: 'पूरा सेटअप',

    signupSteps: {
      step1: {
        heading: 'विवरण',
        title: 'आइए आपकी प्रोफ़ाइल बनाएं',
        subTitle: 'हमें अपने फ़ैक्टरी व्यवसाय के बारे में बताएं',
      },
      step2: {
        heading: 'आरएम प्रकार',
        title: 'आप किस कच्चे माल का उपयोग करते हैं?',
        subTitle: 'नीचे दी गई सूची से सामग्री चुनें',
      },
    },
    rmTypesName: {
      plastic: 'प्लास्टिक',
      papers: 'पत्रों',
      metals: 'धातुओं',
      textiles: 'कपड़ा',
      electrical: 'विद्युतीय',
      chemicals: 'रसायन',
      electronics: 'इलेक्ट्रानिक्स',
      others: 'अन्य',
    },
  },
  requiredMessage: 'आवश्यक',
  validEmailMessage: 'कृपया सही ईमेल डालें',
};
export default signUp;
