const orders = {
  title: 'ऑर्डर्स',
  createOrderBtn: 'ऑर्डर बनाएं',
  activeTab: 'एक्टिव',
  upcomingTab: 'आगामी',
  pastTab: 'पिछला',
  orderSearchPlaceholderSuffix: 'ऑर्डर आईडी, प्रोडक्ट का नाम...',
  filtersDropdownTitle: 'फिल्टर्स',
  createdOn: 'पर बनाया गया',
  orderQty: '{{qty}} पीस',
  lastTimeUpdated: '{{time}} पहले अपडेट किया गया',
  searchFinishDatePlaceholder: 'समाप्ति की तारीख सर्च करें',
  finishedOn: 'पर समाप्त हुआ',
  loadMoreBtn: 'और ज्यादा लोड करें',
  noResultFound: 'ओह! हमें {{searchText}} से संबंधित कोई डिटेल नहीं मिला',
  noOrders: 'ऐसा लगता है कि आपके पास अभी तक कोई ऑर्डर नहीं है',
  dataNotAvailable: 'लागू नहीं',
  filterLbl: {
    scheduledLbl: 'शेड्यूल्ड',
    partiallyScheduledLbl: 'आंशिक रूप से शेड्यूल्ड',
    unscheduledLbl: 'अनशेड्यूल्ड',
    finishedLbl: 'समाप्त हुआ',
    cancelledLbl: 'कैंसल हुआ',
  },
  subStatusLbl: {
    onTimeLbl: 'समय पर',
    delayedLbl: 'देरी से',
    pausedLbl: 'रुका हुआ',
  },
  statusToastMessage: {
    successMessage: 'ऑर्डर अपडेट किया गया',
    errorMessage: 'ऑर्डर स्टेटस अपडेट नहीं हो सका',
  },
  orderDetail: {
    title: 'ऑर्डर आईडी: {{orderId}}',
    orderSettingsBtn: 'ऑर्डर सेटिंग्स',
    finishOrderBtn: 'ऑर्डर समाप्त करें',
    startOrderBtn: 'ऑर्डर शुरू करें',
    editOrderDrawerTitle: 'ऑर्डर एडिट करें',
    status: 'स्टेटस',
    createdOn: 'पर बनाया गया',
    rawMaterials: 'रॉ मटीरियल्स',
    rawMaterialsClickLeft: 'रॉ मटीरियल्स',
    rawMaterialsClickRight: 'मात्रा',
    lastUpdated: 'अंतिम बार अपडेट किया गया',
    lastTimeUpdated: '{{time}} पहले',
    productMoved: 'प्रोडक्ट हट गया',
    productQty: '{{qty}} पीस',
    orderJobsTitle: 'इस ऑर्डर में जॉब्स',
    bulkUpdateBtn: 'थोक में अपडेट',
    recordPartProductionBtn: 'पार्ट प्रोडक्शन रिकॉर्ड करें',
    recordPartProductionDesc:
      'इस ऑर्डर से बने प्रोडक्ट और इस्तेमाल किए गए मटीरियल को रिकॉर्ड करें',
    productionHistoryBtn: 'प्रोडक्शन की हिस्ट्री',
    productionHistoryDesc:
      'अभी तक ऑर्डर में बनाए गए आंशिक प्रोडक्शन्स को चेक करें',
    editOrderBtn: 'ऑर्डर एडिट करें',
    editOrderDesc: 'ऑर्डर में टार्गेट मात्रा और मटीरियल्स को एडिट करें',
    cancelOrderBtn: 'ऑर्डर कैंसल करें',
    cancelOrderDesc: 'ऑर्डर के प्रोडक्शन को बंद करें',
    deleteOrderBtn: 'ऑर्डर डिलीट करें',
    deleteOrderDesc: 'इस ऑर्डर को हटाएं',
  },
  jobDetail: {
    onLbl: 'पर',
    addMachine: 'मशीन जोड़ें',
    noMachine: 'मशीन नहीं',
    qty: '{{qty}} पीस',
    qtyUnit: 'पीस',
    okUnits: 'ओके यूनिट्स',
    rejected: 'खारिज किया हुआ',
    updateHistoryBtn: 'हिस्ट्री अपडेट करें',
    updateQuantityBtn: 'मात्रा अपडेट करें',
    updatedLastTime: '{{time}} पहले अपडेट किया गया',
    createdLastTime: '{{time}} पहले बनाया गया',
    supervisors: 'सुपरवाईज़र्स',
    addBtn: 'जोड़ें',
    closeBtn: 'बंद करें',
    removeBtn: 'हटाएं',
    searchEmployeePlaceholder: 'एमप्लॉई का नाम डालें',
    noSupervisorsAssigned: 'अभी तक कोई सुपरवाइज़र नियुक्त नहीं किया गया है',
    machineLbl: 'मशीन',
    machineSearchPlaceholder: 'मशीन का नाम डालें ',
    selectMachine: 'मशीन चुनें',
    unknown: 'अज्ञात',
    dateAndTimeLbl: 'तारीख और समय',
    dateAndTimePlaceholder: 'dd/mm/yyyy',
    setStartingDateAndTime: 'शुरुआती तारीख और समय सेट करें',
    noteLbl: 'नोट',
    notePlaceholder: 'जॉब विवरण डालें',
    enterJobDescription: 'जॉब विवरण डालें',
    cancelBtn: 'कैंसल करें',
    saveBtn: 'सेव करें',
    editBtn: 'एडिट करें',
  },
  updateQuantityForm: {
    title: 'ऑर्डर आईडी अपडेट करें: #{{orderId}}',
    jobs: 'जॉब्स',
    okItems: 'ओके आइटम्स',
    rejectedItems: 'खारिज किए गए आइटम्स',
    qtyPlaceholder: 'मात्रा',
    qtyUnit: 'पीस',
    cancelBtn: 'कैंसल करें',
    updateBtn: 'अपडेट करें',
    okItemsRequiredError: 'ओके आइटम्स आवश्यक हैं',
    okItemsNegativeError: 'ओके आइटम्स नेगेटिव नहीं हो सकते',
    rejectedItemsNegativeError: 'खारिज किए गए आइटम्स नेगेटिव नहीं हो सकते',
  },
  jobUpdatedDetailForm: {
    title: 'हिस्ट्री अपडेट करें',
    updatedJob: '{{jobName}}: {{qty}} पीस',
    dateAndTime: 'तारीख और समय',
    updatedBy: 'द्वारा अपडेट किया गया',
    okUnits: 'ओके यूनिट्स',
    rejectedUnits: 'खारिज किए गए यूनिट्स',
    loadMoreBtn: 'और ज्यादा लोड करें',
    qtyUnit: 'पीस',
    qty: '{{qty}} पीस',
    dataNotAvailable: 'लागू नहीं',
  },
  partProductionForm: {
    title: 'पार्ट प्रोडक्शन',
    summarySinceStart: 'शुरू से लेकर अभी तक का सार',
    totalProductManufactured: 'कुल बनाए गए प्रोडक्ट',
    totalMaterialsConsumed: 'इस्तेमाल किए गए कुल मटीरियल्स',
    qty: '{{qty}} पीस',
    qtyUnit: 'पीस',
    newProduction: 'नया प्रोडक्शन',
    moreMaterialConsumed: 'इस जगह पर अनुमान से ज्यादा मटीरियल का इस्तेमाल हुआ',
    noMaterialsAdded: 'कोई भी मटीरियल जोड़ा नहीं गया',
    accordionTitleProductManufactured: 'बनाए गए प्रोडक्ट',
    accordionTitleMaterialsConsumed: 'इस्तेमाल किए गए मटीरियल्स',
    accordionTitleProductionSummary: 'प्रोडक्शन का सार',
    enterQtyOf: 'की मात्रा डालें',
    manufacturedSince: 'से बनाए गए',
    lastProduction: 'अंतिम प्रोडक्शन',
    start: 'शुरू करें',
    enterQtyOfMaterials: 'से इस्तेमाल किए गए मटीरियल्स की मात्रा डालें',
    productPlaceholder: '0',
    materialPlaceholder: 'मात्रा',
    productManufactured: 'बनाए गए प्रोडक्ट',
    materialsConsumed: 'इस्तेमाल किए गए मटीरियल्स',
    name: 'नाम',
    before: 'पहले',
    manufactured: 'बनाए गए',
    consumed: 'इस्तेमाल किए गए',
    newTotal: 'नया कुल योग',
    nextBtn: 'अगला',
    backBtn: 'पिछला',
    recordBtn: 'रिकॉर्ड',
    markForDispatchBtn: 'भेजने के लिए चिह्नित करें',
  },
  cancelOrderForm: {
    title: 'ऑर्डर कैंसल करें',
    summarySinceStart: 'शुरू से लेकर अभी तक का सार',
    totalProductManufactured: 'कुल बनाए गए प्रोडक्ट',
    totalMaterialsConsumed: 'इस्तेमाल किए गए कुल मटीरियल्स',
    qtyUnit: 'पीस',
    qty: '{{qty}} पीस',
    moreMaterialConsumed: 'इस जगह पर अनुमान से ज्यादा मटीरियल का इस्तेमाल हुआ',
    noMaterialsAdded: 'कोई भी मटीरियल जोड़ा नहीं गया',
    finalProduction: 'फाइनल प्रोडक्शन',
    accordionTitleProductManufactured: 'बनाए गए प्रोडक्ट',
    accordionTitleMaterialsConsumed: 'इस्तेमाल किए गए मटीरियल्स',
    accordionTitleProductionSummary: 'प्रोडक्शन का सार',
    enterQtyOf: 'की मात्रा डालें',
    manufacturedSince: 'से बनाए गए',
    lastProduction: 'अंतिम प्रोडक्शन',
    start: 'शुरू करें',
    productQtyPlaceholder: '0',
    enterQtyOfMaterials: 'से इस्तेमाल किए गए मटीरियल्स की मात्रा डालें',
    materialPlaceholder: 'मात्रा',
    productManufactured: 'बनाए गए प्रोडक्ट',
    materialsConsumed: 'इस्तेमाल किए गए मटीरियल्स',
    name: 'नाम',
    before: 'पहले',
    manufactured: 'बनाए गए',
    consumed: 'इस्तेमाल किए गए',
    newTotal: 'नया कुल योग',
    nextBtn: 'अगला',
    backBtn: 'पिछला',
    recordAndCancelBtn: 'Record & Cancel',
    markForDispatchBtn: 'भेजने के लिए चिह्नित करें',
  },
  finishOrderForm: {
    title: 'ऑर्डर समाप्त करें',
    summarySinceStart: 'शुरू से लेकर अभी तक का सार',
    totalProductManufactured: 'कुल बनाए गए प्रोडक्ट',
    totalMaterialsConsumed: 'इस्तेमाल किए गए कुल मटीरियल्स',
    qty: '{{qty}} पीस',
    moreMaterialConsumed: 'इस जगह पर अनुमान से ज्यादा मटीरियल का इस्तेमाल हुआ',
    noMaterialsAdded: 'कोई भी मटीरियल जोड़ा नहीं गया',
    finalProduction: 'फाइनल प्रोडक्शन',
    accordionTitleProductManufactured: 'बनाए गए प्रोडक्ट',
    accordionTitleMaterialsConsumed: 'इस्तेमाल किए गए मटीरियल्स',
    accordionTitleProductionSummary: 'प्रोडक्शन का सार',
    enterQtyOf: 'की मात्रा डालें',
    manufacturedSince: 'से बनाए गए',
    lastProduction: 'अंतिम प्रोडक्शन',
    start: 'शुरू करें',
    productPlaceholder: '0',
    qtyUnit: 'पीस',
    enterQtyOfMaterials: 'से इस्तेमाल किए गए मटीरियल्स की मात्रा डालें',
    materialPlaceholder: 'मात्रा',
    productManufactured: 'बनाए गए प्रोडक्ट',
    materialsConsumed: 'इस्तेमाल किए गए मटीरियल्स',
    name: 'नाम',
    before: 'पहले',
    manufactured: 'बनाए गए',
    consumed: 'इस्तेमाल किए गए',
    newTotal: 'नया कुल योग',
    nextBtn: 'अगला',
    backBtn: 'पिछला',
    markForDispatchBtn: 'भेजने के लिए चिह्नित करें',
    recordAndFinishBtn: 'रिकॉर्ड और समाप्त करें',
  },
  productionHistory: {
    title: 'प्रोडक्शन लॉग्स ({{name}})',
    recordPartProductionBtn: 'पार्ट प्रोडक्शन रिकॉर्ड करें',
    productionNumber: 'प्रोडक्शन  {{number}}',
    productManufactured: 'बनाए गए प्रोडक्ट',
    materialsConsumed: 'इस्तेमाल किए गए मटीरियल्स',
    name: 'नाम',
    before: 'पहले',
    manufactured: 'बनाए गए',
    consumed: 'इस्तेमाल किए गए',
    newTotal: 'नया कुल योग',
    qty: '{{qty}} पीस',
    qtyUnit: 'पीस',
    noProductionActivity: 'इस ऑर्डर के पास कोई भी प्रोडक्शन एक्टिविटी नहीं है',
  },
  createOrderDrawer: {
    stepSelectProduct: 'प्रोडक्ट चुनें',
    stepArrangeJobs: 'जॉब्स की व्यवस्था करें',
    stepAddRawMaterials: 'रॉ मटीरियल्स जोड़ें',
    title: 'ऑर्डर बनाएं',
    orderIdLbl: 'ऑर्डर आईडी',
    orderIdPlaceholder: 'प्रोडक्ट कोड',
    suggestion: 'सुझाव',
    productLbl: 'प्रोडक्ट चुनें',
    productPlaceholder: 'प्रोडक्ट सर्च करें...',
    qtyLbl: 'मात्रा डालें',
    qtyPlaceholder: 'मात्रा*',
    qtyUnit: 'पीस',
    orderId: 'ऑर्डर आईडी:#{{id}}',
    qty: '{{qty}} पीस',
    jobs: 'जॉब्स',
    addBtn: 'जोड़ें',
    closeBtn: 'बंद करें',
    processSearchPlaceholder: 'प्रोसेस का नाम डालें',
    noJobsAdded: 'अभी तक कोई भी जॉब जोड़ा नहीं गया है',
    rawMaterials: 'रॉ मटीरियल्स',
    rawMaterialsTooltipLine1:
      'का इस्तेमाल करते हुए मटीरियल की मात्राओं को दिखाया गया है',
    rawMaterialsTooltipLine2: 'इस प्रोडक्ट के लिए मटीरियल अनुपात सेट',
    materialSearchPlaceholder: 'मटीरियल का नाम डालें',
    avlQty: 'उपलब्ध मात्रा',
    materialPlaceholder: 'मात्रा',
    noMaterialAdded: 'अभी तक कोई भी मटीरियल जोड़ा नहीं गया है',
    saveMaterialRatio: 'आगे इस्तेमाल करने के लिए मटीरियल अनुपात को सेव करें',
    prevBtn: 'पिछला',
    nextBtn: 'अगला',
    createBtn: 'बनाएं',
    orderCreatedSuccessfully: 'ऑर्डर अच्छे तरीके से बना दिया गया',
    doneForNowBtn: 'अभी के लिए पूरा हुआ',
    assignNowBtn: 'अभी नियुक्त करें',
    requiredError: 'आवश्यक',
  },
  editOrderDrawer: {
    targetQtyLbl: 'टार्गेट मात्रा',
    targetQtyPlaceholder: 'मात्रा',
    qtyUnit: 'पीस',
    rawMaterials: 'रॉ मटीरियल्स',
    addBtn: 'जोड़ें',
    closeBtn: 'बंद करें',
    materialSearchPlaceholder: 'मटीरियल का नाम डालें',
    avlQty: 'उपलब्ध मात्रा',
    materialPlaceholder: 'मात्रा',
    noMaterialAdded: 'अभी तक कोई भी मटीरियल जोड़ा नहीं गया है',
    jobs: 'जॉब्स',
    processSearchPlaceholder: 'प्रोसेस का नाम डालें',
    noProcessAdded: 'अभी तक कोई भी प्रोसेस जोड़ा नहीं गया',
    cancelOrderBtn: 'ऑर्डर कैंसल करें',
    deleteOrderBtn: 'ऑर्डर डिलीट करें',
    saveBtn: 'सेव करें',
  },
  deleteOrderModal: {
    title: 'क्या आपको यकीन है?',
    bodyText: 'आप ऑर्डर डिलीट करने जा रहे हैं #{{orderId}}',
    cancelBtn: 'कैंसल करें',
    deleteBtn: 'डिलीट करें',
  },
  dispatchPartialProductForm: {
    title: 'भेजें',
    qtyLbl: 'मात्रा*',
    qtyPlaceholder: 'मात्रा',
    qtyUnit: 'पीस',
    notesLbl: 'नोट्स (वैकल्पिक)',
    cancelBtn: 'कैंसल करें',
    dispatchBtn: 'भेजें',
    requiredError: 'आवश्यक',
    qtyZeroError: 'प्रोडक्ट 0 नहीं हो सकता',
    qtyNegativeError: 'प्रोडक्ट की मात्रा नेगेटिव नहीं हो सकती',
    qtyDecimalError: 'प्रोडक्ट की मात्रा डेसिमल में नहीं हो सकती',
  },
  finishPartProductionModal: {
    title: 'पार्ट प्रोडक्शन रिकॉर्ड किया गया !',
    manufactured: 'बनाए गए',
    dispatch: 'भेजें',
    qty: '{{qty}} पीस',
    orderTargetExceededPart1: 'ऑर्डर का टार्गेट तय सीमा से ज्यादा है - (',
    orderTargetExceededPart2: ') {{unit}} क्या आप भी चाहते हैं “',
    orderTargetExceededPart3: 'समाप्त',
    orderTargetExceededPart4: '” ऑर्डर',
    skipBtn: 'इसे छोड़कर आगे बढ़ें',
    finishOrderBtn: 'ऑर्डर समाप्त करें',
  },
  successPartialDispatchModal: {
    title: 'पार्ट प्रोडक्शन रिकॉर्ड किया गया !',
    manufactured: 'बनाए गए',
    dispatch: 'भेजें',
    qty: '{{qty}} पीस',
  },
  successFinishOrderModal: {
    title: 'ऑर्डर समाप्त हुआ !',
    qty: '{{qty}} पीस',
    totalManufactured: 'कुल बनाए गए',
    totalDispatched: 'कुल भेजे गए',
  },
  successCancelOrderModal: {
    title: 'ऑर्डर कैंसल किए गए !',
    qty: '{{qty}} पीस',
    totalManufactured: 'कुल बनाए गए',
    totalDispatched: 'कुल भेजे गए',
  },
  listing: {
    title: 'इसके अनुसार क्रमबद्ध करें',
    orderByDateOfCreation: 'निर्माण की तारीख',
    orderByProductName: 'प्रोडक्ट का नाम',
    orderByCompletion: 'समापन',
    orderByTargetQuantity: 'टार्गेट मात्रा',
    orderByFinishedDate: 'समाप्त तिथि',
  },
};
export default orders;
