import { FC, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './store/Reducer';

import Maintenance from './components/Maintenance';
import RouteAuthenticated from './authenticatedRoute';
import RouteUnauthenticated from './unAuthenticatedRoute';
import RouteAuthenticatedWithSignuptoken from './authenticatedWithSignuptoken';
import mixpanel from 'mixpanel-browser';

import { ADMIN } from './constants';

const LazyPartialProductionLog = lazy(
  () => import('./containers/Orders/partialProductionLog')
);
const LazyProductionLog = lazy(
  () => import('./containers/Production/productionHistory')
);
const LazyLogin = lazy(() => import('./containers/Login'));
const LazyReminder = lazy(() => import('./containers/Reminders'));
const LazyHome = lazy(() => import('./containers/Home'));
const LazyApp = lazy(() => import('./components/AppLink'));
const LazyProcess = lazy(() => import('./containers/Processes'));
const LazyLedger = lazy(() => import('./containers/Ledger'));
const LazyEmployees = lazy(() => import('./containers/Employees'));
const LazySetting = lazy(() => import('./containers/Setting'));

const LazyAddProduct = lazy(
  () => import('./containers/Inventory/pages/addProduct')
);
const LazyProcessDetails = lazy(
  () => import('./containers/Processes/processDetail')
);

const LazyEmployeDetail = lazy(
  () => import('./containers/Employees/employeeDetail')
);
const LazySignUp = lazy(() => import('./containers/SignUp'));

const LazyInventoryDetail = lazy(
  () => import('./containers/Inventory/inventoryDetail')
);

const LazyMaterial = lazy(() => import('./containers/Inventory/material'));
const LazyUpdateInventory = lazy(
  () => import('./containers/Inventory/pages/updateInventory')
);
const LazyProduct = lazy(() => import('./containers/Inventory/product'));
const LazyOrder = lazy(() => import('./containers/Orders'));
const LazyOrderDetails = lazy(() => import('./containers/Orders/orderDetails'));
const LazyProductionDetails = lazy(
  () => import('./containers/Production/productionDetails')
);
const LazyProduction = lazy(() => import('./containers/Production'));

const LazyParty = lazy(() => import('./containers/Party/index'));

const LazyPartyDetails = lazy(() => import('./containers/Party/partyDetails'));
const LazyMaterialCategoryDetails = lazy(
  () => import('./containers/Inventory/materialCategoryDetails')
);
const LazyProductCategoryDetails = lazy(
  () => import('./containers/Inventory/productCategoryDetails')
);

const LazyMaterialBuy = lazy(
  () => import('./containers/FactoryRequest/rmPurchaseRequest')
);

const LazySingleRefill = lazy(
  () => import('./containers/Inventory/pages/singleRefill')
);
const LazySingleConsume = lazy(
  () => import('./containers/Inventory/pages/singleConsume')
);

const LazyConsume = lazy(() => import('./containers/Home/pages/bulkConsume'));

const LazyRefill = lazy(() => import('./containers/Home/pages/bulkRefill'));

const LazyReports = lazy(() => import('./containers/Reports/pages'));
const LazySalesReports = lazy(() => import('./containers/Reports/pages/sales'));
const LazyPurchasesReports = lazy(
  () => import('./containers/Reports/pages/purchases')
);

import useWindowDimensions from './hooks/useDimensionsWithResize';
import storageService, { STORAGE_KEY } from './services/storageService';

const Routes: FC = () => {
  const fallbackUri = `${
    storageService.get(STORAGE_KEY.SIGN_UP_IN_PROGRESS)
      ? '/signup'
      : storageService.get(STORAGE_KEY.REFRESH_TOKEN)
      ? '/dashboard'
      : '/login'
  }`;

  const { user } = useSelector((state: RootState) => state);
  useEffect(() => {
    const refreshToken: string | null = storageService.get(
      STORAGE_KEY.REFRESH_TOKEN
    );
    const token: string | null = storageService.get(STORAGE_KEY.TOKEN);

    if (!(refreshToken && token)) {
      [
        STORAGE_KEY.TOKEN,
        STORAGE_KEY.TOKEN_EXPIRES,
        STORAGE_KEY.REFRESH_TOKEN,
      ].forEach((key) => storageService.remove(key));
    }
  }, []);

  const maintenanceMode: string | null = storageService.get(
    STORAGE_KEY.MAINTENANCE
  );
  const { width } = useWindowDimensions();

  useEffect(() => {
    mixpanel.register({
      email: user?.email,
      factoryName: user.factoryName,
      userId: user.userId,
      userName: user.name,
      role: user.role,
      factoryId: user.factoryId,
    });
  }, []);

  return width && width > 1023 ? (
    <Router>
      <Suspense fallback={null}>
        {maintenanceMode === 'on' ? (
          <Maintenance />
        ) : (
          <Switch>
            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated
                path="/processes/:id"
                component={LazyProcessDetails}
              />
            )}
            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated path="/processes" component={LazyProcess} />
            )}

            <RouteAuthenticated
              path="/product/add"
              component={LazyAddProduct}
            />

            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated
                path="/employees/:id"
                component={LazyEmployeDetail}
              />
            )}
            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated path="/employees" component={LazyEmployees} />
            )}
            <RouteAuthenticated
              path="/single_refill/:id"
              component={LazySingleRefill}
            />
            <RouteAuthenticated
              path="/single_consume/:id"
              component={LazySingleConsume}
            />

            <RouteAuthenticated
              path="/material/add"
              component={LazyUpdateInventory}
            />
            <RouteAuthenticated
              path="/material/:id/edit"
              component={LazyUpdateInventory}
            />
            <RouteAuthenticated
              path="/product/:id/edit"
              component={LazyUpdateInventory}
            />
            <RouteAuthenticated
              path="/material/:id"
              component={LazyInventoryDetail}
            />

            <RouteAuthenticated
              path="/refill_materials"
              component={LazyRefill}
            />

            <RouteAuthenticated
              path="/consume_materials"
              component={LazyConsume}
            />
            <RouteAuthenticated path="/materials" component={LazyMaterial} />
            <RouteAuthenticated
              path="/product/:id"
              component={LazyInventoryDetail}
            />
            <RouteAuthenticated path="/products" component={LazyProduct} />

            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated path="/ledger" component={LazyLedger} />
            )}
            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated
                path="/orders/:id/logs"
                component={LazyPartialProductionLog}
              />
            )}
            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated
                path="/orders/:id"
                component={LazyOrderDetails}
              />
            )}
            <RouteAuthenticated path="/orders" component={LazyOrder} />
            <RouteAuthenticated
              path="/production/:id"
              component={LazyProductionDetails}
            />
            <RouteAuthenticated
              path="/production_log/:id"
              component={LazyProductionLog}
            />
            <RouteAuthenticated path="/production" component={LazyProduction} />
            <RouteAuthenticated
              path="/material_category/:id"
              component={LazyMaterialCategoryDetails}
            />
            <RouteAuthenticated
              path="/product_category/:id"
              component={LazyProductCategoryDetails}
            />
            <RouteAuthenticated path="/dashboard" component={LazyHome} />

            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated
                path="/party/:id"
                component={LazyPartyDetails}
              />
            )}

            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated path="/party" component={LazyParty} />
            )}

            <RouteAuthenticated
              path="/material_buy/:rmType"
              component={LazyMaterialBuy}
            />

            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated path="/reminders" component={LazyReminder} />
            )}

            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated
                path="/reports/sales"
                component={LazySalesReports}
              />
            )}

            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated
                path="/reports/purchases"
                component={LazyPurchasesReports}
              />
            )}

            {user.role.toLowerCase() === ADMIN && (
              <RouteAuthenticated path="/reports" component={LazyReports} />
            )}

            <RouteAuthenticatedWithSignuptoken
              path="/signup"
              component={LazySignUp}
            />

            <RouteAuthenticated path="/settings" component={LazySetting} />
            <RouteUnauthenticated path="/login" component={LazyLogin} />
            <RouteUnauthenticated path="/app" component={LazyApp} />
            <Redirect to={fallbackUri} />
          </Switch>
        )}
      </Suspense>
    </Router>
  ) : (
    <Suspense fallback={null}>
      <LazyApp />
    </Suspense>
  );
};

export default Routes;
