import { AxiosPromise } from 'axios';
import { axiosInstance } from '../../utils/axiosInterceptor';
import storageService, { STORAGE_KEY } from '../storageService';

export const setSignUpInProgress = () =>
  storageService.set(STORAGE_KEY.SIGN_UP_IN_PROGRESS, '1');

export const checkSignUpInProgress = () =>
  storageService.get(STORAGE_KEY.SIGN_UP_IN_PROGRESS) === '1';

export const removeSignUpInProgressFlag = () =>
  localStorage.removeItem(STORAGE_KEY.SIGN_UP_IN_PROGRESS);

export const getIndustriesList = (): AxiosPromise =>
  axiosInstance({
    url: `v1/industries`,
    method: 'get',
  });
