const successMessage = {
  orderStarted: 'Order started!',
  orderUpdated: 'Order updated!',
  orderDeleted: 'Order deleted!',
  orderFinished: 'Order finished!',
  jobUpdated: 'Job updated!',
  quantityUpdated: 'Quantity updated!',
  productMovedToInventory: 'Product moved to inventory!',
  materialCreated: 'Material created.',
  materialUpdated: 'Material updated!',
  bulkMaterialsCreated: 'Materials created!',
  materialQuantityUpdated: 'Material quantity updated!',
  materialDeleted: 'Material deleted!',
  bulkProductsCreated: 'Products created!',
  productUpdated: 'Product updated!',
  productDeleted: 'Product deleted!',
  productQuantityUpdated: 'Product quantity updated!',
  categoryUpdated: 'Category updated!',
  categoryCreated: 'Category created!',
  categoryEdited: 'Category edited!',
  categoryDeleted: 'Category deleted!',
  partyCreated: 'Party created!',
  machineUpdated: 'Machine updated!',
  bulkMachinesCreated: 'Machines created!',
  machineDeleted: 'Machine deleted!',
  machineOperationUpdated: 'Machine Operation Updated',
  processCreated: 'Process created!',
  processUpdated: 'Process updated!',
  bulkProcessesCreated: 'Processes created!',
  processDeleted: 'Process deleted!',
  employeeAssignedToProcess: 'Employee added!',
  employeeRemovedFromProcess: 'Employee deleted!',
  employeeUpdated: 'Employee updated!',
  employeeDeleted: 'Employee deleted!',
  employeeActivated: 'Employee Activated!',
  employeeReactivated: 'Employee Reactivated!',
  employeeDeactivated: 'Employee Deactivated!',
  partyAdded: '{{type}} Added',
  partyUpdated: '{{type}} Updated',
  deactivated: '{{type}} Deactivated',
  ledgerDownloaded: 'Ledger downloaded successfully!',
  reminderDeleted: 'Reminder deleted!',
  factoryDetailsUpdated: 'Factory details updated!',
  profileDetailsUpdated: 'Profile details updated!',
  reminderCreated: 'Reminder created!',
  reminderUpdated: 'Reminder updated!',
  productCreated: 'Product created!',
  productDispatched: 'Product dispatched!',
  machineCreated: 'Machine created!',
  employeeCreated: 'Employee created!',
  materialsConsumed: 'Materials Consumed',
  materialsRefilled: 'Materials Refilled',
  productStockUpdated: 'Products stock updated',
  productionCreated: 'Production created!',
  materialRatioSaved: 'Material Ration saved',
  bulkCategoryCreated: 'Category created!',
};

export default successMessage;
