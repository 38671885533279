const party = {
  partyForm: {
    nameLbl: 'Name',
    namePlaceholder: 'ex: Ramesh Industries',
    nameRequiredError: 'Required',
    nameInvalidError: 'Required',

    typeLbl: 'Party Type',
    typePlaceholder: 'Customer/Vendor',

    mobileLbl: 'Mobile No.',
    mobileCode: '+91',
    mobilePlaceholder: 'Mobile No',
    mobileRequiredError: 'Required',
    mobileInvalidError: 'Please enter a valid number',

    pocLbl: 'Person of Contact (Optional)',
    pocPlaceholder: 'ex: Ramesh Sharma',

    emailLbl: 'Email (Optional)',
    emailPlaceholder: 'ex: abcxyz123@email.com',
    emailInvalidError: 'Please enter valid email',

    gstinLbl: 'GSTIN (Optional)',
    gstinPlaceholder: 'ex: 22 AAAAA0000A 1 Z 5 ',
    gstinInvalidError: 'Please enter valid GSTIN',

    addressLbl: 'Address (Optional)',
    addressPlaceholder: 'ex: 72, Chintamani Road',

    cancelBtn: 'Cancel',
    addBtn: 'Add',
    updateBtn: 'Update',
  },
  filterLbl: {
    vendorLbl: 'Vendor',
    vendorVal: 'VENDOR',
    customerLbl: 'Customer',
    customerVal: 'CUSTOMER',
    vend_custLbl: 'Vendor_Customer',
    vend_custVal: 'VENDOR_CUSTOMER',
    allLbl: '',
  },
  partyList: {
    title: 'Party',
    vendCustTitle: 'Party',
    type: 'Type',
    addBtn: 'Add Party',
    name: 'Name',
    mobile: 'Mobile No.',
    poc: 'Person of Contact',
    email: 'Email',
    searchbar: 'Name, Mobile No.',
  },
  partyDetail: {
    title: 'Party Details',
    editBtn: 'Edit',
    deactivateBtn: 'Deactivate',
    pocLbl: 'Person of Contact',
    contactLbl: 'Contact No.',
    emailLbl: 'Email',
    gstinLbl: 'GSTIN',
    addressLbl: 'Address',
    itemsLbl: 'Items',
    transactionHistoryLbl: 'Transaction History',
    unit: 'pcs',
    loadMoreBtn: 'Load more',
    searchBar: 'product or material name, code',
    vendAndCust: 'Vendor and Customer',
    vendorLbl: 'Vendor',
    customerLbl: 'Customer',
    allLbl: 'Vendor, Customer',
  },
  employeeDetail: { title: 'Employee Details' },
  deactivate: {
    title: 'Deactivate a {{type}}',
    confirmationMsg: 'Are you sure you want to deactivate {{name}} ?',
    cancelBtn: 'Cancel',
    deactivateBtn: 'Deactivate',
  },
};

export default party;
