const reports = {
  documentTitle: 'रिपोर्ट्स | फैक्ट्री प्लस',
  common: {
    dateFilterApply: 'अप्लाई करें',
    dateFilterCancel: 'कैंसल करें',
  },
  dashboard: {
    title: 'रिपोर्ट्स ',
    businessOverviewHeading: 'बिज़नेस ओवरव्यू',
    inventoryOverviewHeading: 'इनवेंट्री ओवरव्यू',
    dateFilterPlaceholder: 'तारीख का दायरा चुनें',
    dateFilterToday: 'आज',
    dateFilterThisWeek: 'इस सप्ताह',
    dateFilterThisMonth: 'इस महीने',
    dateFilterThisQuarter: 'इस तिमाही',
    dateFilterThisYear: 'इस वर्ष',
    dateFilterDateRange: 'तारीख का दायरा',
    downloadReportBtn: 'रिपोर्ट डाउनलोड करें',
    totalSales: 'कुल सेल्स',
    totalPurchases: 'कुल खरीद',
    viewDetailedReport: 'डिटेल में रिपोर्ट देखें',
    topRefilledMaterials: 'टॉप 5  रीफिल किए हुए मटीरियल ',
    topDispatchedProducts: 'टॉप 5  भेजे गए प्रोडक्ट्स',
    inventoryOverviewNoDataTitle: 'हमें इस तारीख का कोई डेटा नहीं मिला',
    inventoryOverviewNoDataDesc: 'तारीख का दायरा बदल कर देखें',
    materialSectionHeading: 'मटीरियल की कीमत और ट्रेंड्स',
    materialName: 'मटीरियल का नाम',
    productName: 'प्रोडक्ट का नाम',
    selectMaterialPlaceholder: 'मटीरियल चुनें',
    amount: 'राशि',
    ppuChartHeading: 'प्रति यूनिट राशि की हिस्ट्री',
    refillChartHeading: 'ट्रेंड रीफिल करें',
    consumeChartHeading: 'ट्रेंड इस्तेमाल करें',
    transactions: 'ट्रांजेक्शन्स',
    ppu: 'प्रति यूनिट कीमत',
    refill: 'रीफिल करें ',
    consume: 'इस्तेमाल करें',
    noPpuDataText: 'राशि की हिस्ट्री देखने के लिए एक मटीरियल चुनें ',
    noRefillDataText: 'रीफिल का ट्रेंड देखने के लिए एक मटीरियल चुनें ',
    noConsumeDataText: 'इस्तेमाल का ट्रेंड देखने के लिए एक मटीरियल चुनें ',
  },
  transaction: {
    selectMaterialPlaceholder: 'मटीरियल चुनें',
    selectProductPlaceholder: 'प्रोडक्ट चुनें',
    selectCustomerPlaceholder: 'कस्टमर चुनें',
    selectVendorPlaceholder: 'वेंडर चुनें',
    noTransactionTrendText: 'हमें इस तारीख का कोई डेटा नहीं मिला',
    list: {
      dataNotAvailable: 'लागू नहीं',
      quantity: 'मात्रा',
      amount: 'राशि',
      date: 'तारीख',
      party: 'पार्टी',
      codeAndName: 'कोड और नाम',
    },
  },
};

export default reports;
