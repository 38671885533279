const signUp = {
  signUpForm: {
    ownerNameLbl: 'Owner Name',
    namePlaceholder: 'Ex: Ramesh Sharma',

    factoryNameLbl: 'Factory Name ',
    factoryNamePlaceholder: 'Ex: Ramesh Packers',

    addressLbl: 'Address',
    addressLblPlaceholder: 'Ex: Noida,UP',

    continueBtn: 'Continue',
    completeBtn: 'Complete Setup',

    signupSteps: {
      step1: {
        heading: 'Details',
        title: `Let's create your profile `,
        subTitle: 'Tell us about your factory business',
      },
      step2: {
        heading: 'RM Types',
        title: 'What raw materials do you use?',
        subTitle: 'Choose materials from the below list',
      },
    },
    rmTypesName: {
      plastic: 'Plastic',
      papers: 'Papers',
      metals: 'Metals',
      textiles: 'Textiles',
      electrical: 'Electrical',
      chemicals: 'Chemicals',
      electronics: 'Electronics',
      others: 'Others',
    },
  },
  requiredMessage: 'Required',
  validEmailMessage: 'Please enter valid email',
};
export default signUp;
