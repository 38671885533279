type Action = {
  type: string;
  payload: boolean;
};

const reducer = (state = false, action: Action): boolean => {
  switch (action.type) {
    case 'manageCollapse':
      return (state = action.payload);

    default:
      return state;
  }
};

export default reducer;
