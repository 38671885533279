const login = {
  maxLimitOtp: 'Max Limit Reached For This OTP Verification',
  validOtp: 'Please Enter a Valid OTP',
  validNumber: 'Please enter valid number',
  otpSuccessMsg: 'OTP Sent Successfully',
  accountDeactivate: 'User account is Deactivated',
  unableToProceed: 'Unable to proceed. Contact admin',
  otpRequired: 'OTP is required*',
  changeLanguageBtn: 'Change Language',
  mobileNumber: 'Mobile number',
  mobileNumberPlaceholder: 'Enter Mobile Number',
  copyRightTxt: '© Factory One Private Limited',
  verifyOTPLbl: 'Verify OTP',
  enterOtpLbl: 'Enter the OTP sent to you at',
  supportBtn: 'Support',
  loginTxt: 'Log in Hi-En',
  signUpTxt: 'Sign up',
  resendOTPBtn: 'Resend OTP',
  verifyBtn: 'Verify',
};

export default login;
