import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import {
  SUPPORT_SDK_BASE_URL,
  SUPPORT_SDK_SETTINGS,
  SUPPORT_WEBSITE_TOKEN,
} from './constants';

const useSupportWidget = () => {
  const isInit = useRef(false);
  const [isReady, setIsReady] = useState(false);

  const init = () => {
    if (!isInit.current) {
      window.chatwootSettings = SUPPORT_SDK_SETTINGS;

      window.addEventListener('chatwoot:ready', function () {
        setIsReady(true);
      });

      (function (d, t) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const g: any = d.createElement(t),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          s: any = d.getElementsByTagName(t)[0];
        g.src = `${SUPPORT_SDK_BASE_URL}/packs/js/sdk.js`;
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g, s);
        g.onload = function () {
          window.chatwootSDK.run({
            websiteToken: SUPPORT_WEBSITE_TOKEN,
            baseUrl: SUPPORT_SDK_BASE_URL,
          });
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        g.onerror = function (oError: any) {
          Sentry.captureMessage(
            `The script ${oError?.target?.src} didn't load correctly.`
          );
        };
      })(document, 'script');

      isInit.current = true;
    }
  };

  useEffect(init, []);

  return isReady;
};

export default useSupportWidget;
