const ledger = {
  ledgerList: {
    codeAndName: 'Code & Name',
    action: 'Action',
    quantity: 'Quantity',
    price: 'Amount',
    date: 'Date',
    party: 'Party',
    productionId: 'Production ID',
    employee: ' Employee',
    notes: 'Notes',
  },
  ledgerSearchPlaceholderSuffix: 'product, Order ID...',
  noDataMessage1: 'You don’t have any data in ledger',
  noDataMessage2: 'Looks like you haven’t performed any actions in inventory',
  noSearchData: 'You don’t have any data related to "{{searchText}}" in ledger',

  heading: 'Ledger',
  excelBtn: 'Excel',
  noDataMsg: 'No results for the selected filter(s) in ledger',
  noActionMsg: 'Looks like you haven’t performed any actions in inventory',
  searchNoMatch: 'No results for the search term "{{searchText}}" in ledger',
  filtersInitial: {
    dateLbl: 'Date',
    lifeTimeName: 'Life Time',
    lifeTimeLbl: 'Life Time',
    todayName: 'Today',
    todayLbl: 'Today',
    yesterdayLbl: 'Yesterday',
    yesterdayName: 'Yesterday',
    weekName: 'This week',
    weekLbl: 'This week',
    yearName: 'This year',
    yearLbl: 'This year',
    dateRangeName: 'Date Range',
    dateRangeLbl: 'Date Range',
    typeLbl: 'Type',
    productName: 'Product',
    productLbl: 'Product',
    materialName: 'Material',
    materialLbl: 'Material',
    actionLbl: 'Action',
    produceName: 'Produce',
    restockLbl: 'Re-Stock',
    dispatchName: 'Dispatch',
    dispatchLbl: 'Dispatch',
    consumeName: 'Consume',
    consumeLbl: 'Consume',
    refillName: 'Refill',
    refillLbl: 'Refill',
    employeeLbl: 'Employee',
  },
  tableHeader: {
    dateTime: 'Date & Time',
    type: 'Type',
    codeName: 'Code & Name',
    action: 'Action',
    employee: 'Employee',
    notes: 'Notes',
    prodId: 'Production ID',
    qty: 'Quantity',
  },
  filter: {
    filterBtn: 'Filter',
    title: 'Filter Ledger',
    clearAllBtn: 'Clear All',
    to: 'to',
    applyFilterBtn: 'Apply Filter',
    customerAndVendor: 'Customer, Vendor',
    vendor: 'Vendor',
    customer: 'Customer',
  },
};

export default ledger;
