const orders = {
  title: 'Orders',
  createOrderBtn: 'Create Order',
  activeTab: 'Active',
  upcomingTab: 'Upcoming',
  pastTab: 'Past',
  orderSearchPlaceholderSuffix: 'Order ID, Product Name...',
  filtersDropdownTitle: 'filters',
  createdOn: 'created on',
  orderQty: '{{qty}} pcs',
  lastTimeUpdated: 'Updated {{time}} ago',
  searchFinishDatePlaceholder: 'Search Finish Date',
  finishedOn: 'finished on',
  loadMoreBtn: 'Load more',
  noResultFound:
    "Oops! We couldn't find any details related to {{searchText}}`",
  noOrders: "Looks like you don't have any orders yet",
  dataNotAvailable: 'NA',
  filterLbl: {
    scheduledLbl: 'SCHEDULED',
    partiallyScheduledLbl: 'PARTIALLY SCHEDULED',
    unscheduledLbl: 'UNSCHEDULED',
    finishedLbl: 'FINISHED',
    cancelledLbl: 'CANCELLED',
  },
  subStatusLbl: {
    onTimeLbl: 'ON TIME',
    delayedLbl: 'DELAYED',
    pausedLbl: 'PAUSED',
  },
  statusToastMessage: {
    successMessage: 'order updated',
    errorMessage: "Order status couldn't get updated",
  },
  orderDetail: {
    title: 'Order ID: {{orderId}}',
    orderSettingsBtn: 'Order Settings',
    finishOrderBtn: 'Finish Order',
    startOrderBtn: 'Start Order',
    editOrderDrawerTitle: 'Edit Order',
    status: 'Status',
    createdOn: 'Created On',
    rawMaterials: 'Raw Materials',
    rawMaterialsClickLeft: 'Raw Material',
    rawMaterialsClickRight: 'Quantity',
    lastUpdated: 'Last Updated',
    lastTimeUpdated: '{{time}} ago',
    productMoved: 'Product Moved',
    productQty: '{{qty}} pcs',
    orderJobsTitle: 'Jobs in this Order',
    bulkUpdateBtn: 'Bulk Update',
    recordPartProductionBtn: 'Record Part Production',
    recordPartProductionDesc:
      'Record product manufactured and material consumed of this order',
    productionHistoryBtn: 'Production History',
    productionHistoryDesc:
      'Check partial productions created so far in the order',
    editOrderBtn: 'Edit Order',
    editOrderDesc: 'Edit target quantity and materials in the order',
    cancelOrderBtn: 'Cancel Order',
    cancelOrderDesc: 'Stop production of the order',
    deleteOrderBtn: 'Delete Order',
    deleteOrderDesc: 'Remove this order',
  },
  jobDetail: {
    onLbl: 'On',
    addMachine: 'Add Machine',
    noMachine: 'No Machine',
    qty: '{{qty}} pcs',
    qtyUnit: 'pcs',
    okUnits: 'OK Units',
    rejected: 'Rejected',
    updateHistoryBtn: 'Update History',
    updateQuantityBtn: 'Update Quantity',
    updatedLastTime: 'Updated {{time}} ago',
    createdLastTime: 'Created {{time}} ago',
    supervisors: 'Supervisors',
    addBtn: 'Add',
    closeBtn: 'Close',
    removeBtn: 'Remove',
    searchEmployeePlaceholder: 'Enter employee name',
    noSupervisorsAssigned: 'No supervisors assigned yet',
    machineLbl: 'Machine',
    machineSearchPlaceholder: 'Enter machine name',
    selectMachine: 'Select machine',
    unknown: 'Unknown',
    dateAndTimeLbl: 'Date and Time',
    dateAndTimePlaceholder: 'dd/mm/yyyy',
    setStartingDateAndTime: 'Set Starting Date and Time',
    noteLbl: 'Note',
    notePlaceholder: 'Enter a job description',
    enterJobDescription: 'Enter Job Description',
    cancelBtn: 'Cancel',
    saveBtn: 'Save',
    editBtn: 'Edit',
  },
  updateQuantityForm: {
    title: 'Update Order ID: #{{orderId}}',
    jobs: 'Jobs',
    okItems: 'OK Items',
    rejectedItems: 'Rejected Items',
    qtyPlaceholder: 'Qty',
    qtyUnit: 'pcs',
    cancelBtn: 'Cancel',
    updateBtn: 'Update',
    okItemsRequiredError: 'Ok Items is required',
    okItemsNegativeError: 'Ok Items cannot be negative',
    rejectedItemsNegativeError: "Rejected items can't be negative",
  },
  jobUpdatedDetailForm: {
    title: 'Update History',
    updatedJob: '{{jobName}}: {{qty}} pcs',
    dateAndTime: 'Date & Time',
    updatedBy: 'Updated by',
    okUnits: 'OK Units',
    rejectedUnits: 'Rejected Units',
    loadMoreBtn: 'Load more',
    qtyUnit: 'pcs',
    qty: '{{qty}} pcs',
    dataNotAvailable: 'NA',
  },
  partProductionForm: {
    title: 'Part Production',
    summarySinceStart: 'Summary since start',
    totalProductManufactured: 'Total Product Manufactured',
    totalMaterialsConsumed: 'Total Materials Consumed',
    qty: '{{qty}} pcs',
    qtyUnit: 'pcs',
    newProduction: 'New Production',
    moreMaterialConsumed: 'More material consumed at this point than estimated',
    noMaterialsAdded: 'No materials added',
    accordionTitleProductManufactured: 'Product Manufactured',
    accordionTitleMaterialsConsumed: 'Materials Consumed',
    accordionTitleProductionSummary: 'Production Summary',
    enterQtyOf: ' Enter quantity of',
    manufacturedSince: 'manufactured since ',
    lastProduction: 'last production',
    start: 'start',
    enterQtyOfMaterials: 'Enter quantity of materials consumed since ',
    productPlaceholder: '0',
    materialPlaceholder: 'Qty',
    productManufactured: 'Product Manufactured',
    materialsConsumed: 'Materials Consumed',
    name: 'Name',
    before: 'Before',
    manufactured: 'Manufactured',
    consumed: 'Consumed',
    newTotal: 'New Total',
    nextBtn: 'Next',
    backBtn: 'Back',
    recordBtn: 'Record',
    markForDispatchBtn: 'Mark for Dispatch',
  },
  cancelOrderForm: {
    title: 'Cancel Order',
    summarySinceStart: 'Summary since start',
    totalProductManufactured: 'Total Product Manufactured',
    totalMaterialsConsumed: 'Total Materials Consumed',
    qtyUnit: 'pcs',
    qty: '{{qty}} pcs',
    moreMaterialConsumed: 'More material consumed at this point than estimated',
    noMaterialsAdded: 'No materials added',
    finalProduction: 'Final Production',
    accordionTitleProductManufactured: 'Product Manufactured',
    accordionTitleMaterialsConsumed: 'Materials Consumed',
    accordionTitleProductionSummary: 'Production Summary',
    enterQtyOf: 'Enter quantity of',
    manufacturedSince: 'manufactured since ',
    lastProduction: 'last production',
    start: 'start',
    productQtyPlaceholder: '0',
    enterQtyOfMaterials: 'Enter quantity of materials consumed since ',
    materialPlaceholder: 'Qty',
    productManufactured: 'Product Manufactured',
    materialsConsumed: 'Materials Consumed',
    name: 'Name',
    before: 'Before',
    manufactured: 'Manufactured',
    consumed: 'Consumed',
    newTotal: 'New Total',
    nextBtn: 'Next',
    backBtn: 'Back',
    recordAndCancelBtn: 'Record & Cancel',
    markForDispatchBtn: 'Mark for Dispatch',
  },
  finishOrderForm: {
    title: 'Finish Order',
    summarySinceStart: 'Summary since start',
    totalProductManufactured: 'Total Product Manufactured',
    totalMaterialsConsumed: 'Total Materials Consumed',
    qty: '{{qty}} pcs',
    moreMaterialConsumed: 'More material consumed at this point than estimated',
    noMaterialsAdded: 'No materials added',
    finalProduction: 'Final Production',
    accordionTitleProductManufactured: 'Product Manufactured',
    accordionTitleMaterialsConsumed: 'Materials Consumed',
    accordionTitleProductionSummary: 'Production Summary',
    enterQtyOf: 'Enter quantity of',
    manufacturedSince: 'manufactured since ',
    lastProduction: 'last production',
    start: 'start',
    productPlaceholder: '0',
    qtyUnit: 'pcs',
    enterQtyOfMaterials: 'Enter quantity of materials consumed since ',
    materialPlaceholder: 'Qty',
    productManufactured: 'Product Manufactured',
    materialsConsumed: 'Materials Consumed',
    name: 'Name',
    before: 'Before',
    manufactured: 'Manufactured',
    consumed: 'Consumed',
    newTotal: 'New Total',
    nextBtn: 'Next',
    backBtn: 'Back',
    markForDispatchBtn: 'Mark for Dispatch',
    recordAndFinishBtn: 'Record & Finish',
  },
  productionHistory: {
    title: 'Production Logs ({{name}})',
    recordPartProductionBtn: 'Record Part Production',
    productionNumber: 'Production {{number}}',
    productManufactured: 'Product Manufactured',
    materialsConsumed: 'Materials Consumed',
    name: 'Name',
    before: 'Before',
    manufactured: 'Manufactured',
    consumed: 'Consumed',
    newTotal: 'New Total',
    qty: '{{qty}} pcs',
    qtyUnit: 'pcs',
    noProductionActivity: "This order doesn't have any production activity",
  },
  createOrderDrawer: {
    stepSelectProduct: 'Select Product',
    stepArrangeJobs: 'Arrange Jobs',
    stepAddRawMaterials: 'Add Raw Materials',
    title: 'Create Order',
    orderIdLbl: 'Order ID',
    orderIdPlaceholder: 'Product Code',
    suggestion: 'suggestion',
    productLbl: 'Select Product',
    productPlaceholder: 'Search Product...',
    qtyLbl: 'Enter Quantity',
    qtyPlaceholder: 'Qty*',
    qtyUnit: 'pcs',
    orderId: 'Order ID:#{{id}}',
    qty: '{{qty}} pcs',
    jobs: 'Jobs',
    addBtn: 'Add',
    closeBtn: 'Close',
    processSearchPlaceholder: 'Enter process name',
    noJobsAdded: 'No Jobs added yet',
    rawMaterials: 'Raw Materials',
    rawMaterialsTooltipLine1: 'Material quantities are displayed using the',
    rawMaterialsTooltipLine2: 'material ratio set for this product',
    materialSearchPlaceholder: 'Enter material name',
    avlQty: 'Avl Qty',
    materialPlaceholder: 'Qty',
    noMaterialAdded: 'No material added yet',
    saveMaterialRatio: 'Save material ratio for future use',
    prevBtn: 'Prev',
    nextBtn: 'Next',
    createBtn: 'Create',
    orderCreatedSuccessfully: 'Order Created Successfully',
    doneForNowBtn: 'Done for now',
    assignNowBtn: 'Assign Now',
    requiredError: 'Required',
  },
  editOrderDrawer: {
    targetQtyLbl: 'Target Quantity',
    targetQtyPlaceholder: 'Qty',
    qtyUnit: 'pcs',
    rawMaterials: 'Raw Materials',
    addBtn: 'Add',
    closeBtn: 'Close',
    materialSearchPlaceholder: 'Enter material name',
    avlQty: 'Avl Qty',
    materialPlaceholder: 'Qty',
    noMaterialAdded: 'No material added yet',
    jobs: 'Jobs',
    processSearchPlaceholder: 'Enter process name',
    noProcessAdded: 'No process added yet',
    cancelOrderBtn: 'Cancel Order',
    deleteOrderBtn: 'Delete Order',
    saveBtn: 'Save',
  },
  deleteOrderModal: {
    title: 'Are you sure ?',
    bodyText: 'You are about to delete Order #{{orderId}}',
    cancelBtn: 'Cancel',
    deleteBtn: 'Delete',
  },
  dispatchPartialProductForm: {
    title: 'Dispatch',
    qtyLbl: 'Quantity*',
    qtyPlaceholder: 'Qty',
    qtyUnit: 'pcs',
    notesLbl: 'Notes (Optional)',
    cancelBtn: 'Cancel',
    dispatchBtn: 'Dispatch',
    requiredError: 'Required',
    qtyZeroError: 'Product Qty can’t be 0',
    qtyNegativeError: 'Product Qty can’t be negative',
    qtyDecimalError: 'Product Qty can’t be decimal',
  },
  finishPartProductionModal: {
    title: 'Part Production Recorded !',
    manufactured: 'Manufactured',
    dispatch: 'Dispatch',
    qty: '{{qty}} pcs',
    orderTargetExceededPart1: 'Order target exceeded - (',
    orderTargetExceededPart2: ') {{unit}} Do you also want to “',
    orderTargetExceededPart3: 'Finish',
    orderTargetExceededPart4: '” the order',
    skipBtn: 'Skip',
    finishOrderBtn: 'Finish Order',
  },
  successPartialDispatchModal: {
    title: 'Part Production Recorded !',
    manufactured: 'Manufactured',
    dispatch: 'Dispatch',
    qty: '{{qty}} pcs',
  },
  successFinishOrderModal: {
    title: 'Order Finished !',
    qty: '{{qty}} pcs',
    totalManufactured: 'Total Manufactured',
    totalDispatched: 'Total Dispatched',
  },
  successCancelOrderModal: {
    title: 'Order Cancelled !',
    qty: '{{qty}} pcs',
    totalManufactured: 'Total Manufactured',
    totalDispatched: 'Total Dispatched',
  },
  listing: {
    title: 'sort by',
    orderByDateOfCreation: 'Date of Creation',
    orderByProductName: 'Product Name',
    orderByCompletion: 'Completion',
    orderByTargetQuantity: 'Target Quantity',
    orderByFinishedDate: 'Finished Date',
  },
};
export default orders;
