import { Redirect, Route, RouteProps } from 'react-router-dom';
import { checkSignUpInProgress } from './services/signUpService';
import storageService, { STORAGE_KEY } from './services/storageService';
const RouteAuthenticatedWithSignuptoken: React.ComponentState = ({
  component: Component,
  path,
}: RouteProps) => {
  const signUpToken = storageService.get(STORAGE_KEY.SIGN_UP_TOKEN);

  const isSignUpInProgress = checkSignUpInProgress();
  if (signUpToken || isSignUpInProgress) {
    return <Route component={Component} path={path} />;
  } else {
    return <Redirect to="/login" />;
  }
};

export default RouteAuthenticatedWithSignuptoken;
