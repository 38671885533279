const dashboard = {
  duplicateError: 'डुप्लीकेट रील',
  atLeastOneErrorMessage: 'कम से कम एक मटीरियल या रील के लिए मात्रा डालें',
  greeting: '{{name}} में आपका स्वागत है',
  defaultGreeting: 'आपकी फैक्ट्री में आपका स्वागत है',
  welcomeMsg: 'पता लगाएं आपकी फैक्ट्री में आज क्या हो रहा है',
  inr: 'आईएनआर',
  materialSearchPlaceholderSuffix: 'मटीरियल का नाम...',
  recentProductions: 'हाल ही के प्रोडक्शन्स',
  banner: {
    newFeatureLbl: 'नया फीचर',
    rawMaterial: {
      buyPaper: 'कागज कच्चे माल खरीदें',
      buyPlastic: 'प्लास्टिक कच्चा माल खरीदें',
      purchase: 'खरीदने के लिए यहां क्लिक करें',
      purchaseBtn: 'खरीदना',
      material: 'मटीरियल्स',
    },
    rmPurchaseConfirmationTitle: 'खरीद अनुरोध सफलतापूर्वक सबमिट किया गया',
    rmPurchaseConfirmationBtnLbl: 'डैशबोर्ड पर जाएं',
  },
  dropdownMenu: {
    employees: 'एमप्लॉईज़',
    products: 'प्रोडक्ट्स',
    materials: 'मटीरियल्स',
  },
  stats: {
    activeOrders: 'ऐक्टिव ऑर्डर्स',
    dispatches: 'भेजे गए सामानों की संख्या',
    finishedOrders: 'ऑर्डर्स समाप्त हुए',
  },
  addEmployeeForm: {
    title: 'एमप्लॉई जोड़ें',

    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    nameRequiredError: 'आवश्यक',
    nameInvalidError: 'आवश्यक',

    emailLbl: 'ईमेल',
    emailPlaceholder: 'ईमेल',
    emailInvalidError: 'कृपया सही ईमेल डालें',

    mobileLbl: 'मोबाइल',
    mobileCode: '+91',
    mobilePlaceholder: 'मोबाइल नंबर',
    mobileRequiredError: 'आवश्यक',
    mobileInvalidError: 'कृपया सही नंबर डालें',

    roleLbl: 'रोल',
    rolePlaceholder: 'रोल सर्च करें',
    roleRequiredError: 'आवश्यक',

    adminLbl: 'एडमिन',
    adminVal: 'एडमिन',

    prodSupervisorLbl: 'प्रोडक्शन सुपरवाइज़र',
    prodSupervisorVal: 'प्रोडक्शन सुपरवाइज़र',

    inventoryAndProdLbl: 'इन्वेंट्री और प्रोडक्शन सुपरवाइज़र',
    inventoryAndProdVal: 'इन्वेंट्री और प्रोडक्शन सुपरवाइज़र',

    inventoryAndProdViewOnlyLbl:
      'इन्वेंट्री और प्रोडक्शन सुपरवाइज़र (सिर्फ देखें)',
    inventoryAndProdViewOnlyVal:
      'इन्वेंट्री और प्रोडक्शन सुपरवाइज़र (सिर्फ देखें)',

    processesLbl: 'प्रोसेसेस',
    processesPlaceholder: 'प्रोसेसेस सर्च करें',

    addBtn: 'जोड़ें',
    cancelBtn: 'कैंसल करें',
  },
  addMachineForm: {
    title: 'मशीन जोड़ें',

    nameLbl: 'नाम',
    namePlaceholder: 'मशीन का नाम',
    nameRequiredError: 'आवश्यक',
    nameInvalidError: 'आवश्यक',

    specsLbl: 'खास निर्देश',
    specsPlaceholder: 'खास निर्देश',

    processesLbl: 'प्रोसेसेस',
    processesPlaceholder: 'प्रोसेसेस सर्च करें',

    cancelBtn: 'कैंसल करें',
    addBtn: 'जोड़ें',
  },

  order: {
    title: 'ऑर्डर',
    createBtn: 'बनाएं',
  },
  production: {
    title: 'प्रोडक्शन',
    recordBtn: 'रिकॉर्ड',
  },
  materials: {
    title: 'मटीरियल्स',
    refillBtn: 'रीफिल',
    consumeBtn: 'इस्तेमाल',
  },
  product: {
    title: 'प्रोडक्ट',
    restockBtn: 'री-स्टॉक',
    dispatchBtn: 'भेजें',
  },
  chart: {
    salesBtn: 'कुल सेल्स',
    purchaseBtn: 'कुल खरीद',
    subTitle: '(पिछले 7 दिन)',
  },
  lowInventory: {
    title: 'कम इन्वेंट्री',
    fullStockMsg1: 'ऐसा लगता है कि आपके आइटम्स',
    fullStockMsg2: 'पूरी तरह स्टॉक किए गए हैं',
  },
  refillMaterialForm: {
    note: 'नोट',
    addReel: 'रील जोड़ें',
    name: 'नाम',
    reels: 'रील्स',
    reel: 'रील',
    avlQtyLbl: 'उपलब्ध मात्रा',
    reelSetting: 'इस मटीरियल में रील सेटिंग्स है',
    avlQty: 'उपलब्ध मात्रा',
    title: 'मटीरियल्स रीफिल करें',
    addMaterialLbl: 'मटीरियल जोड़ें',
    addMaterialPlaceholder: 'मटीरियल सर्च करें',
    save: 'सेव करें',
    vendorLbl: 'वेंडर चुनें (वैकल्पिक)',
    vendorPlaceholder: 'वेंडर सर्च करें',
    reelsNameLbl: 'रील का नाम',
    tableHeaderMaterialCodeName: 'मटीरियल कोड और नाम',
    tableHeaderNotes: 'नोट्स',
    tableHeaderAvailableQty: 'उपलब्ध मात्रा',
    tableHeaderQty: 'मात्रा',
    tableHeaderPricePerUnit: 'प्रति यूनिट कीमत',
    tableHeaderAmount: 'राशि',

    notesInputPlaceholder: 'नोट',
    qtyInputPlaceholder: '0',
    requiredError: 'आवश्यक',
    unit: '{{unit}}',
    searchAndAdd: 'मटीरियल्स सर्च करें और जोड़ें',
    savePricePerUnitCheckbox: 'सभी आइटम्स के लिए प्रति यूनिट कीमत सेव करें',
    cancelBtn: 'कैंसल करें',
    refillBtn: 'मटीरियल्स रीफिल करें',
  },
  consumeMaterialForm: {
    avlQty: 'उपलब्ध मात्रा:',
    savePricePerUnitCheckbox: 'सभी आइटम्स के लिए प्रति यूनिट कीमत सेव करें',
    title: 'इस्तेमाल के लिए थोक में मटीरियल्स',
    addMaterialLbl: 'मटीरियल जोड़ें',
    addMaterialPlaceholder: 'मटीरियल सर्च करें',
    note: 'नोट',
    partyLbl: 'पार्टी चुनें (वैकल्पिक)',
    partyPlaceholder: 'पार्टी सर्च करें',
    reels: 'रील्स',
    tableHeaderMaterialCodeName: 'मटीरियल कोड और नाम',
    tableHeaderNotes: 'नोट्स',
    tableHeaderAvailableQty: 'उपलब्ध मात्रा',
    tableHeaderQty: 'मात्रा',

    notesInputPlaceholder: 'नोट',
    qtyInputPlaceholder: '0',
    unit: '{{unit}}',
    searchAndAdd: 'मटीरियल्स सर्च करें और जोड़ें',
    consumeBtn: 'मटीरियल्स इस्तेमाल करें',
    cancelBtn: 'कैंसल करें',
  },
  restockProductForm: {
    title: 'प्रोडक्ट्स रीस्टॉक करें',

    addProductLbl: 'प्रोडक्ट जोड़ें',
    addProductPlaceholder: 'प्रोडक्ट सर्च करें',

    partyLbl: 'पार्टी चुनें (वैकल्पिक)',
    partyPlaceholder: 'पार्टी सर्च करें',

    tableHeaderProductCodeName: 'प्रोडक्ट कोड और नाम',
    tableHeaderNotes: 'नोट्स',
    tableHeaderAvailableQty: 'उपलब्ध मात्रा',
    tableHeaderQty: 'मात्रा',

    notesInputPlaceholder: 'नोट',
    qtyInputPlaceholder: '0',
    unit: 'पीस',
    searchAndAdd: 'मटीरियल्स सर्च करें और जोड़ें',
    restockBtn: 'रीस्टॉक',
  },
  dispatchProductForm: {
    title: 'प्रोडक्टस भेजें',
    addProductLbl: 'प्रोडक्ट जोड़ें',
    addProductPlaceholder: 'प्रोडक्ट सर्च करें',

    customerLbl: 'कस्टमर चुनें (वैकल्पिक)',
    customerPlaceholder: 'कस्टमर चुनें',

    tableHeaderProductCodeName: 'प्रोडक्ट कोड और नाम',
    tableHeaderNotes: 'नोट्स',
    tableHeaderAvailableQty: 'उपलब्ध मात्रा',
    tableHeaderQty: 'मात्रा',
    tableHeaderPricePerUnit: 'प्रति यूनिट कीमत',
    tableHeaderAmount: 'राशि',

    notesInputPlaceholder: 'नोट',
    qtyInputPlaceholder: '0',
    unit: 'पीस',
    searchAndAdd: 'मटीरियल्स सर्च करें और जोड़ें',
    savePricePerUnitCheckbox: 'सभी आइटम्स के लिए प्रति यूनिट कीमत सेव करें',
    cancelBtn: 'कैंसल करें',
    dispatchBtn: 'भेजें',
  },
  notificationFeed: {
    noNotif: 'अभी के लिए कोई भी नोटिफिकेशन नहीं है',
  },
};

export default dashboard;
