const party = {
  partyForm: {
    nameLbl: 'नाम',
    namePlaceholder: 'उदाहरणः रमेश इंडस्ट्रीज़',
    nameRequiredError: 'आवश्यक',
    nameInvalidError: 'आवश्यक',

    typeLbl: 'पार्टी का प्रकार',
    typePlaceholder: 'कस्टमर/वेंडर',

    mobileLbl: 'मोबाइल नंबर',
    mobileCode: '+91',
    mobilePlaceholder: '9999999999',
    mobileRequiredError: 'आवश्यक',
    mobileInvalidError: 'कृपया सही नंबर डालें',

    pocLbl: 'संपर्क का व्यक्ति (वैकल्पिक)',
    pocPlaceholder: 'उदाहरणः रमेश शर्मा',

    emailLbl: 'ईमेल (वैकल्पिक)',
    emailPlaceholder: 'उदाहरणः abcxyz123@email.com',
    emailInvalidError: 'कृपया सही ईमेल डालें',

    gstinLbl: 'जीएसटीआईएन (वैकल्पिक)',
    gstinPlaceholder: 'उदाहरण:  22 AAAAA0000A 1 Z 5 ',
    gstinInvalidError: 'कृपया सही जीएसटीआईएन डालें',

    addressLbl: 'पता (वैकल्पिक)',
    addressPlaceholder: 'उदाहरणः 72, चिंतामणि रोड',

    cancelBtn: 'कैंसल करें',
    addBtn: 'जोड़ें',
    updateBtn: 'अपडेट करें',
  },
  filterLbl: {
    vendorLbl: 'वेंडर',
    vendorVal: 'वेंडर',
    customerLbl: 'कस्टमर',
    customerVal: 'कस्टमर',
    vend_custLbl: 'वेंडर_कस्टमर',
    vend_custVal: 'वेंडर_कस्टमर',
    allLbl: '',
  },
  partyList: {
    title: 'पार्टी',
    vendCustTitle: 'पार्टी',
    type: 'प्रकार',
    addBtn: 'पार्टी जोड़ें',
    name: 'नाम',
    mobile: 'मोबाइल नंबर',
    poc: 'संपर्क का व्यक्ति',
    email: 'ईमेल',
    searchbar: 'नाम, मोबाइल नंबर',
  },
  partyDetail: {
    title: 'पार्टी की डिटेल्स',
    editBtn: 'एडिट करें',
    deactivateBtn: 'डीएक्टिवेट करें',
    pocLbl: 'संपर्क का व्यक्ति',
    contactLbl: 'कॉन्टैक्ट नंबर',
    emailLbl: 'ईमेल',
    gstinLbl: 'जीएसटीआईएन',
    addressLbl: 'पता',
    itemsLbl: 'आइटम्स',
    transactionHistoryLbl: 'ट्रांजेक्शन की हिस्ट्री',
    unit: 'पीस',
    loadMoreBtn: 'और ज्यादा लोड करें',
    searchBar: 'प्रोडक्ट और मटीरियल का नाम, कोड',
    vendAndCust: 'वेंडर और कस्टमर',
    vendorLbl: 'वेंडर',
    customerLbl: 'कस्टमर',
    allLbl: 'वेंडर, कस्टमर',
  },
  employeeDetail: { title: 'एमप्लॉई की डिटेल्स' },
  deactivate: {
    title: 'डीएक्टिवेट करें {{type}}',
    confirmationMsg:
      'क्या आपको यकीन है कि आप {{name}} डीएक्टिवेट करना चाहते हैं?',
    cancelBtn: 'कैंसल करें',
    deactivateBtn: 'डीएक्टिवेट करें',
  },
};

export default party;
