const processes = {
  title: 'प्रोसेसेस',
  addProcessBtn: 'प्रोसेस जोड़ें',
  addProcessBulkBtn: 'थोक में अपलोड',
  processSearchPlaceholderSuffix: 'प्रोसेस',
  addEditProcessForm: {
    titleAdd: 'प्रोसेस जोड़ें',
    titleEdit: 'प्रोसेस एडिट करें',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    cancelProcessBtn: 'कैंसल करें',
    createProcessBtn: 'बनाएं',
    updateProcessBtn: 'अपडेट करें',
    nameRequiredError: 'आवश्यक',
    processCommaError: 'कॉमा(,) की अनुमति नहीं है',
  },
  addProcessBulkForm: {
    title: 'थोक में प्रोसेसेस जोड़ें',
    allowedExtensionsText:
      'सिर्फ .xls .xlxs .csv एक्सटेंशन वाली फाइलों की अनुमति है',
    downloadTemplateBtn: 'टेम्प्लेट डाउनलोड करें',
    cancelBulkProcessBtn: 'कैंसल करें',
    uploadBulkProcessBtn: 'अपलोड करें',
    dragAndDropText: 'ड्रैग और ड्रॉप करें',
    uploadFromComputerText: 'कम्प्यूटर से अपलोड करें ',
    invalidTemplateError:
      'इस फाइल को अपलोड नहीं कर सकते। टेम्प्लेट फाइल को चेक करें और सभी आवश्यक फील्ड भरें।',
    partialFileReviewText:
      '{{errorLength}} में से {{totalLength}} आइटम्स में गलतियां हैं। अपलोड पूरा करने के लिए गलतियां हटाएं!',
    completeFileReviewText: 'कृपया इसे रीव्यू करें',
    reviewedNameLbl: 'नाम',
    reviewedNamePlaceholder: 'नाम',
    reviewedNameRequiredError: 'आवश्यक',
    reviewedNameCommaError: 'कृपया कॉमा(,) हटाएं',
    uploadFileAgain: 'दोबारा अपलोड करें',
  },
  processDetail: {
    editProcessBtn: 'एडिट करें',
    deleteProcessBtn: 'डिलीट करें',
    deleteProcessModal: {
      title: 'प्रोसेस डिलीट करें',
      bodyText: 'क्या आपको यकीन है कि आप {{processName}} डिलीट करना चाहते हैं?',
      cancelBtn: 'कैंसल करें',
      deleteBtn: 'डिलीट करें',
    },
    employeesHeading: 'इस प्रोसेस के लिए डिफॉल्ट एमप्लॉई',
    employeeSearchPlaceholderSuffix: 'एमप्लॉई',
    assignEmployeeBtn: 'एमप्लॉई नियुक्त करें',
    removeEmployeeTooltip: 'एमप्लॉई हटाएं',
    assignEmployeeForm: {
      title: 'एमप्लॉई नियुक्त करें',
      selectEmployeeLbl: 'एमप्लॉईज़ चुनें',
      searchEmployeePlaceholder: 'एमप्लॉई सर्च करें',
      cancelBtn: 'कैंसल करें',
      assignBtn: 'नियुक्त करें',
    },
    removeEmployeeModal: {
      title: 'क्या आपको यकीन है?',
      bodyText: 'आप “{{employeeName}}” को {{processName}} से हटाने वाले हैं',
      cancelBtn: 'कैंसल करें',
      removeBtn: 'हटाएं',
    },
  },
};

export default processes;
