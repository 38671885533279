const production = {
  title: 'प्रोडक्शन',
  produceBtn: 'उत्पाद',
  productCodeSearchPlaceholderSuffix: 'प्रोडक्ट कोड...',
  productQuantity: '{{quantity}} पीस',
  timeSinceRecording: '{{time}} पहले रिकॉर्ड किया गया',
  loadMoreBtn: 'और ज्यादा लोड करें',
  noSearchResultFound: 'ओह! हमें {{searchText}} से संबंधित कोई डिटेल नहीं मिला',
  noOrdersYet: 'ऐसा लगता है कि आपके पास अभी तक कोई ऑर्डर नहीं है',
  party: 'पार्टी',
  createProductionForm: {
    numberError: 'नंबर डालें',
    openingStockPlaceholder: '0',
    reelsNamePlaceholder: 'रील्स चुनें',
    stepProduct: 'पार्टी और प्रोडक्ट',
    stepMaterials: 'मटीरियल्स',
    title: 'प्रोडक्शन',
    codeLbl: 'कोड',
    codePlaceholder: 'प्रोडक्ट कोड',
    codeSuggestion: 'सुझाव',
    productLbl: 'प्रोडक्ट चुनें',
    productPlaceholder: 'प्रोडक्ट का नाम डालें',
    qtyLbl: 'मात्रा डालें',
    qtyPlaceholder: 'मात्रा*',
    qtyUnit: 'पीस',
    noteLbl: 'नोट',
    addReelBtn: 'एक और रील चुनें',
    productCode: 'कोड',
    productQty: '{{quantity}}',
    rawMaterials: 'रॉ मटीरियल्स',
    addBtn: 'जोड़ें',
    closeInputBtn: 'बंद करें',
    materialPlaceholder: 'मटीरियल का नाम डालें',
    avlQty: 'उपलब्ध मात्रा',
    materialQtyPlaceholder: 'मात्रा',
    noMaterialAdded: 'अभी तक कोई भी मटीरियल जोड़ा नहीं गया है',
    saveMaterialRatio: 'आगे इस्तेमाल करने के लिए मटीरियल अनुपात को सेव करें',
    prevBtn: 'पिछला',
    produceBtn: 'उत्पाद',
    nextBtn: 'अगला',
    producedSuccessfully: 'प्रोडक्शन को अच्छे तरीके से रिकॉर्ड किया गया',
    closeBtn: 'बंद करें',
    summaryBtn: 'सार',
    requiredError: 'आवश्यक',
    partyLbl: 'कस्टमर चुनें',
    partyPlaceholder: 'कस्टमर सर्च करें',
  },
  updateProductionForm: {
    qtyLbl: 'मात्रा डालें',
    qtyPlaceholder: 'मात्रा*',
    noteLbl: 'नोट',
    materialQtyPlaceholder: 'मात्रा',
    noMaterialAdded: 'अभी तक कोई भी मटीरियल जोड़ा नहीं गया है',
    submitBtn: 'सबमिट करें',
    partyLbl: 'कस्टमर चुनें',
    partyPlaceholder: 'कस्टमर सर्च करें',
  },
  productionDetail: {
    title: 'कोडः {{codeNumber}}',
    dataNotAvailable: 'लागू नहीं',
    recodedOn: 'पर रिकॉर्ड किया गया',
    recodedBy: 'द्वारा रिकॉर्ड किया गया',
    productManufactured: 'प्रोडक्ट बनाया गया',
    materialsConsumed: 'इस्तेमाल किए गए मटीरियल्स',
    name: 'नाम',
    oldBalance: 'पुराना बैलेंस',
    manufactured: 'बनाए गए',
    consumed: 'इस्तेमाल किए गए',
    newBalance: 'नया बैलेंस',
    productQty: '{{quantity}} पीस',
    noMaterialsAdded: 'कोई भी मटीरियल जोड़ा नहीं गया',
    party: 'पार्टी',
    edited: '(एडिट किया गया)',
  },
  productionHistory: {
    editHistory: 'हिस्ट्री एडिट करें',
    edit: 'एडिट करें  {{index}}',
    editedOn: 'पर एडिट किया गया',
    party: 'पार्टी',
    recordedBy: 'द्वारा रिकॉर्ड किया गया',
    notes: 'नोट्स',
    viewNote: 'नोट देखें',
    name: 'नाम',
    before: 'पहले',
    manufactured: 'बनाए गए',
    consumed: 'इस्तेमाल किए गए',
    newTotal: 'इस्तेमाल किए गए',
    productionNumber: 'प्रोडक्शन',
    productManufactured: 'प्रोडक्ट बनाया गया',
    materialsConsumed: 'इस्तेमाल किए गए मटीरियल्स',
    noProductionActivity: 'इस ऑर्डर के पास कोई भी प्रोडक्शन एक्टिविटी नहीं है',
    originalProduction: 'वास्तविक प्रोडक्शन',
  },
};
export default production;
