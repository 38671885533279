import { combineReducers } from 'redux';
import navReducer from './navReducer';
import saveUser from './saveUser';
import navInventoryReducer from './navInventoryReducer';
import navManageReducer from './navManageReducer';
import graphReducer from './graphReducer';

export const reducers = combineReducers({
  collapseOpen: navReducer,
  user: saveUser,
  inventoryCollapse: navInventoryReducer,
  manageCollapse: navManageReducer,
  graphMenu: graphReducer,
});

export type RootState = ReturnType<typeof reducers>;
