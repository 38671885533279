import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { RootState } from './store/Reducer';
import { ADMIN, INVENTORY_PRODUCTION_SUPERVISOR } from './constants';
import storageService, { STORAGE_KEY } from './services/storageService';

import { applyTheme } from './theme/utils';
import { DEFAULT_THEME } from './theme';

const RouteAuthenticated: React.ComponentState = ({
  component: Component,
  path,
}: RouteProps) => {
  const refreshToken = storageService.get(STORAGE_KEY.REFRESH_TOKEN);
  const { user } = useSelector((state: RootState) => state);

  useEffect(() => {
    applyTheme(DEFAULT_THEME);
  }, []);
  [
    STORAGE_KEY.SIGN_UP_TOKEN,
    STORAGE_KEY.MOBILE,
    STORAGE_KEY.STATUS,
    STORAGE_KEY.USER_NAME,
    STORAGE_KEY.FACTORY_NAME,
  ].forEach((key) => storageService.remove(key));

  if (storageService.get(STORAGE_KEY.SIGN_UP_IN_PROGRESS)) {
    return <Redirect to="/signup" />;
  }

  if (!refreshToken) {
    return <Redirect to="/login" />;
  }

  return user?.role.toLowerCase() === ADMIN ||
    user.role.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR ? (
    <Route component={Component} path={path} />
  ) : (
    <Redirect to="/app" />
  );
};

export default RouteAuthenticated;
