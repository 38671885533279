import mixpanel, { Dict } from 'mixpanel-browser';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import convertToKebabCase from './convertToKebabCase';

const TRACKING_ID = process.env.REACT_APP_MIXPANEL_TRACKING_ID;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

interface UserData {
  name: string;
  factoryName: string;
  userId: number;
  industry: string;
  role: string;
  email: string;
  phone: string;
}

let isMixPanelInitialized = false;
let isGAInitialized = false;
let isCleverTapInitialized = false;

function init(): void {
  if (TRACKING_ID) {
    mixpanel.init(TRACKING_ID, { debug: false });
    isMixPanelInitialized = true;
  }
  if (firebaseConfig.projectId) {
    initializeApp(firebaseConfig);
    isGAInitialized = true;
  }
  isCleverTapInitialized = Boolean(process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID);
}

function identify(id: number): void {
  mixpanel.identify(`${id}`);
}

function sendEvent(
  name: string,
  properties?: Dict,
  goggleAnalytics = false
): void {
  if (isMixPanelInitialized) {
    mixpanel.track(name, properties);
  }
  if (goggleAnalytics && isGAInitialized) {
    const analytics = getAnalytics();
    logEvent(analytics, convertToKebabCase(name));
  }
  if (isCleverTapInitialized) {
    window['clevertap'].event.push(name, properties);
  }
}
function profileSet(data: UserData): void {
  if (isMixPanelInitialized) {
    mixpanel.people.set({
      $name: data?.name,
      factoryName: data.factoryName,
      userId: data.userId,
      role: data?.role,
      $email: data?.email,
      phone: data?.phone,
      industry: data?.industry,
    });
  }

  if (isCleverTapInitialized) {
    window['clevertap'].onUserLogin.push({
      Site: {
        Name: data?.name,
        FactoryName: data.factoryName,
        Industry: data.industry,
        Role: data?.role,
        Identity: data.userId,
        Email: data?.email,
        Phone: `+${data?.phone}`,
      },
    });
  }
}
function logout(): void {
  if (isCleverTapInitialized) {
    window['clevertap'].logout();
  }
}

export default {
  init,
  identify,
  sendEvent,
  profileSet,
  logout,
};
