const errorMessage = {
  generic: 'Something went wrong! Please try again later.',
  duplicateCode: 'File contains duplicate code',
  required: 'Required',
  jobNotUpdated: "Job couldn't get updated",
  orderNotCreated: "Order wasn't created! Please try again!",
  orderNotUpdated: "Order couldn't get updated",
  productionNotCreated: "Production wasn't created! Please try again!",
  partyAlreadyExists: 'Party already exists',
  ledgerDownloadFailed: 'Download Excel file failed. Please try again',
  maxPrice: 'Max price allowed: 99999999',
  maxAmount: 'Max amount allowed: 99999999',
  maxQty: 'Max qty allowed: 99999999',
  invalidValue: 'Invalid value',
  negativeAmount: 'Amount can’t be negative',
  negativePrice: 'Price can’t be negative',
  validDecimalNumber: 'Only upto 2 decimals allowed',
  maxValue: 'Max value: {{ maxValue }}',
  negativeValue: "Value can't be negative",
  inSufficientBalance: 'Insufficient balance! Avl quantity can’t be -ve',
  inSufficientBalanceSmall: 'Avl qty can’t be -ve',
  zeroValue: "Value can't be 0",
  twoDecimalAllowed: 'Only 2 decimals allowed',
  threeDecimalAllowed: 'Only 3 decimals allowed',
  productionNotUpdated: "Production wasn't updated! Please try again!",
  duplicateName: 'Duplicate',
  invItemMinValueError: 'Value can`t be less than {{minValue}}',
  invItemMacValueError: 'Max qty allowed: {{maxValue}}',
  invItemsAlreadyExists: 'Items name already exists',
};

export default errorMessage;
