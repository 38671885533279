import { FC } from 'react';
import LottiePlayer from 'react-lottie-player';

type LottieProps = {
  animationData: any;
  height?: number;
  width?: number;
  isLoop?: boolean;
};

const Lottie: FC<LottieProps> = ({
  animationData,
  height = 400,
  width = 400,
  isLoop = true,
}: LottieProps) => {
  return (
    <LottiePlayer
      loop={isLoop}
      play
      animationData={animationData}
      style={{ width, height }}
      rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
    />
  );
};

export default Lottie;
