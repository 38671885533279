import { AxiosPromise } from 'axios';
import { LANGUAGE_CODE } from '../../constants/enum';
import { axiosInstance } from '../../utils/axiosInterceptor';
import storageService, { STORAGE_KEY } from '../storageService';

export const checkUserLoggedIn = () =>
  Boolean(storageService.get(STORAGE_KEY.TOKEN));

export const checkUserPreferredLanguageSet = () =>
  Boolean(storageService.get(STORAGE_KEY.PREFERRED_LANGUAGE));

export const setUserPreferredLanguage = (preferredLanguage: LANGUAGE_CODE) => {
  if (preferredLanguage) {
    storageService.set(STORAGE_KEY.PREFERRED_LANGUAGE, preferredLanguage);
  }
};

export const getUserPreferredLanguage = () => {
  const userPreferredLanguage = storageService.get(
    STORAGE_KEY.PREFERRED_LANGUAGE
  );
  switch (userPreferredLanguage) {
    case LANGUAGE_CODE.HI:
      return LANGUAGE_CODE.HI;
    case LANGUAGE_CODE.HI_EN:
      return LANGUAGE_CODE.HI_EN;
    case LANGUAGE_CODE.EN:
      return LANGUAGE_CODE.EN;
    default:
      return undefined;
  }
};

export const getUserSettings = (): AxiosPromise =>
  axiosInstance({
    url: `/v1/users/settings`,
    method: 'get',
  });

export const patchUserSettings = (data: {
  preferredLanguage?: string;
}): AxiosPromise =>
  axiosInstance({
    url: `/v1/users/settings`,
    method: 'patch',
    data,
  });

export const getNotificationSetting = (): AxiosPromise =>
  axiosInstance({
    url: `v1/users/notification/setting`,
    method: 'get',
  });

export const patchNotificationSetting = (data): AxiosPromise =>
  axiosInstance({
    url: `v1/users/notification/setting`,
    method: 'patch',
    data,
  });
