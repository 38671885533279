const login = {
  maxLimitOtp: 'इस ओटीपी वेरीफिकेशन की अधिकतम सीमा हो चुकी है',
  validOtp: 'कृपया सही ओटीपी डालें',
  validNumber: 'कृपया सही नंबर डालें',
  otpSuccessMsg: 'ओटीपी सफलतापूर्वक भेजा गया',
  accountDeactivate: 'यूजर अकाउंट डीएक्टिवेट हो गया है',
  unableToProceed: 'आगे नहीं बढ़ा जा सकता, एडमिन से संपर्क करें',
  otpRequired: 'ओटीपी आवश्यक है*',
  changeLanguageBtn: 'भाषा बदलें',
  mobileNumber: 'मोबाइल नंबर',
  mobileNumberPlaceholder: 'मोबाइल नंबर डालें',
  copyRightTxt: '© फैक्ट्री वन प्राइवेट लिमिटेड',
  verifyOTPLbl: 'ओटीपी वेरीफाई करें',
  enterOtpLbl: 'आपको भेजी गई ओटीपी डालें ',
  supportBtn: 'सहायता',
  loginTxt: 'लॉग इन',
  signUpTxt: 'साइन अप',
  resendOTPBtn: 'ओटीपी दोबारा भेजें ',
  verifyBtn: 'वेरीफाई करें',
};

export default login;
