import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './store/Reducer';
import { useHistory } from 'react-router-dom';
import storageService, { STORAGE_KEY } from './services/storageService';
import { checkUserLoggedIn } from './services/userService';
import { ADMIN } from './constants';

const RouteUnauthenticated: React.ComponentState = ({
  component: Component,
  path,
}: RouteProps) => {
  const history = useHistory();
  const isRefreshTokenAvailable = Boolean(
    storageService.get(STORAGE_KEY.REFRESH_TOKEN)
  );
  const { user } = useSelector((state: RootState) => state);
  [
    STORAGE_KEY.SIGN_UP_TOKEN,
    STORAGE_KEY.MOBILE,
    STORAGE_KEY.STATUS,
    STORAGE_KEY.USER_NAME,
    STORAGE_KEY.FACTORY_NAME,
  ].forEach((key) => storageService.remove(key));

  if (
    isRefreshTokenAvailable &&
    user?.role === ADMIN &&
    !storageService.get(STORAGE_KEY.SIGN_UP_IN_PROGRESS)
  ) {
    return <Redirect to="/dashboard" />;
  }
  if (path === '/app') {
    const isUserLoggedIn = checkUserLoggedIn();
    if (isUserLoggedIn) {
      storageService.remove(STORAGE_KEY.TOKEN);
    } else {
      if (storageService.get(STORAGE_KEY.SIGN_UP_IN_PROGRESS)) {
        history.push('/signup');
      } else {
        history.push('/login');
      }
    }
  }

  return <Route component={Component} path={path} />;
};

export default RouteUnauthenticated;
