const materialBuy = {
  title: 'Enter Raw Material Details',
  materialBuyForm: {
    materialSpecificationLbl: 'Material Specification',
    materialSpecificationPlaceholder: 'Eg: 22X100GSM 16BF SEMI',
    plasticMaterialSpecPlaceholder: 'Eg: PP-Polypropylene',
    quantityLbl: 'Quantity Specification',
    quantityPlaceholder: 'Enter quantity',
    materialSpecificationRequiredError:
      'Please enter the material specification',
    qtyRequiredError: 'Please enter the quantity',
    qtyZeroError: `Qty can't be zero`,
    qtyNegativeError: `Qty can't be negative`,
    qtyMaxError: `Qty can't be greater than 100000`,
    addNewMaterial: '+ Add New Material',
    submitLbl: 'Submit Request',
    cancelLbl: 'Cancel',
  },

  confirmBooking: {
    title: 'Purchase request submitted successfully',
  },
};

export default materialBuy;
