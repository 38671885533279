const inventory = {
  category: {
    categoryBtn: 'Category',
    singleCategory: 'Single Category',
    multipleCategory: 'Multiple Category',
    deleteBtn: 'Delete',
  },

  addCategoryBulkForm: {
    categoryExistErrorMsg: 'Category Already Exists',
    duplicateCategoryErrorMsg: 'Duplicate Category exists',
    title: 'Bulk Add Categories',
    allowedExtensionsText:
      'Only files with the extensions .xls .xlxs .csv are allowed.',
    downloadTemplateBtn: 'download template',
    cancelBulkProcessBtn: 'Cancel',
    uploadBulkProcessBtn: 'Upload',
    dragAndDropText: 'drag and drop',
    uploadFromComputerText: 'upload from computer',
    invalidTemplateError:
      "can't upload this file. Check the template file and fill all the required fields.",
    partialFileReviewText:
      '{{errorLength}} out of {{totalLength}} items have errors in them. Remove errors to complete upload!',
    completeFileReviewText: 'Please review this',
    reviewedNameLbl: 'Name',
    reviewedNamePlaceholder: 'Name',
    reviewedNameRequiredError: 'Required',
    reviewedNameCommaError: 'Please remove comma(,)',
    uploadFileAgain: 'upload again',
    maxLengthError: `Process can't be greater than 100`,
  },

  addPartyForm: {
    title: 'Add Party',
    nameLbl: 'Name',
    namePlaceholder: 'Kumar Singh',
    partyTypeLbl: 'Party Type',
    partyTypePlaceholder: 'Customer/Vendor',
    mobileLbl: 'Mobile No.',
    mobilePlaceholder: 'Mobile No',
    personOfContactLbl: 'Person of Contact (Optional)',
    personOfContactPlaceholder: 'ex: Ramesh Sharma',
    emailLbl: 'Email (Optional)',
    emailPlaceholder: 'ex: abcxyz123@email.com',
    GSTINLbl: 'GSTIN (Optional)',
    GSTINPlaceholder: 'ex: 22 AAAAA0000A 1 Z 5 ',
    addressLbl: ' Address (Optional)',
    addressPlaceholder: 'ex: 72, Chintamani Road',
    addBtn: 'Add',
    requiredError: 'Required',
    validMobileError: 'Please enter a valid number',
    validEmailError: 'Please enter valid email',
    validGSTINError: 'Please enter valid GSTIN',
    customerLbl: 'Customer',
    customerVal: 'CUSTOMER',
    vendorLbl: 'Vendor',
    vendorVal: 'VENDOR',
    vendCustLbl: 'Vend & Cust',
    vendCustVal: 'VENDOR_CUSTOMER',
  },
  editPartyForm: {
    title: 'Edit Party',
  },
  editEntry: {
    title: 'Edit Entry',
    subTitle: 'Created On',
    reasonLabel: 'Reason',
    quantityLabel: 'Quantity',
    amountLbl: 'Amount',
    button: 'Save',
    reasonPlaceHolder: 'Ex: Return',
    requiredError: 'Required',
    priceLbl: 'Price Per Unit',
    partyLbl: 'Select Party',
    reelNameLbl: 'Reel Name',
    quantityLbl: 'Quantity',
    avlQty: 'Avl Qty : {{avlQty}}',
    addReelBtn: 'Add another reel',
    anotherReelBtn: 'Select another reel',
    totalQuantity: 'Total Quantity :',
    partyPlaceholder: 'Search Party',
  },

  viewHistory: {
    title: 'Edit History',
    qtyLabel: 'Original Quantity',
    priceLabel: 'Original Amount',
    timestamp: 'Date & Time',
    reason: 'Reason',
    quantity: 'Quantity',
    price: 'Amount',
    updatedBy: 'Updated by',
    originalParty: 'Original Party',
    originalNotes: 'Original Notes',
    viewNote: 'View Note',
    party: 'Party',
    note: 'Note',
  },
  list: {
    dataNotAvailable: 'NA',
    codeAndName: 'Code & Name',
    brand: 'Brand',
    avlQty: 'Avl QTY',
    minQty: 'Min QTY',
    ppu: 'Price Per Unit',
    hsn: 'HSN Code',
    reel: 'Reel',
    reels: 'Reels',
  },
  partySms: {
    title: 'Send SMS to Party',
    question:
      'Do you always want to send party an SMS whenever an action is performed against them.',
  },
  details: {
    viewHistory: 'View Edit History',
    editEntry: 'Edit Entry',
    remainingQty: 'Remaining : {{value}} {{unit}}',
    consumedByAt: '{{name}} at',
    edited: '(edited)',
    actionTypeInOrder: 'in #{{orderCode}}',
    duplicateMaterialBtn: 'Duplicate',
    backBtn: 'Back',
    editMaterialBtn: 'Edit',
    deleteMaterialBtn: 'Delete',
    dataNotAvailable: 'NA',
    avlQty: 'Available Quantity',
    minQty: 'Minimum Quantity',
    categories: 'Categories',
    usageHistory: 'Usage History',
    ppu: 'Price Per Unit',
    brand: 'Brand',
    avlReelsLbl: 'Available Reels',
    reelQty: '{{reel}} Reels',
    hsnCodeLbl: 'HSN Code',
  },

  addEditInventoryForm: {
    createMaterialTitle: 'Create Material',
    editMaterialTitle: 'Edit Material',
    editProductTitle: 'Edit Product',
    codeLbl: 'Code',
    codePlaceholder: 'Material Code',
    codeSuggestion: 'suggestion',
    nameLbl: 'Name',
    namePlaceholder: 'ex: Kraft Paper 32x45x28',
    unitLbl: 'Unit',
    unitPlaceholder: 'ex: kgs,metres',
    brandLbl: 'Brand',
    priceLbl: 'Price',
    brandPlaceholder: 'ex: Nippon',
    openingStockLbl: 'Opening Stock',
    openingStockPlaceholder: 'ex: 2500',
    minQtyLbl: 'Minimum Qty',
    minQtyPlaceholder: 'ex: 500',
    categoryLbl: 'Category',
    categoryPlaceholder: 'ex: Shelf-23',
    cancelMaterialBtn: 'Cancel',
    createMaterialBtn: 'Create',
    saveMaterialBtn: 'Save',
    requiredError: 'Required',
    qtyNegativeError: 'Qty cannot be negative',
    commaNotAllowedError: 'Comma(,) not allowed',
    numberError: 'Enter a Number',
    copyLbl: '(Copy)',
    materialInfo: 'Material Info',
    useReelsLbl: 'Use reels',
    reelNameLbl: 'Reel Name',
    quantityLbl: 'Quantity',
    addReelBtn: 'Add another reel',
    hsnLbl: 'HSN Code',
    hsnPlaceholder: 'ex: 12345678',
    secondaryBtn: 'Use secondary unit',
    secondaryUnitLbl: ' Secondary Unit',
    conversionRateLbl: 'Conversion Rate',
    conversionRatePlaceholder: 'ex: 100',
    stockInfoLbl: 'Stock Info',
    totalQuantity: 'Total Quantity :',
    createMaterialTitleBar: 'Create Material | FactoryPlus',
    editMaterialTitleBar: 'Edit Material | FactoryPlus',
    productInfo: 'Product Info',
    avlQtyLbl: 'Available Quantity',
    reelsPlaceholder: 'ex: Reel',
  },
  actionLoader: {
    materialInfo: 'Material Info',
    stockInfoLbl: 'Stock Info',
  },
  inventoryItem: {
    reelImgAlt: 'Reel',
  },
  dropdownOption: {
    avlQtyLabel: 'Avl Qty :',
    statusLow: 'LOW',
  },
  actionSection: {
    excelBtn: 'Excel',
    singleMaterial: 'Single Material',
    multipleMaterials: 'Multiple Materials',
    singleProduct: 'Single Product',
    multipleProducts: 'Multiple Products',
    material: 'Material',
    product: 'Product',
  },
};

export default inventory;
