const nav = {
  dashboard: 'Dashboard',
  orders: 'Orders',
  production: 'Production',
  reminders: 'Reminders',
  processes: 'Processes',
  employees: 'Employees',
  settings: 'Settings',
  party: 'Party',
  vendors: 'Vendors',
  customers: 'Customers',
  reports: 'Reports',
  inventory: 'Inventory',
  materials: 'Materials',
  products: 'Products',
  ledger: 'Ledger',
  manage: 'Manage',
  logout: 'Logout',
  needHelp: 'Need help',
  chatWithus: 'Chat with us',
  logOutModalTitle: 'Are you sure to logout?',
  logOutModalSubTitle: 'You will be returned to the login screen',
  logOutBtn: 'Logout',
  cancelBtn: 'Cancel',
};

export default nav;
