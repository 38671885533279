export const SUPPORT_SDK_BASE_URL = 'https://app.chatwoot.com';
export const SUPPORT_WEBSITE_TOKEN =
  process.env.REACT_APP_SUPPORT_WEBSITE_TOKEN;

export const SUPPORT_SDK_SETTINGS = {
  hideMessageBubble: true,
  position: 'right', // This can be left or right
  locale: 'en', // Language to be set
  type: 'standard', // [standard, expanded_bubble]
};

export const SUPPORT_CUSTOM_ATTRIBUTE_KEY = {
  FACTORY_ID: 'factory_id',
  FACTORY_NAME: 'factory_name',
  UNVERIFIED_PHONE_NUMBER: 'unverified_phone_number',
};
