const errorMessage = {
  generic: 'कुछ गड़बड़ी हुई है! कृपया दोबारा कोशिश करें',
  duplicateCode: 'फाइल में डूप्लीकेट कोड है',
  required: 'आवश्यक',
  jobNotUpdated: 'जॉब अपडेट नहीं किया जा सका',
  orderNotCreated: 'ऑर्डर नहीं बन पाया! कृपया दोबारा कोशिश करें!',
  orderNotUpdated: 'ऑर्डर अपडेट नहीं किया जा सका',
  productionNotCreated: 'प्रोडक्शन नहीं बन पाया! कृपया दोबारा कोशिश करें!',
  partyAlreadyExists: 'पार्टी पहले से मौजूद है',
  ledgerDownloadFailed: 'एक्सेल फाइल डाउनलोड नहीं हुआ, कृपया दोबारा कोशिश करें',
  maxPrice: 'अधिकतम कीमत की अनुमतिः 99999999',
  maxAmount: 'अधिकतम राशि की अनुमतिः 99999999',
  maxQty: 'अधिकतम मात्रा की अनुमतिः 99999999',
  invalidValue: 'गलत वैल्यू',
  negativeAmount: 'राशि नेगेटिव नहीं हो सकती',
  negativePrice: 'राशि नेगेटिव नहीं हो सकती',
  validDecimalNumber: 'सिर्फ दो डेसिमल तक अनुमति',
  maxValue: 'अधिकतम वैल्यू: {{ maxValue }}',
  negativeValue: 'वैल्यू नेगेटिव नहीं हो सकती',
  inSufficientBalance: 'अपर्याप्त बैलेंस! उपलब्ध मात्रा नेगेटिव नहीं हो सकती',
  inSufficientBalanceSmall: 'उपलब्ध मात्रा नेगेटिव नहीं हो सकती',
  zeroValue: 'वैल्यू 0  नहीं हो सकती',
  twoDecimalAllowed: 'सिर्फ दो डेसिमल की अनुमति',
  threeDecimalAllowed: 'सिर्फ तीन डेसिमल की अनुमति',
  productionNotUpdated: 'प्रोडक्शन अपडेट नहीं हुआ! कृपया दोबारा कोशिश करें!',
  duplicateName: 'डूप्लीकेट',
  invItemMinValueError: ' वैल्यू {{minValue}} से कम नहीं हो सकती',
  invItemMacValueError: ' अधिकतम मात्रा की अनुमतिः {{maxValue}}',
  invItemsAlreadyExists: 'आइटम का नाम पहले से मौजूद है',
};

export default errorMessage;
