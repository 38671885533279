const reminder = {
  reminders: 'Reminders',
  addReminderBtn: 'Add Reminder',
  removeReminderTooltip: 'Remove Reminder',
  noReminderMessage1: 'You don’t have any reminders set',
  noReminderMessage2: 'Copy Looks like you haven’t set any',
  noReminderMessage3: 'reminders for your Factory',
  deleteReminderModal: {
    title: ' Delete Reminder',
    bodyText: 'Are you sure you want to delete {{time}}?',
    cancelBtn: 'Cancel',
    deleteBtn: 'Delete',
  },
  updateReminderForm: {
    title: 'Reminder Details',
    timeLbl: 'Select Time',
    timePlaceholder: 'Time',
    every: 'Every',
    sunday: 'Sun',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    cancelBtn: 'Cancel',
    createBtn: 'Create',
    errorMsg: 'Reminder Already Exists',

    updateBtn: 'Update',
  },
};
export default reminder;
