const processes = {
  title: 'Processes',
  addProcessBtn: 'Add Process',
  addProcessBulkBtn: 'Bulk Upload',
  processSearchPlaceholderSuffix: 'Process',
  addEditProcessForm: {
    titleAdd: 'Add a Process',
    titleEdit: 'Edit Process',
    nameLbl: 'Name',
    namePlaceholder: 'Name',
    cancelProcessBtn: 'Cancel',
    createProcessBtn: 'Create',
    updateProcessBtn: 'Update',
    nameRequiredError: 'Required',
    processCommaError: 'Comma(,) not allowed',
  },
  addProcessBulkForm: {
    title: 'Bulk Add Processes',
    allowedExtensionsText:
      'Only files with the extensions .xls .xlxs .csv are allowed.',
    downloadTemplateBtn: 'download template',
    cancelBulkProcessBtn: 'Cancel',
    uploadBulkProcessBtn: 'Upload',
    dragAndDropText: 'drag and drop',
    uploadFromComputerText: 'upload from computer',
    invalidTemplateError:
      "can't upload this file. Check the template file and fill all the required fields.",
    partialFileReviewText:
      '{{errorLength}} out of {{totalLength}} items have errors in them. Remove errors to complete upload!',
    completeFileReviewText: 'Please review this',
    reviewedNameLbl: 'Name',
    reviewedNamePlaceholder: 'Name',
    reviewedNameRequiredError: 'Required',
    reviewedNameCommaError: 'Please remove comma(,)',
    uploadFileAgain: 'upload again',
  },
  processDetail: {
    editProcessBtn: 'Edit',
    deleteProcessBtn: 'Delete',
    deleteProcessModal: {
      title: 'Delete a process',
      bodyText: 'Are you sure you want to delete {{processName}}?',
      cancelBtn: 'Cancel',
      deleteBtn: 'Delete',
    },
    employeesHeading: 'Default employees for this process',
    employeeSearchPlaceholderSuffix: 'Employee',
    assignEmployeeBtn: 'Assign Employee',
    removeEmployeeTooltip: 'Remove Employee',
    assignEmployeeForm: {
      title: 'Assign employee',
      selectEmployeeLbl: 'Select Employees',
      searchEmployeePlaceholder: 'Search Employee',
      cancelBtn: 'Cancel',
      assignBtn: 'Assign',
    },
    removeEmployeeModal: {
      title: 'Are you sure?',
      bodyText:
        'You are about to remove “{{employeeName}}” from {{processName}}.',
      cancelBtn: 'Cancel',
      removeBtn: 'Remove',
    },
  },
};

export default processes;
