const production = {
  title: 'Production',
  produceBtn: 'Produce',
  productCodeSearchPlaceholderSuffix: 'Product code ...',
  productQuantity: '{{quantity}} pcs',
  timeSinceRecording: 'Recorded {{time}} ago',
  loadMoreBtn: 'Load more',
  noSearchResultFound:
    "Oops! We couldn't find any details related to {{searchText}}",
  noOrdersYet: "Looks like you don't have any orders yet",
  party: 'Party',
  createProductionForm: {
    numberError: 'Enter a Number',
    openingStockPlaceholder: '0',
    reelsNamePlaceholder: 'Select Reels',
    stepProduct: 'Party & Product',
    stepMaterials: 'Materials',
    title: 'Production',
    codeLbl: 'Code',
    codePlaceholder: 'Product Code',
    codeSuggestion: 'suggestion',
    productLbl: 'Select Product',
    productPlaceholder: 'Enter Product Name',
    qtyLbl: 'Enter Quantity',
    qtyPlaceholder: 'Qty*',
    qtyUnit: 'pcs',
    noteLbl: 'Note',
    addReelBtn: 'Select another Reel',
    productCode: 'Code',
    productQty: '{{quantity}}',
    rawMaterials: 'Raw Materials',
    addBtn: 'Add',
    closeInputBtn: 'Close',
    materialPlaceholder: 'Enter material name',
    avlQty: 'Avl Qty',
    materialQtyPlaceholder: 'Qty',
    noMaterialAdded: 'No material added yet',
    saveMaterialRatio: 'Save material ratio for future use',
    prevBtn: 'Prev',
    produceBtn: 'Produce',
    nextBtn: 'Next',
    producedSuccessfully: 'Production Recorded Successfully',
    closeBtn: 'Close',
    summaryBtn: 'Summary',
    requiredError: 'Required',
    partyLbl: 'Select Customer',
    partyPlaceholder: 'Search Customer',
  },
  updateProductionForm: {
    qtyLbl: 'Enter Quantity',
    qtyPlaceholder: 'Qty*',
    noteLbl: 'Note',
    materialQtyPlaceholder: 'Qty',
    noMaterialAdded: 'No material added yet',
    submitBtn: 'Submit',
    partyLbl: 'Select Customer',
    partyPlaceholder: 'Search Customer',
  },
  productionDetail: {
    title: 'Code: {{codeNumber}}',
    dataNotAvailable: 'NA',
    recodedOn: 'Recorded on',
    recodedBy: 'Recorded by',
    productManufactured: 'Product Manufactured',
    materialsConsumed: 'Materials Consumed',
    name: 'Name',
    oldBalance: 'Old Balance',
    manufactured: 'Manufactured',
    consumed: 'Consumed',
    newBalance: 'New Balance',
    productQty: '{{quantity}} pcs',
    noMaterialsAdded: 'No materials added',
    party: 'Party',
    edited: '(edited)',
  },
  productionHistory: {
    editHistory: 'Edit History',
    edit: 'Edit {{index}}',
    editedOn: 'Edited on',
    party: 'Party',
    recordedBy: 'Recorded by',
    notes: 'Notes',
    viewNote: 'View Note',
    name: 'Name',
    before: 'Before',
    manufactured: 'Manufactured',
    consumed: 'Consumed',
    newTotal: 'New Total',
    productionNumber: 'Production',
    productManufactured: 'Product Manufactured',
    materialsConsumed: 'Materials Consumed',
    noProductionActivity: "This order doesn't have any production activity",
    originalProduction: 'Original Production',
  },
};
export default production;
