const global = {
  dateTime: {
    toDay: 'Today',
    yesterday: 'Yesterday',
    last30Days: 'Last 30 Days',
    thisMonth: 'This Month',
    lastMonth: 'Last Month',
    customRange: 'Custom Range',
  },
  datePickerDropDown: {
    applyBtn: 'apply',
  },
  errorPage: {
    errorMessage: 'Something went wrong...',
    errorTitle: 'We are working on fixing the problem',
    errorSubTitle: 'Please try again',
    errorPageRefreshBtn: 'Refresh Page',
  },
  searchBar: {
    searchTxt: 'Search',
  },
  noDataPage: {
    title: 'No Data Found',
  },
};
export default global;
