const materialBuy = {
  title: 'रॉ मटीरियल्स की डिटेल्स डालें',
  materialBuyForm: {
    materialSpecificationLbl: 'मटीरियल का खास विवरण',
    materialSpecificationPlaceholder: 'उदाहरणः 22X100GSM 16BF सेमी',
    plasticMaterialSpecPlaceholder: 'उदाहरणः PP-Polypropylene',
    quantityLbl: 'मात्रा का खास विवरण',
    quantityPlaceholder: 'मात्रा डालें',
    materialSpecificationRequiredError: 'कृपया मटीरियल का खास विवरण डालें',
    qtyRequiredError: 'कृपया मात्रा डालें',
    qtyZeroError: 'मात्रा 0  नहीं हो सकती',
    qtyNegativeError: 'मात्रा नेगेटिव नहीं हो सकती',
    qtyMaxError: 'मात्रा 100000 से ज्यादा नहीं हो सकती',
    addNewMaterial: '+ नया मटीरियल जोड़ें',
    submitLbl: 'रिक्वेस्ट सबमिट करें',
    cancelLbl: 'कैंसल करें',
  },

  confirmBooking: {
    title: 'खरीद रिक्वेस्ट अच्छे तरीके से सबमिट किया गया',
  },
};

export default materialBuy;
