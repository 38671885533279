const ledger = {
  ledgerList: {
    codeAndName: 'कोड और नाम',
    action: 'एक्शन',
    quantity: 'मात्रा',
    price: 'राशि',
    date: 'तारीख',
    party: 'पार्टी',
    productionId: 'प्रोडक्शन आईडी',
    employee: 'एमप्लॉई',
    notes: 'नोट्स',
  },
  ledgerSearchPlaceholderSuffix: 'प्रोडक्ट, ऑर्डर आईडी...',
  noDataMessage1: 'आपके पास लेजर में कोई डेटा नहीं है',
  noDataMessage2: 'ऐसा लगता है कि आपने इन्वेंट्री में कोई कार्रवाई नहीं की है',
  noSearchData:
    'आपके पास लेजर में "{{searchText}}" से संबंधित कोई डेटा नहीं है',

  heading: 'लेजर',
  excelBtn: 'एक्सेल',
  noDataMsg: 'लेजर में चुने गए फिल्टर/फिल्टरों के लिए कोई रिजल्ट नहीं',
  noActionMsg: 'ऐसा लगता है कि आपने इन्वेंट्री में कोई कार्रवाई नहीं की है',
  searchNoMatch:
    'लेजर में सर्च शब्द "{{searchText}}" के लिए कोई रिजल्ट नहीं है',
  filtersInitial: {
    dateLbl: 'तारीख',
    lifeTimeName: 'आजीवन',
    lifeTimeLbl: 'आजीवन',
    todayName: 'आज',
    todayLbl: 'आज',
    yesterdayLbl: 'कल',
    yesterdayName: 'कल',
    weekName: 'इस सप्ताह',
    weekLbl: 'इस सप्ताह',
    yearName: 'इस वर्ष',
    yearLbl: 'इस वर्ष',
    dateRangeName: 'तारीख का दायरा',
    dateRangeLbl: 'तारीख का दायरा',
    typeLbl: 'प्रकार',
    productName: 'प्रोडक्ट',
    productLbl: 'प्रोडक्ट',
    materialName: 'मटीरियल',
    materialLbl: 'मटीरियल',
    actionLbl: 'एक्शन',
    produceName: 'उत्पाद',
    restockLbl: 'री-स्टॉक',
    dispatchName: 'भेजें',
    dispatchLbl: 'भेजें',
    consumeName: 'इस्तेमाल करें',
    consumeLbl: 'इस्तेमाल करें',
    refillName: 'रीफिल करें',
    refillLbl: 'रीफिल करें',
    employeeLbl: 'एमप्लॉई',
  },
  tableHeader: {
    dateTime: 'तारीख और समय',
    type: 'प्रकार',
    codeName: 'कोड और नाम',
    action: 'एक्शन',
    employee: 'एमप्लॉई',
    notes: 'नोट्स',
    prodId: 'प्रोडक्शन आईडी',
    qty: 'मात्रा',
  },
  filter: {
    filterBtn: 'फिल्टर',
    title: 'फिल्टर लेजर',
    clearAllBtn: 'सभी हटाएं',
    to: 'के लिए',
    applyFilterBtn: 'फिल्टर लगाने के लिए',
    customerAndVendor: 'कस्टमर, वेंडर',
    vendor: 'वेंडर',
    customer: 'कस्टमर',
  },
};

export default ledger;
