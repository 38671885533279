const dashboard = {
  duplicateError: 'Duplicate Reel',
  atLeastOneErrorMessage: 'Enter quantity for at least one material or reel.',
  greeting: 'Welcome to {{name}}',
  defaultGreeting: 'Welcome to your factory',
  welcomeMsg: 'Check out what is happening at your factory today.',
  inr: 'INR',
  materialSearchPlaceholderSuffix: 'Material Name...',
  recentProductions: ' Recent Productions',
  banner: {
    newFeatureLbl: 'New Feature',
    rawMaterial: {
      buyPaper: 'Buy Paper Raw Materials',
      buyPlastic: 'Buy Plastic Raw Materials',
      purchase: 'Click here to purchase',
      purchaseBtn: 'Purchase',
      material: 'Material',
    },
    rmPurchaseConfirmationTitle: 'Purchase request submitted successfully',
    rmPurchaseConfirmationBtnLbl: 'Move to dashboard',
  },
  dropdownMenu: {
    employees: 'Employees',
    products: 'Products',
    materials: 'Materials',
  },
  stats: {
    activeOrders: 'Active Orders',
    dispatches: 'No. of Dispatches',
    finishedOrders: 'Orders Finished',
  },
  addEmployeeForm: {
    title: 'Add an employee',

    nameLbl: 'Name',
    namePlaceholder: 'Name',
    nameRequiredError: 'Required',
    nameInvalidError: 'Required',

    emailLbl: 'Email',
    emailPlaceholder: 'Email',
    emailInvalidError: 'Please enter valid email',

    mobileLbl: 'Mobile',
    mobileCode: '+91',
    mobilePlaceholder: 'Mobile Number',
    mobileRequiredError: 'Required',
    mobileInvalidError: 'Please enter valid number',

    roleLbl: 'Role',
    rolePlaceholder: 'Search Role',
    roleRequiredError: 'Required',

    adminLbl: 'Admin',
    adminVal: 'Admin',

    prodSupervisorLbl: 'Production Supervisor',
    prodSupervisorVal: 'Production Supervisor',

    inventoryAndProdLbl: 'Inventory and Production Supervisor',
    inventoryAndProdVal: 'Inventory and Production Supervisor',

    inventoryAndProdViewOnlyLbl:
      'Inventory and Production Supervisor (View Only)',
    inventoryAndProdViewOnlyVal: 'Inv. Supervisor (view only)',

    processesLbl: 'Processes',
    processesPlaceholder: 'Search Processes',

    addBtn: 'Add',
    cancelBtn: 'Cancel',
  },
  addMachineForm: {
    title: 'Add a Machine',

    nameLbl: 'Name',
    namePlaceholder: 'Machine Name',
    nameRequiredError: 'Required',
    nameInvalidError: 'Required',

    specsLbl: 'Specs',
    specsPlaceholder: 'Specs',

    processesLbl: 'Processes',
    processesPlaceholder: 'Search Processes',

    cancelBtn: 'Cancel',
    addBtn: 'Add',
  },

  order: {
    title: 'Order',
    createBtn: 'Create',
  },
  production: {
    title: 'Production',
    recordBtn: 'Record',
  },
  materials: {
    title: 'Materials',
    refillBtn: 'Refill',
    consumeBtn: 'Consume',
  },
  product: {
    title: 'Product',
    restockBtn: 'Re-Stock',
    dispatchBtn: 'Dispatch',
  },
  chart: {
    salesBtn: 'Total Sales',
    purchaseBtn: 'Total Purchase',
    subTitle: '(Last 7 days)',
  },
  lowInventory: {
    title: 'Low Inventory',
    fullStockMsg1: 'Looks like your items are',
    fullStockMsg2: 'fully stocked.',
  },
  refillMaterialForm: {
    note: 'Note',
    addReel: ' Add Reel',
    name: 'Name',
    reels: 'Reels',
    reel: 'Reel',
    avlQtyLbl: 'Available Quantity',
    reelSetting: ' This material has reel settings,',
    avlQty: 'Avl Qty',
    title: 'Refill Materials',
    addMaterialLbl: 'Add Material',
    addMaterialPlaceholder: 'Search Material',
    save: 'Save',
    vendorLbl: 'Select Vendor (Optional)',
    vendorPlaceholder: 'Search Vendor',
    reelsNameLbl: 'Reel Name',
    tableHeaderMaterialCodeName: 'Material code & Name',
    tableHeaderNotes: 'Notes',
    tableHeaderAvailableQty: 'Available Quantity',
    tableHeaderQty: 'Quantity',
    tableHeaderPricePerUnit: 'Price Per Unit',
    tableHeaderAmount: 'Amount',

    notesInputPlaceholder: 'Note',
    qtyInputPlaceholder: '0',
    requiredError: 'Required',
    unit: '{{unit}}',
    searchAndAdd: 'Search and add materials',
    savePricePerUnitCheckbox: 'Save Price Per Unit for All Items',
    cancelBtn: 'Cancel',
    refillBtn: 'Refill Materials',
  },
  consumeMaterialForm: {
    avlQty: 'Avl Qty:',
    savePricePerUnitCheckbox: 'Save Price Per Unit for All Items',
    title: ' Bulk consume Materials',
    addMaterialLbl: 'Add Material',
    addMaterialPlaceholder: 'Search Material',
    note: 'Note',
    partyLbl: 'Select Party (Optional)',
    partyPlaceholder: 'Search Party',
    reels: 'Reels',
    tableHeaderMaterialCodeName: 'Material code & Name',
    tableHeaderNotes: 'Notes',
    tableHeaderAvailableQty: 'Available Quantity',
    tableHeaderQty: 'Quantity',

    notesInputPlaceholder: 'Note',
    qtyInputPlaceholder: '0',
    unit: '{{unit}}',
    searchAndAdd: 'Search and add materials',
    consumeBtn: 'Consume Materials',
    cancelBtn: 'Cancel',
  },
  restockProductForm: {
    title: 'Restock Products',

    addProductLbl: 'Add Product',
    addProductPlaceholder: 'Search Product',

    partyLbl: 'Select Party (Optional)',
    partyPlaceholder: 'Search Party',

    tableHeaderProductCodeName: 'Product code & Name',
    tableHeaderNotes: 'Notes',
    tableHeaderAvailableQty: 'Available Quantity',
    tableHeaderQty: 'Quantity',

    notesInputPlaceholder: 'Note',
    qtyInputPlaceholder: '0',
    unit: 'pcs',
    searchAndAdd: 'Search and add materials',
    restockBtn: 'Restock',
  },
  dispatchProductForm: {
    title: 'Dispatch Products',
    addProductLbl: 'Add Product',
    addProductPlaceholder: 'Search Product',

    customerLbl: 'Select Customer (Optional)',
    customerPlaceholder: 'Select Customer',

    tableHeaderProductCodeName: 'Product code & Name',
    tableHeaderNotes: 'Notes',
    tableHeaderAvailableQty: 'Available Quantity',
    tableHeaderQty: 'Quantity',
    tableHeaderPricePerUnit: 'Price Per Unit',
    tableHeaderAmount: 'Amount',

    notesInputPlaceholder: 'Note',
    qtyInputPlaceholder: '0',
    unit: 'pcs',
    searchAndAdd: 'Search and add materials',
    savePricePerUnitCheckbox: 'Save Price Per Unit for All Items',
    cancelBtn: 'Cancel',
    dispatchBtn: 'Dispatch',
  },
  notificationFeed: {
    noNotif: 'No notifications as of now',
  },
};

export default dashboard;
