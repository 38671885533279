const employees = {
  title: 'Employees',
  addEmployeeBtn: 'Add Employee',
  employeeSearchPlaceholderSuffix: 'Employee',
  employeeImgAltText: 'Employee Name',
  invProdSupervisor: 'Inv. and Prod. Supervisor',
  invProdSupervisorViewOnly: 'Inv. Supervisor (view only)',
  prodSupervisor: 'Prod. Supervisor',
  employeePendingBtn: 'Pending',
  employeeDeactivated: 'Deactivated',
  editEmployeeBtn: 'Edit',
  deactivateEmployeeBtn: 'Deactivate',
  reactivateEmployeeBtn: 'Reactivate',
  deleteEmployeeBtn: 'Delete',
  loadMoreBtn: 'Load more',
  addEditEmployeeForm: {
    titleAdd: 'Add an employee',
    titleEdit: 'Edit employee',
    nameLbl: 'Name',
    namePlaceholder: 'Name',
    emailLbl: 'Email',
    emailPlaceholder: 'Email',
    mobileLbl: 'Mobile',
    mobilePlaceholder: 'Mobile Number',
    roleLbl: 'Role',
    roleSearchPlaceholder: 'Search Role',
    processesLabel: 'Processes',
    processesSearchPlaceholder: 'Search Processes',
    cancelEmployeeBtn: 'Cancel',
    createEmployeeBtn: 'Create',
    updateEmployeeBtn: 'Update',
    requiredError: 'Required',
    validEmailError: 'Please enter valid email',
    validMobileError: 'Please enter a valid number',
  },
  roleDropDown: {
    adminLbl: 'Admin',
    adminVal: 'Admin',

    prodSupervisorLbl: 'Production Supervisor',
    prodSupervisorVal: 'Production Supervisor',

    inventoryAndProdLbl: 'Inventory and Production Supervisor',
    inventoryAndProdVal: 'Inventory and Production Supervisor',

    inventoryAndProdViewOnlyLbl:
      'Inventory and Production Supervisor (View Only)',
    inventoryAndProdViewOnlyVal: 'Inv. Supervisor (view only)',
  },
  employeeDetail: {
    title: 'Employee Details',
    editEmployeeBtn: 'Edit',
    deleteEmployeeBtn: 'Delete',
    employeeImgAltText: 'Employee Name',
    employeeNotSignedUp: {
      status: 'Status',
      employeePendingBtn: 'Pending',
      invite: 'Invite',
      sendInvite: 'Send invite',
    },
    employeeContactNo: 'Contact No.',
    employeeEmail: 'Email',
    employeeWhatsapp: 'Whatsapp',
    sendMessage: 'Send message',
    employeeProcesses: 'Processes',
    employeeJobsListing: {
      title: 'Jobs',
      activeJobs: 'Active',
      upcomingJobs: 'Upcoming',
      pastJobs: 'Past',
      quantityUnit: '{{quantity}} pcs',
      loadMoreBtn: 'Load more',
    },
    activateBtn: 'Activate Employee',
    deleteEmployeeModal: {
      title: 'Delete an employee',
      bodyText: 'Are you sure you want to delete {{employeeName}}?',
      cancelBtn: 'Cancel',
      deleteBtn: 'Delete',
    },
    deactivateEmployee: {
      title: 'Deactivate an employee',
      bodyText: 'Are you sure you want to deactivate {{employeeName}}?',
      cancelBtn: 'Cancel',
      deactivateBtn: 'Deactivate',
    },
    reactivateEmployee: {
      title: 'Reactivate Employee',
      bodyText:
        'Employee with number +{{number}} already exists and is deactivated. Do you want to activate this employee?',
      cancelBtn: 'Cancel',
      reactivateBtn: 'Reactivate',
    },
    employeeVerification: {
      title: 'Employee Verification',
      subTitle: 'Enter the code sent to you at',
      verifyBtn: 'Verify Code',
    },
  },
};

export default employees;
