const settings = {
  title: 'Settings',
  general: {
    title: 'General',
    profile: 'Profile',
    editBtn: 'Edit',
    name: 'Name',
    photo: 'Photo',
    email: 'Email',
    mobileNumber: 'Mobile Number',
  },
  factory: {
    title: 'Factory',
    factorySettings: 'Factory Settings',
    editBtn: 'Edit',
    name: 'Name',
    address: 'Address',
    email: 'Email',
    contactNumber: 'Contact Number',
    gstin: 'GSTIN',
    reminders: 'Reminders',
    addReminderBtn: 'Add Reminder',
    removeReminderTooltip: 'Remove Reminder',
    noReminderCreated: 'No reminder created yet',
  },
  deleteReminderModal: {
    title: ' Delete Reminder',
    bodyText: 'Are you sure you want to delete {{time}}?',
    cancelBtn: 'Cancel',
    deleteBtn: 'Delete',
  },
  updateFactoryForm: {
    validNumber: 'Enter valid number',
    edit: 'Edit',
    title: 'Factory Details',
    nameLbl: 'Name',
    namePlaceholder: 'name',
    photoLbl: 'Photo',
    addressLbl: 'Address',
    addressPlaceholder: 'address',
    contactNumberLbl: 'Contact Number',
    contactNumberPlaceHolder: '9999999999',
    gstinLbl: 'GSTIN',
    gstinPlaceholder: 'gstin',
    cancelBtn: 'Cancel',
    updateBtn: 'Update',
    requiredError: 'Required',
    mobileInvalidError: 'Please enter a valid number',
    gstinInvalidError: 'Please enter valid GSTIN',
    resetBtn: 'Reset Factory',
    resetModal: {
      title: 'Reset Factory',
      bodyText: 'Are you sure you want to reset your factory?',
      cancelBtn: 'Cancel',
      resetBtn: 'Reset',
      successMessage:
        'Your request has been submitted and someone from our team will reach out to you soon!',
    },
  },
  updateProfileForm: {
    edit: 'Edit',
    title: 'Profile',
    nameLbl: 'Name',
    namePlaceholder: 'name',
    mobileLbl: 'Mobile No.',
    mobilePlaceholder: '9999999999',
    photoLbl: 'Photo',
    emailLbl: 'Email',
    emailPlaceholder: 'email',
    cancelBtn: 'Cancel',
    updateBtn: 'Update',
    validEmailError: 'Please enter valid email',
    requiredError: 'Required',
    languageSettingsLbl: 'Language Settings',
    changeLanguageBtn: 'Change Language',
  },

  notificationSetting: {
    title: 'Notification Settings',
    email: {
      header: 'Email Notifications',
      detailsText: 'Play sounds and show incoming emails from your factory.',
    },
    whatsApp: {
      header: 'Whatsapp Notifications',
      detailsText:
        'Play sounds and show incoming Whatsapp messages from your factory.',
    },
  },

  factorySetting: {
    title: 'Factory Settings',
    sms: {
      header: 'Party Transaction SMS',
      detailsText:
        'Send SMS to a party whenever a transaction is made against it.',
    },
  },
  languageSetting: {
    title: 'Select Language',
    subTitle:
      'Please select a language that you can read and understand comfortably. Selected language will get applied to the app.',
    saveBtn: 'Save Preference',
    cancelBtn: 'Cancel',
    successMsg: 'Language Updated',
  },
  resetBtn: 'Reset Factory',
  account: 'Account',
  dataNotAvailable: 'NA',
};
export default settings;
