const settings = {
  title: 'सेटिंग्स',
  general: {
    title: 'जनरल',
    profile: 'प्रोफाइल',
    editBtn: 'एडिट करें',
    name: 'नाम',
    photo: 'फोटो',
    email: 'ईमेल',
    mobileNumber: 'मोबाइल नंबर',
  },
  factory: {
    title: 'फैक्ट्री',
    factorySettings: 'फैक्ट्री सेटिंग्स',
    editBtn: 'एडिट करें',
    name: 'नाम',
    address: 'पता',
    email: 'ईमेल',
    contactNumber: 'कॉन्टैक्ट नंबर',
    gstin: 'जीएसटीआईएन',
    reminders: 'रिमाइंडर्स',
    addReminderBtn: 'रिमाइंडर लगाएं',
    removeReminderTooltip: 'रिमाइंडर हटाएं',
    noReminderCreated: 'अभी तक कोई भी रिमाइंडर बनाया नहीं गया',
  },
  deleteReminderModal: {
    title: 'रिमाइंडर डिलीट करें',
    bodyText: 'क्या आपको यकीन है कि आप {{time}} डिलीट करना चाहते हैं?',
    cancelBtn: 'कैंसल करें',
    deleteBtn: 'डिलीट करें',
  },
  updateFactoryForm: {
    validNumber: 'सही नंबर डालें',
    edit: 'एडिट करें',
    title: 'फैक्ट्री डिटेल्स',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    photoLbl: 'फोटो',
    addressLbl: 'पता',
    addressPlaceholder: 'पता',
    contactNumberLbl: 'कॉन्टैक्ट नंबर',
    contactNumberPlaceHolder: '9999999999',
    gstinLbl: 'जीएसटीआईएन',
    gstinPlaceholder: 'जीएसटीआईएन',
    cancelBtn: 'कैंसल करें',
    updateBtn: 'अपडेट करें',
    requiredError: 'आवश्यक',
    mobileInvalidError: 'कृपया सही नंबर डालें',
    gstinInvalidError: 'कृपया सही जीएसटीआईएन डालें',
    resetBtn: 'फैक्ट्री को रीसेट करें',
    resetModal: {
      title: 'फैक्ट्री को रीसेट करें',
      bodyText:
        'क्या आपको यकीन है कि आप अपनी फैक्ट्री को रीसेट करना चाहते हैं?',
      cancelBtn: 'कैंसल करें',
      resetBtn: 'रीसेट करें',
      successMessage:
        'आपकी रिक्वेस्ट सबमिट कर दी गई है और हमारी टीम में से कोई जल्द ही आपसे संपर्क करेंगे!',
    },
  },
  updateProfileForm: {
    edit: 'एडिट करें',
    title: 'प्रोफाइल',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    mobileLbl: 'मोबाइल नंबर',
    mobilePlaceholder: '9999999999',
    photoLbl: 'फोटो',
    emailLbl: 'ईमेल',
    emailPlaceholder: 'ईमेल',
    cancelBtn: 'कैंसल करें',
    updateBtn: 'अपडेट करें',
    validEmailError: 'कृपया सही ईमेल डालें',
    requiredError: 'आवश्यक',
    languageSettingsLbl: 'भाषा सेटिंग्स',
    changeLanguageBtn: 'भाषा बदलें',
  },

  notificationSetting: {
    title: 'नेटिफिकेशंस सेटिंग्स',
    email: {
      header: 'नोटिफिकेशंस ईमेल करें',
      detailsText: 'साउंड्स चलाएं और अपनी फैक्ट्री से आने वाले ईमेल दिखाएं',
    },
    whatsApp: {
      header: 'व्हाट्सऐप नोटिफिकेशंस',
      detailsText:
        'साउंड्स चलाएं और अपनी फैक्ट्री से आने वाले व्हाट्सऐप मैसेज दिखाएं',
    },
  },

  factorySetting: {
    title: 'फैक्ट्री सेटिंग्स',
    sms: {
      header: 'पार्टी ट्रांजेक्शन एसएमएस',
      detailsText:
        'जब भी किसी पार्टी के साथ कोई ट्रांजेक्शन किया जाता है तो उसे एसएमएस भेजें',
    },
  },
  languageSetting: {
    title: 'भाषा चुनें',
    subTitle:
      'कृपया ऐसी भाषा चुनें जिसे आप आसानी से पढ़ और समझ सकें। चुनी हुई भाषा ऐप पर लागू हो जाएगी।',
    saveBtn: 'वरीयता सेव करें',
    cancelBtn: 'कैंसल करें',
    successMsg: 'भाषा अपडेट की गई',
  },
  resetBtn: 'फैक्ट्री को रीसेट करें',
  account: 'अकाउंट',
  dataNotAvailable: 'लागू नहीं',
};
export default settings;
