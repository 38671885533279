import { AxiosPromise } from 'axios';
import { tokenType } from '../../types';

import { axiosInstance } from '../../utils/axiosInterceptor';
import storageService, { STORAGE_KEY } from '../storageService';

export const setTokens = ({
  access,
  refresh,
}: {
  access: tokenType;
  refresh: tokenType;
}) => {
  storageService.set(STORAGE_KEY.TOKEN, access.token);
  storageService.set(STORAGE_KEY.TOKEN_EXPIRES, access.expires);
  storageService.set(STORAGE_KEY.REFRESH_TOKEN, refresh.token);
};

export const postRefreshToken = (): AxiosPromise =>
  axiosInstance({
    url: `v1/auth/refresh-tokens`,
    method: 'post',
    data: {
      refreshToken: storageService.get(STORAGE_KEY.REFRESH_TOKEN),
    },
  });
