const nav = {
  dashboard: 'डैशबोर्ड',
  orders: 'ऑर्डर्स',
  production: 'प्रोडक्शन',
  reminders: 'रिमाइंडर्स',
  processes: 'प्रोसेसेस',
  employees: 'एमप्लॉईज़',
  settings: 'सेटिंग्स',
  party: 'पार्टी',
  vendors: 'वेंडर्स',
  customers: 'कस्टमर्स',
  reports: 'रिपोर्ट्स',
  inventory: 'इन्वेंट्री',
  materials: 'मटीरियल्स',
  products: 'प्रोडक्ट्स',
  ledger: 'लेजर',
  manage: 'मैनेज करें',
  logout: 'लॉगआउट',
  needHelp: 'सहायता चाहिए',
  chatWithus: 'हमसे चैट करें',
  logOutModalTitle: 'क्या आपको यकीन है कि आप लॉगआउट करना चाहते हैं?',
  logOutModalSubTitle: 'आप लॉगिन स्क्रीन पर वापस आ जाएंगे',
  logOutBtn: 'लॉगआउट',
  cancelBtn: 'कैंसल करें',
};

export default nav;
