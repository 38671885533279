import { createStore, applyMiddleware } from 'redux';
import { reducers } from './Reducer';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer<any, any>(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  {},
  composeWithDevToolsDevelopmentOnly(applyMiddleware(thunk))
);
export const persistor = persistStore(store);
