const global = {
  dateTime: {
    toDay: 'आज',
    yesterday: 'कल',
    last30Days: 'पिछले 30  दिन',
    thisMonth: 'इस महीने',
    lastMonth: 'पिछले महीने',
    customRange: 'कस्टम रेंज',
  },
  datePickerDropDown: {
    applyBtn: 'लागू  करें',
  },
  errorPage: {
    errorMessage: 'कुछ गड़बड़ी हुई है...',
    errorTitle: 'हम समस्या को निपटाने के लिए काम कर रहे हैं',
    errorSubTitle: 'कृपया दोबारा कोशिश करें',
    errorPageRefreshBtn: 'Refresh Page',
  },
  searchBar: {
    searchTxt: 'सर्च करें',
  },
  noDataPage: {
    title: 'कोई डेटा नहीं मिला',
  },
};
export default global;
