import './style.css';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { store, persistor } from './store/store';
import { ToastContainer, cssTransition } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { FC, useEffect, useState } from 'react';

import Routes from './routes';
import Lottie from './components/Lottie';
import FallBackError from './components/Error/fallBackError';
import GlobalStyles from './../src/components/GlobalStyles';

import { XIcon } from '@heroicons/react/solid';

import { postRefreshToken, setTokens } from './services/authService';

import useAnalytics from './hooks/useAnalytics';

import animationData from './assets/lotties/WebLoader.json';
import storageService, { STORAGE_KEY } from './services/storageService';
import SupportContext from './SupportContext';
import useSupportWidget from './widgets/support/useSupportWidget';

const toastStyleCustom = {
  borderRadius: '6px',
  width: 'fit-content',
};
const downAnimation = cssTransition({
  enter: 'animate-slideIn',
  exit: 'animate-slideOut',
});
const App: FC = () => {
  const { init } = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);
  const isSupportReady = useSupportWidget();

  const getRefreshToken = async () => {
    try {
      setIsLoading(true);
      const data = await postRefreshToken();
      const { data: apiResponse } = data;
      if (apiResponse) {
        setTokens(apiResponse);
      } else {
        [
          STORAGE_KEY.TOKEN,
          STORAGE_KEY.TOKEN_EXPIRES,
          STORAGE_KEY.REFRESH_TOKEN,
        ].forEach((key) => storageService.remove(key));
      }
    } catch (error) {
      Sentry.captureException(error);
      storageService.clear();
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2500);
    }
  };
  useEffect(() => {
    const refreshToken: string | null = storageService.get(
      STORAGE_KEY.REFRESH_TOKEN
    );

    if (refreshToken) {
      getRefreshToken();
    } else {
      [
        STORAGE_KEY.TOKEN,
        STORAGE_KEY.TOKEN_EXPIRES,
        STORAGE_KEY.REFRESH_TOKEN,
      ].forEach((key) => storageService.remove(key));
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  return !isLoading ? (
    <div
      className="h-screen"
      id="mainContainer"
      onDrop={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Sentry.ErrorBoundary fallback={<FallBackError />}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SupportContext.Provider value={isSupportReady}>
              <ToastContainer
                toastClassName={
                  'rounded-xl overflow-hidden cursor-pointer bg-black-100'
                }
                toastStyle={{ padding: '0px', minHeight: 'fit-content' }}
                bodyClassName="p-0 m-0"
                style={toastStyleCustom}
                position="bottom-left"
                closeButton={
                  <div className="flex py-4 px-3">
                    <button className="focus:outline-none" type="button">
                      <XIcon
                        className={`h-5 w-5 text-white`}
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                }
                autoClose={2000}
                transition={downAnimation}
                hideProgressBar
              />
              <GlobalStyles />
              <Routes />
            </SupportContext.Provider>
          </PersistGate>
        </Provider>
      </Sentry.ErrorBoundary>
    </div>
  ) : (
    <div className="h-screen w-full flex items-center justify-center">
      <Lottie animationData={animationData} />
    </div>
  );
};

export default App;
