const reports = {
  documentTitle: 'Reports | FactoryPlus',
  common: {
    dateFilterApply: 'apply',
    dateFilterCancel: 'cancel',
  },
  dashboard: {
    title: 'Reports',
    businessOverviewHeading: 'Business Overview',
    inventoryOverviewHeading: 'Inventory Overview',
    dateFilterPlaceholder: 'Select Date Range',
    dateFilterToday: 'Today',
    dateFilterThisWeek: 'This Week',
    dateFilterThisMonth: 'This Month',
    dateFilterThisQuarter: 'This Quarter',
    dateFilterThisYear: 'This Year',
    dateFilterDateRange: 'Date Range',
    downloadReportBtn: 'Download Report',
    totalSales: 'Total Sales',
    totalPurchases: 'Total Purchases',
    viewDetailedReport: 'View Detailed Report',
    topRefilledMaterials: 'Top 5 Refilled Materials',
    topDispatchedProducts: 'Top 5 Dispatched Products',
    inventoryOverviewNoDataTitle: `We couldn't find any data for this date.`,
    inventoryOverviewNoDataDesc: 'Try changing the date range',
    materialSectionHeading: 'Material Pricing & Trends',
    materialName: 'Material Name',
    productName: 'Product Name',
    selectMaterialPlaceholder: 'Select Material',
    amount: 'Amount',
    ppuChartHeading: 'Amount per unit History',
    refillChartHeading: 'Refill Trend',
    consumeChartHeading: 'Consume Trend',
    transactions: 'Transactions',
    ppu: 'Price Per Unit',
    refill: 'Refill',
    consume: 'Consume',
    noPpuDataText: `Select a material to view it’s amount history.`,
    noRefillDataText: 'Select a material to view it’s refill trend.',
    noConsumeDataText: 'Select a material to view it’s consumption trend.',
  },
  transaction: {
    selectMaterialPlaceholder: 'Select Material',
    selectProductPlaceholder: 'Select Product',
    selectCustomerPlaceholder: 'Select Customer',
    selectVendorPlaceholder: 'Select Vendor',
    noTransactionTrendText: `We couldn't find any data for this date`,
    list: {
      dataNotAvailable: 'NA',
      quantity: 'Quantity',
      amount: 'Amount',
      date: 'Date',
      party: 'Party',
      codeAndName: 'Code & Name',
    },
  },
};

export default reports;
