const materials = {
  title: 'Materials',
  pageTitle: {
    materialsTab: 'Materials | FactoryPlus',
    categoryTab: 'Category | FactoryPlus',
  },
  tabLabelMaterial: 'materials',
  tabLabelCategory: 'category',
  excelDownloadFailed: 'Download Excel file failed. Please try again',
  excelDownloadSuccess: 'Material downloaded successfully!',
  materialsList: {
    title: 'List',
    materialSearchPlaceholderSuffix: 'Material Code, Name...',
    addMaterialBtn: 'Add Material',
    addMaterialBulkBtn: 'Bulk Upload',
    codeAndName: 'Code & Name',
    brand: 'Brand',
    avlQty: 'Avl QTY',
    minQty: 'Min QTY',
    ppu: 'Price Per Unit',
    dataNotAvailable: 'NA',
    refillBtn: 'Refill',
    consumeBtn: 'Consume',
    loadMoreBtn: 'Load more',
    zero: '0',
  },
  materialsCategory: {
    title: 'Category',
    categorySearchPlaceholderSuffix: 'Category Name',
    addCategoryBtn: 'Add Category',
    loadMoreBtn: 'Load more',
    totalItems: '{{noOfItems}} items',
    editBtn: 'Edit',
    deleteBtn: 'Delete',
  },
  updateCategoryMaterialForm: {
    title: 'Add/Remove Materials',
    searchMaterialPlaceholder: 'Search Material',
    cancelBtn: 'Cancel',
    saveBtn: 'Save',
  },
  addEditCategoryForm: {
    title: '{{formType}} Category',
    nameLbl: 'Name',
    namePlaceholder: 'Name',
    cancelBtn: 'Cancel',
    createBtn: 'Create',
    editBtn: 'Edit',
    requiredError: 'Required',
    commaNotAllowedError: 'Comma(,) not allowed',
  },
  deleteCategoryModal: {
    title: 'Delete Category',
    bodyText: 'Are you sure you want to delete {{categoryName}} ?',
    cancelBtn: 'Cancel',
    deleteBtn: 'Delete',
  },
  materialsCategoryDetail: {
    editBtn: 'Edit',
    deleteBtn: 'Delete',
    materialSearchPlaceholderSuffix: 'Material Code and Name...',
    createMaterialBtn: 'Create Material',
    addRemoveMaterialsBtn: 'Add/Remove Materials',
  },
  addEditMaterialForm: {
    createTitle: 'Create Material',
    editTitle: 'Edit Material',
    codeLbl: 'Code',
    codePlaceholder: 'Material Code',
    codeSuggestion: 'suggestion',
    nameLbl: 'Name',
    namePlaceholder: 'ex: Kraft Paper 32x45x28',
    unitLbl: 'Unit',
    unitPlaceholder: 'ex: kgs,metres',
    brandLbl: 'Brand',
    priceLbl: 'Price',
    brandPlaceholder: 'ex: Nippon',
    openingStockLbl: 'Opening Stock',
    openingStockPlaceholder: 'ex: 2500',
    minQtyLbl: 'Minimum Qty',
    minQtyPlaceholder: 'ex: 500',
    categoryLbl: 'Category',
    categoryPlaceholder: 'ex: Shelf-23',
    cancelMaterialBtn: 'Cancel',
    createMaterialBtn: 'Create',
    saveMaterialBtn: 'Save',
    requiredError: 'Required',
    qtyNegativeError: 'Qty cannot be negative',
    commaNotAllowedError: 'Comma(,) not allowed',
    numberError: 'Enter a Number',
    copyLbl: '(Copy)',
    materialInfo: 'Material Info',
    useReelsLbl: 'Use reels',
    reelNameLbl: 'Reel Name',
    quantityLbl: 'Quantity',
    addReelBtn: 'Add another reel',
    hsnLbl: 'HSN Code',
    hsnPlaceholder: 'ex: 12345678',
    secondaryBtn: 'Use secondary unit',
    secondaryUnitLbl: ' Secondary Unit',
    conversionRateLbl: 'Conversion Rate',
    conversionRatePlaceholder: 'ex: 100',
    stockInfoLbl: 'Stock Info',
    totalQuantity: 'Total Quantity :',
    createMaterialTitleBar: 'Create Material | FactoryPlus',
    editMaterialTitleBar: 'Edit Material | FactoryPlus',
  },
  addMaterialsBulkForm: {
    ppuLbl: 'Price Per Unit',
    ppuPlaceholder: '9999999',
    codeExists: 'Code Already Exists',
    title: 'Bulk Add materials',
    allowedExtensionsText:
      'Only files with the extensions .xls .xlxs .csv are allowed.',
    downloadTemplateBtn: 'download template',
    partialFileReviewTextPart1: '{{errorLength}} out of {{totalLength}} ',
    partialFileReviewTextPart2:
      'items have errors in them. Remove errors to complete upload!',
    completeFileReviewText: 'Please review this',
    codeLbl: 'Code',
    codePlaceholder: 'Material Code',
    nameLbl: 'Name',
    namePlaceholder: 'Name',
    brandLbl: 'Brand',
    brandPlaceholder: 'Brand',
    unitLbl: 'Unit',
    unitPlaceholder: 'Unit',
    avlQtyLbl: 'Avl Qty',
    avlQtyPlaceholder: 'Available Qty',
    minQtyLbl: 'Min Qty',
    minQtyPlaceholder: 'Minimum Qty',
    categoryLbl: 'Category',
    categoryPlaceholder: 'Category',
    dragAndDropText: 'drag and drop',
    uploadFromComputerText: 'upload from computer',
    invalidTemplateError:
      "can't upload this file. Check the template file and fill all the required fields. test",
    uploadFileAgain: 'upload again',
    cancelBulkMaterialBtn: 'Cancel',
    uploadBulkMaterialBtn: 'Upload',
    requiredError: 'Required',
    nameRequiredError: 'Name is required',
    qtyNegativeError: 'Qty cannot be negative',
    unitRequiredError: 'Unit is required',
    commaNotAllowedError: 'Comma(,) not allowed',
    duplicateCodeError: 'Duplicate Code',
  },
  materialDetail: {
    brand: 'Brand',
    refillBtn: 'Refill',
    consumeBtn: 'Consume',
    consumed: 'Consumed',
    refilled: 'Refilled',
  },
  refillConsumeMaterialForm: {
    refillFormTitle: 'Refill Material',
    consumeFormTitle: 'Consume Material',
    availableQty: 'Available Qty',
    qtyLbl: 'Quantity',
    qtyPlaceholder: 'ex: 4000',
    vendorLbl: 'Select Vendor (Optional)',
    vendorPlaceholder: 'Search Vendor',
    customerLbl: 'Select Customer (Optional)',
    customerPlaceholder: 'Select Customer',
    notesLbl: 'Notes (Optional)',
    notesPlaceholder: 'Notes',
    priceLbl: 'Amount',
    pricePerUnitLbl: 'Price Per Unit',
    amountLbl: 'Amount',
    savePricePerUnitCheckbox: 'Save Price Per Unit for All Items',
    cancelBtn: 'Cancel',
    refillBtn: 'Refill',
    consumeBtn: 'Consume',
    requiredError: 'Required',
    qtyNegativeError: 'Qty can’t be negative',
    qtyZeroError: 'Qty can’t be 0',
    stockInfoLbl: 'Stock Info',
    reelNameLbl: 'Reel Name',
    namePlaceHolder: 'ex: Reel 1-27/04',
    addReelBtn: 'Add another reel',
    totalQuantity: 'Total Quantity: ',
    materialInfo: 'Material Info',
    nameLbl: 'Name',
    noteBtn: 'Add note',
    materialLbl: 'Material',
    atLeastOneError: 'Enter quantity for at least one reel.',
    avlQtyLbl: 'Avl Qty:',
    reelInfoLbl: 'This material has reels',
    reelsLbl: 'Reels',
    reelsPlaceholder: 'ex: Reel',
    noReels: 'No Reels',
    noReeltitleMessage: 'No reels found',
    noReelBodyMessage:
      'Looks like you have consumed all your reels. Refill reels to continue.',
  },
  deleteMaterialModel: {
    title: 'Delete Material',
    bodyText: 'Are you sure you want to delete {{materialName}}?',
    unableToDeleteMsg: 'Sorry, cannot delete {{materialName}} right now.',
    cancelBtn: 'Cancel',
    deleteBtn: 'Delete',
  },
};

export default materials;
