import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { LANGUAGE_CODE } from '../constants/enum';

import storageService, { STORAGE_KEY } from '../services/storageService/index';
import { getUserPreferredLanguage } from '../services/userService';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/`,
});

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const userToken = storageService.get(STORAGE_KEY.TOKEN);
  const language: LANGUAGE_CODE | undefined = getUserPreferredLanguage();
  config.headers = {
    ...config.headers,
    ...(userToken && { authorization: `bearer ${userToken}` }),
    ...(language && { 'preferred-lang': language }),
    'content-Type': 'application/json',
  };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    storageService.remove(STORAGE_KEY.MAINTENANCE);
    // Return a successful response back to the calling service
    return response;
  },
  (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status === 401) {
      storageService.remove(STORAGE_KEY.TOKEN);
    }
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    [
      STORAGE_KEY.SIGN_UP_TOKEN,
      STORAGE_KEY.MOBILE,
      STORAGE_KEY.STATUS,
      STORAGE_KEY.USER_NAME,
      STORAGE_KEY.FACTORY_NAME,
    ].forEach((key) => storageService.remove(key));

    if (error.response.status === 503) {
      storageService.set(STORAGE_KEY.MAINTENANCE, 'on');
    } else {
      storageService.remove(STORAGE_KEY.MAINTENANCE);
    }
    // Logout user if token refresh didn't work

    window.location.reload();
  }
);
