const employees = {
  title: 'एमप्लॉईज़',
  addEmployeeBtn: 'एमप्लॉई जोड़ें',
  employeeSearchPlaceholderSuffix: 'एमप्लॉई',
  employeeImgAltText: 'एमप्लॉई का नाम',
  invProdSupervisor: 'इन्वेंट्री और प्रोडक्शन सुपरवाइज़र',
  invProdSupervisorViewOnly: 'इन्वेंट्री और प्रोडक्शन सुपरवाइज़र (सिर्फ देखें)',
  prodSupervisor: 'प्रोडक्शन सुपरवाइज़र',
  employeePendingBtn: 'लंबित',
  employeeDeactivated: 'डीएक्टिवेटेड',
  editEmployeeBtn: 'एडिट करें',
  deactivateEmployeeBtn: 'डीएक्टिवेट करें',
  reactivateEmployeeBtn: 'रीएक्टिवेट करें',
  deleteEmployeeBtn: 'डिलीट करें',
  loadMoreBtn: 'और ज्यादा लोड करें',
  addEditEmployeeForm: {
    titleAdd: 'एमप्लॉई जोड़ें',
    titleEdit: 'एमप्लॉई एडिट करें',
    nameLbl: 'नाम',
    namePlaceholder: 'नाम',
    emailLbl: 'ईमेल',
    emailPlaceholder: 'ईमेल',
    mobileLbl: 'मोबाइल',
    mobilePlaceholder: 'मोबाइल नंबर',
    roleLbl: 'रोल',
    roleSearchPlaceholder: 'रोल सर्च करें',
    processesLabel: 'प्रोसेसेस',
    processesSearchPlaceholder: 'प्रोसेसेस सर्च करें',
    cancelEmployeeBtn: 'कैंसल करें',
    createEmployeeBtn: 'बनाएं',
    updateEmployeeBtn: 'अपडेट करें',
    requiredError: 'आवश्यक',
    validEmailError: 'कृपया सही ईमेल डालें',
    validMobileError: 'कृपया सही नंबर डालें',
  },
  roleDropDown: {
    adminLbl: 'एडमिन',
    adminVal: 'एडमिन',

    prodSupervisorLbl: 'प्रोडक्शन सुपरवाइज़र',
    prodSupervisorVal: 'प्रोडक्शन सुपरवाइज़र',

    inventoryAndProdLbl: 'इन्वेंट्री और प्रोडक्शन सुपरवाइज़र',
    inventoryAndProdVal: 'इन्वेंट्री और प्रोडक्शन सुपरवाइज़र',

    inventoryAndProdViewOnlyLbl:
      'इन्वेंट्री और प्रोडक्शन सुपरवाइज़र (सिर्फ देखें)',
    inventoryAndProdViewOnlyVal:
      'इन्वेंट्री और प्रोडक्शन सुपरवाइज़र (सिर्फ देखें)',
  },
  employeeDetail: {
    title: 'एमप्लॉई डिटेल्स',
    editEmployeeBtn: 'एडिट करें',
    deleteEmployeeBtn: 'डिलीट करें',
    employeeImgAltText: 'एमप्लॉई का नाम',
    employeeNotSignedUp: {
      status: 'स्टेटस',
      employeePendingBtn: 'लंबित',
      invite: 'निमंत्रण',
      sendInvite: 'निमंत्रण भेजें',
    },
    employeeContactNo: 'कॉन्टैक्ट नंबर',
    employeeEmail: 'ईमेल',
    employeeWhatsapp: 'व्हाट्सऐप',
    sendMessage: 'मैसेज भेजें',
    employeeProcesses: 'प्रोसेसेस',
    employeeJobsListing: {
      title: 'जॉब्स',
      activeJobs: 'एक्टिव करें',
      upcomingJobs: 'आगामी',
      pastJobs: 'पिछला',
      quantityUnit: '{{quantity}} पीस',
      loadMoreBtn: 'और ज्यादा लोड करें',
    },
    activateBtn: 'एमप्लॉई एक्टिवेट करें',
    deleteEmployeeModal: {
      title: 'एमप्लॉई डिलीट करें',
      bodyText: 'Are you sure you want to delete {{employeeName}}?',
      cancelBtn: 'कैंसल करें',
      deleteBtn: 'डिलीट करें',
    },
    deactivateEmployee: {
      title: 'Deactivate an employee',
      bodyText:
        'क्या आपको यकीन है कि आप {{employeeName}} डिलीट करना चाहते हैं? ',
      cancelBtn: 'कैंसल करें',
      deactivateBtn: 'डीएक्टिवेट करें',
    },
    reactivateEmployee: {
      title: 'एमप्लॉई रीएक्टिवेट करें',
      bodyText:
        '{{number}} नंबर वाला एमप्लॉई पहले से मौजूद है और उसे डीएक्टिवेट कर दिया गया है। क्या आप इस एमप्लॉई को एक्टिव करना चाहते हैं?',
      cancelBtn: 'कैंसल करें',
      reactivateBtn: 'रीएक्टिवेट करें',
    },
    employeeVerification: {
      title: 'एमप्लॉई वेरीफिकेशन',
      subTitle: 'कोड डालें जो आपको भेजा गया',
      verifyBtn: 'कोड वेरीफाई करें',
    },
  },
};

export default employees;
