const successMessage = {
  orderStarted: 'ऑर्डर शुरू किया गया!',
  orderUpdated: 'ऑर्डर अपडेट किया गया!',
  orderDeleted: 'ऑर्डर डिलीट किया गया!',
  orderFinished: 'ऑर्डर समाप्त किया गया!',
  jobUpdated: 'जॉब अपडेट किया गया!',
  quantityUpdated: 'मात्रा अपडेट की गई!',
  productMovedToInventory: 'प्रोडक्ट को इन्वेंट्री में भेजा गया',
  materialCreated: 'मटीरियल बनाया गया',
  materialUpdated: 'मटीरियल अपडेट किया गया!',
  bulkMaterialsCreated: 'मटीरियल्स बनाए गए!',
  materialQuantityUpdated: 'मटीरियल की मात्रा अपडेट की गई!',
  materialDeleted: 'मटीरियल डिलीट किया गया!',
  bulkProductsCreated: 'प्रोडक्ट्स बनाए गए!',
  productUpdated: 'प्रोडक्ट अपडेट किया गया!',
  productDeleted: 'प्रोडक्ट डिलीट किया गया!',
  productQuantityUpdated: 'प्रोडक्ट की मात्रा अपडेट की गई!',
  categoryUpdated: 'कैटगरी अपडेट की गई!',
  categoryCreated: 'कैटगरी बनाई गई!',
  categoryEdited: 'कैटगरी एडिट की गई!',
  categoryDeleted: 'कैटगरी डिलीट की गई!',
  partyCreated: 'पार्टी बनाई गई!',
  machineUpdated: 'मशीन अपडेट किया गया!',
  bulkMachinesCreated: 'मशीनें बनाई गईं!',
  machineDeleted: 'मशीन डिलीट किया गया!',
  machineOperationUpdated: 'मशीन ऑपरेशन अपडेट किया गया!',
  processCreated: 'प्रोसेस बनाया गया!',
  processUpdated: 'प्रोसेस बनाया गया!',
  bulkProcessesCreated: 'प्रोसेसेस बनाए गए!',
  processDeleted: 'प्रोसेस डिलीट किया गया!',
  employeeAssignedToProcess: 'एमप्लॉई जोड़ा गया!',
  employeeRemovedFromProcess: 'एमप्लॉई डिलीट किया गया!',
  employeeUpdated: 'एमप्लॉई अपडेट किया गया!',
  employeeDeleted: 'एमप्लॉई डिलीट किया गया!',
  employeeActivated: 'एमप्लॉई एक्टिवेट किया गया!',
  employeeReactivated: 'एमप्लॉई रीएक्टिवेट किया गया!',
  employeeDeactivated: 'एमप्लॉई डीएक्टिवेट किया गया!',
  partyAdded: '{{type}} जोड़ा गया',
  partyUpdated: '{{type}} अपडेट किया गया',
  deactivated: '{{type}} डीएक्टिवेट किया गया',
  ledgerDownloaded: 'लेजर अच्छे तरीके से डाउनलोड किया गया!',
  reminderDeleted: 'रिमाइंडर डिलीट किया गया!',
  factoryDetailsUpdated: 'फैक्ट्री डिटेल्स अपडेट किया गया!',
  profileDetailsUpdated: 'प्रोफाइल डिटेल्स अपडेट किया गया!',
  reminderCreated: 'रिमाइंडर बनाया गया!',
  reminderUpdated: 'रिमाइंडर अपडेट किया गया!',
  productCreated: 'प्रोडक्ट बनाया गया!',
  productDispatched: 'प्रोडक्ट भेजा गया!',
  machineCreated: 'मशीन बनाया गया!',
  employeeCreated: 'एमप्लॉई बनाया गया!',
  materialsConsumed: 'इस्तेमाल किए गए मटीरियल्स',
  materialsRefilled: 'मटीरियल्स रीफिल किया गया',
  productStockUpdated: 'प्रोडक्ट्स के स्टॉक को अपडेट किया गया',
  productionCreated: 'प्रोडक्शन बनाया गया!',
  materialRatioSaved: 'मटीरियल अनुपात सेव किया गया',
  bulkCategoryCreated: 'कैटगरी बनाई गई!',
};

export default successMessage;
