import { Dict } from 'mixpanel-browser';
import analytics from './../utils/analytics';
interface UserData {
  name: string;
  factoryName: string;
  userId: number;
  industry: string;
  role: string;
  email: string;
  phone: string;
}
export default function useAnalytics(): {
  sendEvent: (name: string, props: Dict, googleAnalytics?: boolean) => void;
  identify: (id: number) => void;
  init: () => void;
  profileSet: (data: UserData) => void;
  logout: () => void;
} {
  return {
    sendEvent: (name, props, googleAnalytics) => {
      analytics.sendEvent(name, props, googleAnalytics);
    },
    identify: (id) => {
      analytics.identify(id);
    },
    init: () => {
      analytics.init();
    },
    profileSet: (data) => {
      analytics.profileSet(data);
    },
    logout: () => analytics.logout(),
  };
}
