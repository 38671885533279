const inventoryItemsToolTip = {
  reelName: 'रील का नाम',
  quantity: 'मात्रा',
  noReelsErrorMsg: 'कोई भी रील मौजूद नहीं है',
  remaining: 'बचा हुआ',
  oldBalance: 'पुराना बैलेंस',
  consumed: 'इस्तेमाल किया हुआ',
  newBalance: 'नया बैलेंस',
};
export default inventoryItemsToolTip;
