interface User {
  name: string;
  factoryName: string;
  email: string;
  userId: string;
  intercom: string;
  role: string;
  phone: string;
  imgUrl: string;
  factoryId: string;
  industry: string;
  rmTypes: string;
}
type Action = {
  type: string;
  payload: User;
};

const reducer = (
  state = {
    name: '',
    factoryName: '',
    email: '',
    phone: '',
    userId: '',
    intercom: '',
    role: '',
    imgUrl: '',
    factoryId: '',
    industry: '',
    rmTypes: '',
  },
  action: Action
): {
  name: string;
  factoryName: string;
  email: string;
  phone: string;
  userId: string;
  intercom: string;
  role: string;
  imgUrl: string;
  factoryId: string;
  industry: string;
  rmTypes: string;
} => {
  switch (action.type) {
    case 'saveUser':
      return (state = action.payload);

    default:
      return state;
  }
};

export default reducer;
