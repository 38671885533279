export const whatsappMessage = (
  role = '',
  factoryName = '',
  name = ''
): string => {
  return `Welcome to FactoryPlus! You have been added as ${role} on ${factoryName} by ${name}! Click on link to download FactoryPlus! https://bit.ly/FPlusapp`;
};
export const machinesCreatedSuccessfully = 'Machines created successfully';

export const processesCreatedSuccessfully = 'Processes created successfully';
export const ADD = 'Add';
export const EDIT = 'Edit';
export const FG = 'FG';
export const RM = 'RM';
export const RMFG = 'RM,FG';
export const available = 'AVAILABLE';
export const low = 'LOW';
export const produce = 'PRODUCE';
export const consume = 'CONSUME';
export const dispatch = 'DISPATCH';
export const refill = 'REFILL';
export const VENDOR = 'Vendor';
export const CUSTOMER = 'Customer';
export const VENDOR_CUSTOMER = 'VENDOR_CUSTOMER';
export const ADD_PARTY = 'ADD_PARTY';
export const UPDATE_PARTY = 'UPDATE_PARTY';
export const DEACTIVATE_PARTY = 'DEACTIVATE_PARTY';
export const RESET = 'RESET';
export const FACTORY_RESET = 'FACTORY_RESET';
export const DELETE = 'DELETE';
export const ADD_REMINDERS = 'ADDREMINDERS';
export const SHOW_PROFILE = 'SHOWPROFILE';
export const SHOW_FACTORY = 'SHOWFACTORY';
export const DUPLICATE_PRODUCT = 'DUPLICATE_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_MATERIAL = 'EDIT_MATERIAL';
export const EDIT_ENTRY = 'EDIT_ENTRY';
export const HISTORY_MODAL = 'HISTORY_MODAL';
export const GREEN = 'green';
export const RED = 'red';
export const PARTY = 'Party';
export const PRODUCTION = 'PRODUCTION';
export const ADMIN = 'admin';
export const INVENTORY_PRODUCTION_SUPERVISOR =
  'inventory and production supervisor';
export const MANAGE = 'Manage';
export const INVENTORY = 'Inventory';
export const EDIT_PRODUCTION = 'EDIT_PRODUCTION';
export const rupeesSymbol = '₹';
export const currency = 'INR';
export const maxPrice = 99999999;
export const maxValue = 99999999;
export const dateFormat = 'do MMM yyyy, hh:mm aaa';
export const hyphen = '-';
export const QUANTITY_UPDATE = 'QUANTITY UPDATE';

export const CORRUGATION = 'CORRUGATION';
export const PAPER = 'PAPER';

export const DEFAULT_LIMIT = 10;

export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';

export const UNIT_RATIO = 1;

export const INVENTORY_ITEM_TYPE_FIELD_NAME = 'inventoryItems';
export const INVENTORY_ITEM_TYPES_ARRAY = ['REEL'];

export const VENDOR_VENDOR_CUSTOMER = 'VENDOR,VENDOR_CUSTOMER';

export const VendorAndCustomer = 'Vendor and Customer';

export const API_DATE_FILTER_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export const DISPATCH = 'DISPATCH';

export const LANGUAGE_SETTING_MODAL_OPEN = 'Language Settings';

export const PREFERRED_LANGUAGE_LABEL_CODE_MAP = {
  en: 'English',
  hi: 'हिन्दी',
  'hi-en': 'Hinglish',
};

export const USER_ACTION_TYPE = {
  LOGIN: 'login',
  SIGN_UP: 'signup',
};

export const UTC_TO_IST_MINUTE_DIFFERENCE = 330;
export const DATE_AND_TIME_FORMAT = {
  DO_MMM_YY: 'do MMM yy', //ex: 29th JAN 22
  DD_MM_YY_HH_MM_A: 'dd MMM yy, hh:mm a', // ex: 26 Sep 22 ,05:34 PM
  YYYY_MM_DD_HH_MM_SS: 'yyyy-MM-dd HH:mm:ss', //ex: 2022-09-26 18:00:00
  MMMM_D_YYYY_H_MM_A: 'MMMM d, yyyy h:mm aa', //ex: September 26, 2022 6:00 PM
};
