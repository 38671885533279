export enum STORAGE_KEY {
  TOKEN = 'token',
  REFRESH_TOKEN = 'refreshToken',
  TOKEN_EXPIRES = 'tokenExpires',
  PREFERRED_LANGUAGE = 'preferredLanguage',
  SIGN_UP_TOKEN = 'signUpToken',
  MOBILE = 'mobile',
  STATUS = 'status',
  USER_NAME = 'userName',
  FACTORY_NAME = 'factoryName',
  MAINTENANCE = 'maintenance',
  SIGN_UP_IN_PROGRESS = 'signUpInProgress',
}

const storageService = {
  get: (key: string): string | null => localStorage.getItem(key),
  set: (key: STORAGE_KEY, value: string | number): void =>
    localStorage.setItem(key, `${value}`),
  remove: (key: string): void => localStorage.removeItem(key),
  clear: (): void => localStorage.clear(),
};

export default storageService;
