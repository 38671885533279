import dashboard from './labels/dashboard';
import nav from './labels/nav';
import orders from './labels/orders';
import products from './labels/inventory/products';
import materials from './labels/inventory/materials';
import inventory from './labels/inventory/inventory';
import reminder from './labels/reminder';
import signUp from './labels/signUp';
import ledger from './labels/ledger';
import production from './labels/production';

import processes from './labels/processes';
import employees from './labels/employees';
import settings from './labels/settings';
import errorMessage from './messages/errorMessage';
import successMessage from './messages/successMessage';
import party from './labels/party';
import error from './labels/error';
import materialBuy from './labels/materialBuy';
import login from './labels/login';
import materialOrProductDropdown from './organism/materialOrProductDropdown';
import inventoryItemsToolTip from './organism/inventoryItemsToolTip';
import reports from './labels/reports';
import global from './global';
import notification from './labels/notification';

export default {
  reminder,
  nav,
  dashboard,
  orders,
  inventory,
  production,
  materials,
  products,
  ledger,
  processes,
  employees,
  settings,
  errorMessage,
  successMessage,
  party,
  signUp,
  error,
  materialBuy,
  login,
  materialOrProductDropdown,
  reports,
  inventoryItemsToolTip,
  global,
  notification,
};
