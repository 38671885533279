import rosetta, { Rosetta } from 'rosetta';
import en from './locales/en';
import hi from './locales/hi';
import hi_en from './locales/hi-en';
import { getUserPreferredLanguage } from '../userService';
import { LANGUAGE_CODE } from '../../constants/enum';

const translationService: Rosetta<typeof en> = rosetta({
  en,
  hi,
  'hi-en': hi_en,
});

((language) => {
  switch (language) {
    case LANGUAGE_CODE.HI:
      translationService.locale(LANGUAGE_CODE.HI);
      break;
    case LANGUAGE_CODE.HI_EN:
      translationService.locale(LANGUAGE_CODE.HI_EN);
      break;
    default:
      translationService.locale(LANGUAGE_CODE.EN);
      break;
  }
})(getUserPreferredLanguage());

export const t = translationService.t;

export default translationService;
