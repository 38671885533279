const products = {
  title: 'Products',
  pageTitle: {
    productsTab: 'Products | FactoryPlus',
    categoryTab: 'Category | FactoryPlus',
  },
  excelDownloadFailed: 'Download Excel file failed. Please try again',
  excelDownloadSuccess: 'Product downloaded successfully!',
  tabLabelProduct: 'products',
  tabLabelCategory: 'category',
  productsList: {
    title: 'List',
    productSearchPlaceholderSuffix: 'Product Code, Name...',
    addProductBtn: 'Add Product',
    addProductBulkBtn: 'Bulk Upload',
    codeAndName: 'Code & Name',
    avlQty: 'Avl QTY',
    ppu: 'Price Per Unit',
    avlQtyUnit: '{{avlQtyPcs}} pcs',
    dataNotAvailable: 'NA',
    dispatchBtn: 'Dispatch',
    restockBtn: 'Restock',
    loadMoreBtn: 'Load more',
  },
  productsCategory: {
    title: 'Category',
    categorySearchPlaceholderSuffix: 'Category Name',
    addCategoryBtn: 'Add Category',
    loadMoreBtn: 'Load more',
    totalItems: '{{noOfItems}} items',
    editBtn: 'Edit',
    deleteBtn: 'Delete',
  },
  updateCategoryProductForm: {
    title: 'Add/Remove Products',
    searchProductsPlaceholder: 'Search Products',
    cancelBtn: 'Cancel',
    saveBtn: 'Save',
  },
  addEditCategoryForm: {
    title: '{{formType}} Category',
    nameLbl: 'Name',
    namePlaceholder: 'Name',
    cancelBtn: 'Cancel',
    createBtn: 'Create',
    editBtn: 'Edit',
    requiredError: 'Required',
    commaNotAllowedError: 'Comma(,) not allowed',
  },
  deleteCategoryModal: {
    title: 'Delete Category',
    bodyText: 'Are you sure you want to delete {{categoryName}} ?',
    cancelBtn: 'Cancel',
    deleteBtn: 'Delete',
  },
  productsCategoryDetail: {
    editBtn: 'Edit',
    deleteBtn: 'Delete',
    productSearchPlaceholderSuffix: 'Product Code and Name...',
    createProductBtn: 'Create Product',
    addRemoveProductBtn: 'Add/Remove Product',
  },
  createOrDuplicateProductForm: {
    material: 'Material',
    conversionRatePlaceholder: 'ex: 100',
    unitPlaceholder: 'ex: kgs,metres',
    secondaryUnitLbl: ' Secondary Unit',
    conversionRateLbl: 'Conversion Rate',
    productInfo: ' Product Info',
    stockInfo: 'Stock Info',
    step1: 'Product Details',
    noProcessError: 'No process selected',
    noMaterialError: 'No material selected',
    secondaryBtn: 'Use secondary unit',
    step2: 'Process Details',
    step3: 'Add/Remove Materials',
    step4: 'Material Ratio',
    title: '{{formType}} Product',
    codeLbl: 'Code',
    codePlaceholder: 'Product Code',
    hsnLbl: 'HSN Code',
    hsnPlaceholder: 'ex: 100',
    codeSuggestion: 'suggestion',
    nameLbl: 'Name',
    namePlaceholder: 'Name',
    openingStockLbl: 'Opening Stock',
    unitLbl: 'Unit',
    unitPlaceHolder: 'ex: kgs,metres',
    priceLbl: 'Price Per Unit',

    openingStockPlaceholder: 'ex: 2500',
    openingStockUnit: 'pcs',
    categoryLbl: 'Category',
    categoryPlaceholder: 'ex: Shelf-23',
    searchProcessPlaceholder: 'Search Process',
    searchMaterialPlaceholder: 'search material',
    forProductLbl: 'For',
    zeroPlaceholder: '0',
    addMaterialWarningLine1: 'Please',
    addMaterialWarningLine2: 'Add Materials',
    addMaterialWarningLine3: 'to use this feature',
    saveForLater: 'You can proceed by clicking Save to use this feature later',
    learnMore: 'Learn More',
    learnMoreTooltip1: 'Enter quantities required for production.',
    learnMoreTooltip2: 'This ratio will be used in future to calculate',
    learnMoreTooltip3: 'quantities of materials for production of this product',
    prevBtn: 'Prev',
    nextBtn: 'Next',
    addBtn: 'Add',
    skipAndAddBtn: 'Skip & Add',
    requiredError: 'Required',
    valueNegativeError: 'Value can’t be negative',
    valueDecimalError: 'Value can’t be decimal',
    commaNotAllowedError: 'Comma(,) not allowed',
    minQtyLbl: 'Minimum Qty',
    minQtyPlaceHolder: 'ex: 500',
  },
  addProductsBulkForm: {
    minQtyLbl: 'Min Qty',
    ppuLbl: 'Price Per Unit',
    codeExists: 'Code already exists',
    minQtyPlaceholder: '0',
    ppuPlaceholder: '999999',
    unitPlaceholder: 'ex: kgs,metres',
    unitLbl: 'Unit',
    unitRequiredError: 'Unit is required',
    title: 'Bulk Add products',
    allowedExtensionsText:
      'Only files with the extensions .xls .xlxs .csv are allowed.',
    partialFileReviewTextPart1: '{{errorLength}} out of {{totalLength}} ',
    partialFileReviewTextPart2:
      'items have errors in them. Remove errors to complete upload!',
    completeFileReviewText: 'Please review this',
    downloadTemplateBtn: 'download template',
    codeLbl: 'Code',
    codePlaceholder: 'Code',
    nameLbl: 'Name',
    namePlaceholder: 'Name',
    openingStockLbl: 'Opening Stock',
    openingStockPlaceholder: 'Avl Qty',
    materialsLbl: 'Materials',
    materialsPlaceholder: 'Select Materials',
    processesLbl: 'Processes',
    processesPlaceholder: 'Select Processes',
    categoryLbl: 'Category',
    categoryPlaceholder: 'Category',
    dragAndDropText: 'drag and drop',
    uploadFromComputerText: 'upload from computer',
    invalidTemplateError:
      "can't upload this file. Check the template file and fill all the required fields. test",
    uploadFileAgain: 'upload again',
    cancelBulkProductBtn: 'Cancel',
    uploadBulkProductBtn: 'Upload',
    requiredError: 'Required',
    nameRequiredError: 'Name is required',
    qtyNegativeError: 'Quantity cannot be negative',
    valueNegativeError: 'Value can’t be negative',
    valueDecimalError: 'Value can’t be decimal',
    commaNotAllowedError: 'Comma(,) not allowed',
    duplicateCodeError: 'Duplicate Code',
  },
  productDetail: {
    backBtn: 'Back',
    duplicateProductBtn: 'Duplicate',
    editProductBtn: 'Edit',
    deleteProductBtn: 'Delete',
    avlQtyLbl: 'Available Quantity',
    avlQty: '{{quantity}} pcs',
    produceBtn: 'Produce',
    restockBtn: 'Restock',
    dispatchBtn: 'Dispatch',
    categories: 'Categories',
    processDetails: 'Process Details',
    materialDetails: 'Material Details',
    usageHistory: 'Usage History',
    produced: 'Produced',
    dispatched: 'Dispatched',
    producedOrDispatchedInOrderCode: 'in #{{orderCode}}',
    userAt: '{{name}} at',
    qtyChange: '{{quantityChange}} pcs',
    remainingQty: 'Remaining : {{value}} {{unit}}',
    loadMoreBtn: 'Load more',
    ppu: 'Price Per Unit',
    viewHistory: 'View Edit History',
    editEntry: 'Edit Entry',
  },

  editProductForm: {
    editProductTitleBar: 'Edit Product | FactoryPlus',
    title: 'Edit Product',
    codeLbl: 'Code',
    codePlaceholder: 'Product Code',
    codeSuggestion: 'suggestion',
    nameLbl: 'Name',
    namePlaceholder: 'Name',
    categoryLbl: 'Category',
    categoryPlaceholder: 'ex: Shelf-23',
    updateBtn: 'Update',
    requiredError: 'Required',
    commaNotAllowedError: 'Comma(,) not allowed',
    unitLbl: 'Unit',
    unitPlaceHolder: 'ex: kgs,metres',
    priceLbl: 'Price Per Unit',
  },

  restockOrDispatchProductForm: {
    dispatchTitle: 'Dispatch',
    restockTitle: 'Restock Quantity',
    availableQty: 'Available Qty',
    qtyLbl: 'Quantity',
    qtyPlaceholder: 'Qty',
    partyLbl: 'Select Party',
    partyPlaceholder: 'Search Party',
    customerLbl: 'Select Customer',
    customerPlaceholder: 'Search Customer',
    notesLbl: 'Notes (Optional)',
    notesPlaceholder: 'Notes',
    dispatchBtn: 'Dispatch',
    restockBtn: 'Restock',
    requiredError: 'Required',
    qtyNegativeError: 'Product Qty can’t be negative',
    qtyZeroError: 'Product Qty can’t be 0',
    qtyDecimalError: 'Product Qty can’t be decimal',
    enterQty: 'Enter Quantity',
    price: 'Amount',
    pricePerUnitLbl: 'Price Per Unit',
    amountLbl: 'Amount',
    savePricePerUnitCheckbox: 'Save Price Per Unit for All Items',
    cancelBtn: 'Cancel',
  },
  deleteProductModel: {
    title: 'Delete Product',
    bodyText: 'Are you sure you want to delete {{productName}}?',
    unableToDeleteMsg: 'Sorry, cannot delete {{productName}} right now.',
    cancelBtn: 'Cancel',
    deleteBtn: 'Delete',
  },
  processUpdateForm: {
    title: '{{formType}} process',
    processPlaceholder: 'Search Process',
    updateBtn: 'Update',
  },
  productInfoUpdateForm: {
    title: 'Material details',
    forProductLbl: 'For',
    productUnit: 'pcs',
    productPlaceholder: '0',
    addMaterialsText: 'Please Add Materials to use this feature',
    addMaterialsBtn: 'Add Materials',
    addRemoveMaterials: 'Add/Remove Materials',
    cancelBtn: 'Cancel',
    saveBtn: 'Save',
    valueZeroError: 'Value cannot be 0',
    valueNegativeError: 'Value can not be negative',
    productDecimalError: 'Product cannot be decimal',
    qtyRequiredError: 'Quantity is required',
    requiredError: 'Required',
  },
  productMaterialUpdateForm: {
    material: 'Material',
    title: '{{formType}} Material',
    materialSearchPlaceholder: 'Search Material',
    prevBtn: 'Prev',
    updateBtn: 'Update',
  },
};

export default products;
