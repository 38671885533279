const inventoryItemsToolTip = {
  reelName: 'Reel Name',
  quantity: 'Quantity',
  noReelsErrorMsg: ' No Reels Exists',
  remaining: 'Remaining',
  oldBalance: 'Old Balance',
  consumed: 'Consumed',
  newBalance: 'New Balance',
};
export default inventoryItemsToolTip;
