import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import './index.css';

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [new Integrations.BrowserTracing()],
  environment: `${process.env.REACT_APP_ENV}`,
  tracesSampleRate: 0.2,
});

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
