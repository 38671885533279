import { INVENTORY_PRODUCTION_SUPERVISOR } from '../constants';
import storageService from '../services/storageService';

const Base = () => {
  let user;
  const localStorageObject: string | null = storageService.get('persist:root');
  if (localStorageObject) {
    user = JSON.parse(localStorageObject);
    user = JSON.parse(user.user);
  }

  const brandColor = {
    'brand-900':
      user?.role?.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR
        ? '#101841'
        : '#1F3731',
    'brand-800':
      user?.role?.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR
        ? '#212E72'
        : '#30554C',
    'brand-700':
      user?.role?.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR
        ? '#2A3B91'
        : '#417367',
    'brand-600':
      user?.role?.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR
        ? '#3D4FAE'
        : '#529182',
    'brand-500':
      user?.role?.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR
        ? '#4359C9'
        : '#68AA9A',
    'brand-400':
      user?.role?.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR
        ? '#6374D2'
        : '#86BBAE',
    'brand-300':
      user?.role?.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR
        ? '#8290DB'
        : '#A5CCC2',
    'brand-200':
      user?.role?.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR
        ? '#B2BDF4'
        : '#C3DDD6',
    'brand-100':
      user?.role?.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR
        ? '#E0E3F6'
        : '#E1EEEB',
    'brand-50':
      user?.role?.toLowerCase() === INVENTORY_PRODUCTION_SUPERVISOR
        ? '#BBF7D0'
        : '#BBF7D0',
  };
  return brandColor;
};
export default Base;
