import SentryErrorIMG from '../../assets/sentryError.svg';
import { t } from '../../services/translationService';

const FallBackError = () => {
  return (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="w-full flex flex-col items-center">
        <p className="mb-9 font-semibold leading-8 text-5xl text-silver-900">
          {t('error.fallBackError.title')}
        </p>
        <p className="text-2xl leading-8 font-normal text-silver-700">
          {t('error.fallBackError.subtitle1')}
        </p>
        <p className="text-2xl leading-8 font-normal text-silver-700">
          {t('error.fallBackError.subtitle2')}
        </p>
        <button
          type="button"
          className="mt-12 font-medium text-green-700 leading-8 text-2xl underline"
          onClick={() => window.location.reload()}
        >
          {t('error.fallBackError.refreshBtn')}
        </button>
        <div>
          <img
            src={SentryErrorIMG}
            alt={t('error.fallBackError.subtitle1')}
            className="w-125 h-78 mt-16"
          />
        </div>
      </div>
    </div>
  );
};

export default FallBackError;
